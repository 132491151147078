<template>
  <v-card width="80vw" class="d-flex" :color="'#171717'">
    <!-- Dialogs -->
    <v-dialog v-model="subscriptionDialog">
      <v-card>
        <v-card-title>Тут будет подписка</v-card-title>
        <v-btn @click="goToAnalysis">Перейти к анализу</v-btn>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="uploadDialogModel" persistent width="400">
      <v-card v-if="uploadModel">
        <v-card-title>{{ uploadModel.target }}</v-card-title>
        <v-text-field
          variant="outlined"
          :label="$t('common.dateFrom')"
          density="compact"
          type="date"
          v-model="uploadModel.date_from"
          class="ma-2"
        />
        <v-text-field
          variant="outlined"
          :label="$t('common.dateTo')"
          density="compact"
          type="date"
          v-model="uploadModel.date_to"
          class="ma-2"
        />
        <v-select
          :label="$t('compare.timeControl')"
          variant="outlined"
          density="compact"
          :items="rangeItems"
          v-model="uploadModel.timecontrols"
          multiple
          item-title="title"
          item-value="id"
          chips
          class="ma-2"
        />
        <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
          <v-btn @click="uploadModel = null">{{ $t('common.cancel') }}</v-btn>
          <v-btn
            color="success"
            @click="handleUpload"
            :disabled="uploadDisabled"
          >
            {{ $t('cardSummary.uploadButton') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="reassignModel" persistent width="400">
      <v-card v-if="reassignModel">
        <v-card-title>Reassign nickname from {{ hero.name }}</v-card-title>
        <v-select
          label="to"
          variant="outlined"
          density="compact"
          :items="heroesList && heroesList.filter(item => item.name !== hero.name)"
          v-model="reassignModel.hid"
          item-title="name"
          item-value="hero_id"
          chips
          class="ma-2"
        />
        <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
          <v-btn @click="reassignModel = null">Cancel</v-btn>
          <v-btn
            color="success"
            @click="handleReassign"
            :disabled="!reassignModel.hid"
          >
            Reassign
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="editAccountDialogModel"
      persistent
      width="40vw"
      height="40vh"
    >
      <edit-account-dialog
        @close="editAccountDialogModel = false"
        :heroId="hero.hero_id"
        :editingNickname="editingNickname"
      />
    </v-dialog>
    
    <v-dialog
      v-model="confirmDeleteDialogModel"
      persistent
      width="40vw"
      height="40vh"
    >
      <v-card>
        <v-card-title>
          Подтверждение удаление аккаунта {{ nickToDelete && nickToDelete.nick }}
        </v-card-title>
        <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
          <v-btn @click="nickToDelete = null">Cancel</v-btn>
          <v-btn color="error" @click="handleDelete">Delete</v-btn>
        </div>
      </v-card>
    </v-dialog>
    
    <!-- Main Content -->
    <div class="d-flex flex-column" style="width: 100%; height: 100%">
      <!-- Header Section -->
      <div class="d-flex justify-space-between align-center mb-4">
        <h2 >{{ $t('cardSummary.accounts') }}</h2>
        <div class="header-buttons">
          <v-btn
            prepend-icon="mdi-plus-circle-outline"
            @click="openEditNickDialog()" 
          >
            Add account
          </v-btn>
          <v-btn
            icon
            variant="small"
            @click="getUsergameSummary(hero.hero_id)"
            v-if="hero" 
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Table Section -->
      <v-card>
        <v-table v-if="currentHeroNicknames" class="custom-table">
          <thead>
            <tr>
              <th>Analyze</th>
              <th>Nickname</th>
              <th>Site</th>
              <th>
                <TableCheckbox
                  v-model="categories"
                  value="4"
                  label="Blitz"
                  :disabled="!currentHeroStatistics"
                  color="primary"
                />
              </th>
              <th>
                <TableCheckbox
                  v-model="categories"
                  value="5"
                  label="Bullet"
                  :disabled="!currentHeroStatistics"
                  color="primary"
                />
              </th>
              <th>
                <TableCheckbox
                  v-model="categories"
                  value="3"
                  label="Rapid"
                  :disabled="!currentHeroStatistics"
                  color="primary"
                />
              </th>
              <th>
                <TableCheckbox
                  v-model="categories"
                  value="2"
                  label="Classical"
                  :disabled="!currentHeroStatistics"
                  color="primary"
                />
              </th>
              <th>Period of time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getTableData(currentHeroNicknames)" :key="item.player_name">
              <td class="align-center">
                <v-switch
                  inset
                  :model-value="item.enabled !== 0"
                  @change="handleSwitchChange(item)"
                  :class="{ 'custom-switch': item.enabled, 'custom-switch-off': !item.enabled }"
                />
              </td>
              <td class="align-center">{{ item.player_name }}</td>
              <td class="align-center">
                <playground-logo :id="item.playground" :width="50" />
              </td>
              <td class="align-center">{{ item.blitz }}</td>
              <td class="align-center">{{ item.bullet }}</td>
              <td class="align-center">{{ item.rapid }}</td>
              <td class="align-center">{{ item.classical }}</td>
              <td class="align-center">{{ item.dateRange }}</td>
              <td class="align-center">
                <v-menu>
                  <template v-slot:activator="{ props }">
                     <IconButton
                        icon="mdi-cog-outline"
                        color="#4A4A4A"
                        iconColor="#9A9A9A"  
                        :size="'32px'"
                        :border-radius="'8px'"                  
                        v-bind="props"  
                        disableClick=true
                      />
                  </template>
                  <v-list>
                    <v-list-item @click="handleRemoveNickButtonClick(item)">
                      <v-list-item-title>Delete Account</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="handleSwapClick(item.nickname_id)">
                      <v-list-item-title>Relocate Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr v-if="getTableDataTotal(currentHeroNicknames).length" :key="'total'">
              <td :colspan="3" class="align-center">Total number of games to be analysed</td>
              <td class="align-center">{{ getTableDataTotal(currentHeroNicknames)[0].blitz }}</td>
              <td class="align-center">{{ getTableDataTotal(currentHeroNicknames)[0].bullet }}</td>
              <td class="align-center">{{ getTableDataTotal(currentHeroNicknames)[0].rapid }}</td>
              <td class="align-center">{{ getTableDataTotal(currentHeroNicknames)[0].classical }}</td>
              <td class="align-center"></td>
              <td class="align-center"></td>
            </tr>
          </tbody>
        </v-table>
      </v-card>

      <!-- Footer Section -->
      <div class="d-flex justify-space-between mt-4">
        <v-btn @click="()=>{}">Activate extended tariff</v-btn>
        <v-btn @click="handleClick" :disabled="categories.length === 0">
          {{ $t('cardSummary.analysisQuery') }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>



<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EditAccountDialog from './EditAccountDialog.vue';
import TableCheckbox from './TableCheckbox.vue';
import IconButton from './IconButton.vue';
import PlaygroundLogo from '../../../src/assets/PlaygroundLogo.vue'; 
const defaultNickname = {
  hero_id: null,
  playground: 0,
  nick: '',
};

export default {
  emits: ['addNickname'],
  props: ['hero'],
  data: () => ({
    subscriptionDialog: false,
    editingNickname: null,
    nickToUploadGames: null,
    nickToDelete: null,
    uploadModel: null,
    reassignModel: null,
    categories: [], 
    localeItems: [{title: 'en', id: '1', name:'English' }, { title:'ru', id: '4', name:'Русский' }],
  }),
  components: {
    EditAccountDialog,
    PlaygroundLogo,
    TableCheckbox,
    IconButton,
  },
  computed: {
    ...mapState('data', [
      'heroNicknames',
      'heroStatistics',
      'playgroundList',
      'heroesList',
    ]),
    ...mapGetters('data', ['getCurrentHeroNicknames']),
    uploadDialogModel: {
      get() {
        return this.uploadModel ? true : false;
      },
      set() {
        this.uploadModel = null;
      },
    },
    uploadDisabled() {
      const tc = this.uploadModel?.timecontrols

      return !tc || tc?.length === 0;
    },
    rangeItems() {
      const uploadModel = this.uploadModel;
      if (!uploadModel) return [];
      const lichessItems = [
        {
          id: '2,3',
          title: 'Rapid',
        },
        {
          id: '4',
          title: 'Blitz',
        },
        {
          id: '5',
          title: 'Bullet',
        },
      ];

      return lichessItems;
    },
    confirmDeleteDialogModel: {
      get() {
        return this.nickToDelete ? true : false;
      },
      set(val) {
        if (!val) {
          this.nickToDelete = null;
        }
      },
    },
    editAccountDialogModel: {
      get() {
        return this.editingNickname ? true : false;
      },
      set(val) {
        if (!val) {
          this.editingNickname = null;
        }
      },
    },
    currentHeroNicknames() {
      const hero = this.hero;
      const hero_id = hero.hero_id;
      return this.getCurrentHeroNicknames(hero_id);
    },
    currentHeroStatistics() {
      const heroStatistics = this.heroStatistics;
      const currentHeroNicknames = this.currentHeroNicknames;

      if (!currentHeroNicknames) return [];
      const hero_id = this.hero.hero_id;
      return heroStatistics[hero_id]?.filter((stat) =>
        currentHeroNicknames.find(
          (nick) => nick.nickname_id === stat.nickname_id && nick.enabled,
        )
          ? true
          : false,
      );
    },
    getTableData(){

      const currentHeroStatistics = this.currentHeroStatistics  ;

      return (key) => { 
        if (!currentHeroStatistics  ) return []; 
        return  key.map((item) => {
          const statObj =
            currentHeroStatistics.find(
              (stat) => stat.nickname_id === item.nickname_id,
            ) || {playground: "",
                  correspondence: [],
                  classical: [],
                  rapid: [],
                  blitz: [],
                  bullet: [],};

          return {
            nickname_id: item.nickname_id,
            enabled: item.enabled,
            playground: this.getPlaygroundTitle(item.playground).title,
            player_name: item.nick,
            classical: item.enabled ? statObj.classical[0] :'',
            rapid: item.enabled ? statObj.rapid[0] :'',
            blitz: item.enabled ? statObj.blitz[0] :'',
            bullet: item.enabled ? statObj.bullet[0] :'',
            dateRange: item.enabled ?'All time' : '',
          };
        }) 
        }
    },
    getTableDataTotal(){

      const currentHeroStatistics = this.currentHeroStatistics  ;

      return (key) => {
        
        if (!currentHeroStatistics  ) return [];
       
        return  [key.reduce((acc,item) => {
                  const statObj =
                    currentHeroStatistics.find(
                      (stat) => stat.nickname_id === item.nickname_id,
                    ) || {classical:[0],rapid:[0],blitz:[0],bullet:[0]};
                  acc.classical +=statObj.classical[0]||0;
                  acc.rapid +=statObj.rapid[0]||0;
                  acc.blitz +=statObj.blitz[0]||0;
                  acc.bullet +=statObj.bullet[0]||0;
                  
                    return acc;
                },{classical:0,rapid:0,blitz:0,bullet:0})];
        }
    },
       getTableRows() {
      const currentHeroStatistics = this.currentHeroStatistics; 

      return (key) => { 
        if (!currentHeroStatistics) return [];
        const result = currentHeroStatistics.reduce((acc, item) => {
          if (!item[key] || item.enabled === false) return acc;
          const parsedArray = item[key];
          if (acc.length === 0) {
            acc = parsedArray;
            return acc;
          } else {
            acc = acc.map((prev, index) => (prev += parsedArray[index]));
          }

          return acc;
        }, []);
        if (result.length === 0 || result.filter((i) => i !== 0).length === 0) {
          return [this.$t('cardSummary.noGames')];
        } 
        return result;
      };
    },
    getPlaygroundTitle() {
      const playgroundList = this.playgroundList;
      return (id) => playgroundList.find((el) => el.playground_id === id);
    },
  },
  methods: {
    ...mapActions('data', [
      'getHeroNicknames',
      'deleteNickName',
      'uploadGames',
      'toggleNickname',
      'reassignNickname',
      'getUsergameSummary'
    ]),
    handleRemoveNickButtonClick(item) { 
      const obj = {...item}
      obj.hero_id = this.hero.hero_id
      this.nickToDelete = obj;
    },
     handleSwitchChange(value) { 
      const command = value.enabled ?  'disable' : 'enable' ;

      this.toggleNickname({
        command,
        nickname_id: value.nickname_id,
        heroid: this.hero.hero_id,
      });
    },

    handleEyeClick(nickname) {
      const command = nickname.enabled ? 'disable' : 'enable';

      this.toggleNickname({
        command,
        nickname_id: nickname.nickname_id,
        heroid: this.hero.hero_id,
      });
    },
    handleSwapClick(nickid) {
      this.reassignModel = {
        hid: null,
        nickid,
      };
    },
    handleReassign() {
      this.reassignNickname(this.reassignModel);
      this.reassignModel = null;
    },
    handleUpload() {
      this.uploadGames({
        requestParams: this.uploadModel,
        heroId: this.hero.hero_id,
      });
      this.uploadModel = null;
    },
    handleClick() {
      this.subscriptionDialog = true;
    },
    goToAnalysis() {
      this.$router.push({
        name: 'Analysis',
        params: {
          heroid: this.hero.hero_id,
          categories: this.categories.join(','),
        },
      });
    },
    openEditNickDialog(nick) { 
      this.editingNickname = nick || defaultNickname;
    },
    async handleDelete() {
      const nickToDelete = this.nickToDelete;
      await this.deleteNickName(nickToDelete);
      this.nickToDelete = null;
    },
    openUploadGamesDialog(nickname) {
      const current = new Date();

      const to = current.toISOString().split('T')[0];
      const from = new Date();
      const playgroundNames = {
        3: 'Lichess.org',
        4: 'Chess.com'
      }
      from.setDate(from.getDate() - 183);

      this.uploadModel = {
        nickid: nickname.nickname_id,
        target: playgroundNames[nickname.playground],
        timecontrols: [],
        date_from: from.toISOString().split('T')[0],
        date_to: to,
      };
    },
  },
  watch: {
    heroNicknames: {
      deep: true,
      immediate: true,
      handler: function () {},
    },
    heroStatistics: {
      deep: true,
      immediate: true,
      handler: function () {},
    },
    currentHeroStatistics: {
      deep: true,
      immediate: true,
      handler: function () {},
    },
    hero: {
      immediate: true,
      handler(val) {
        this.categories = [];
        if (!this.currentHeroNicknames && val) {
          this.getHeroNicknames(val.hero_id);
        }
      },
    },
  },
};
</script>

<style >
.v-card__loader {
  /* Hide loader if using custom CSS */
  display: none;
} 
/* Ensure high specificity for overriding Vuetify styles */

/* Custom styling for the switch thumb (circle) when enabled */
.custom-switch .v-switch__thumb {
  background-color: rgb(255, 255, 255) ; /* Orange color for the thumb when enabled */
}
 
/* Custom styling for the switch track (background) */
.custom-switch .v-switch__track {
  height: 22px;
  width:40px;
  background-color: #1D85DD; /* Light gray color for the track when disabled */
} 

.custom-switch-off .v-switch__thumb {
  background-color: #797C84; /* Orange color for the thumb when enabled */
}
 
/* Custom styling for the switch when it's on */
.custom-switch-off .v-switch__track {
  width:40px;
  height: 22px;
  background-color: #4A4A4A ; /* Green background when switch is on */
}
.custom-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are collapsed for the table */
  border-radius: 8px; /* Rounds the corners of the table */
  overflow: hidden; /* Ensures content does not overflow rounded corners */
}

.custom-table thead,
.custom-table tbody {
  border: 1px solid #ddd; /* Outer border for the table */
}

.custom-table th,
.custom-table td {
  padding: 8px; /* Padding inside cells */
  text-align: left; /* Align text to the left */
}

.custom-table th {
  background-color: #1E1E1E; /* Slightly darker gray for headers */
  font-weight: bold; /* Bold text for headers */
}

.custom-table td {
  background-color: #232323; /* Remove background color from cells */
}

.custom-table tr {
  border-bottom: 1px solid #ddd; /* Bottom border for each row */
}

.custom-table tr{
  border-left: 1px solid #ddd; /* Left border for the first cell in each row */
  background-color: #1E1E1E; /* Background color for the first column */
}
 

</style>
