<template>
  <v-app>
    <v-layout>
      <v-app-bar
        :color="'#171717'"
        class="custom-app-bar"
        v-if="isAuthenticated"
        app
      >
        <logo-svg @click="handleLogoClick" style="cursor: pointer" />
        <!-- <v-btn variant="tonal" color="white" class="ml-4 font-weight-bold" @click="handleAboutClick">
          О компании
        </v-btn> -->
        <v-spacer></v-spacer>
        <portal-target name="app-bar-portal" />
        <v-spacer></v-spacer>

        <div class="mr-2">
          <v-icon>mdi-email-outline</v-icon>
          <a v-bind:href="'mailto:' + getMail">{{ getMail }}</a>
        </div>
        <div class="mr-2">
          <v-icon>mdi-tune-vertical</v-icon>
        </div>
        <div class="mr-2">Settings</div>
        <div class="ml-2">{{ currentLangName }}</div>

        <div>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in localeItems"
                :key="item.id"
                @click="selectLanguage(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <portal-target name="app-bar-portal-user" />
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn variant="text" icon="mdi-account-circle" v-bind="props" />
          </template>
          <v-list style="cursor: pointer">
            <v-list-item @click="handleLogOut"> Log out </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-main>
        <div class="content">
          <router-view></router-view>
        </div>
        <!-- <div
          style="
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100vw;
            height: 24px;
          "
          class="bg-white text-center"
        >
          <div class="text-blue">
            {{ `ООО Интеллектуальные системы, ${new Date().getFullYear()} г ` }}
          </div>
        </div> -->
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LogoSvg from '/src/assets/LogoSvg.vue';
const defaultFilters = {
  pgid: 3,
  avelo: [2100, 2500, 2900],
  categories: [3],
  elodiff: '2,3,4,5,6',
};

export default {
  name: 'App',
  data: () => ({
    menuVisible: false,
    localeItems: [{title: 'en', id: '1', name:'English' }, { title:'ru', id: '4', name:'Русский' }],
  }),
  computed: {
    ...mapState(['isAuthenticated']),
    ...mapState('ui', ['userPreferences']),
    currentLangName() {
      const lang = this.localeItems.find(i => i.id === this.langModel.id);
      return lang ? lang.name : 'English';
    },
    langModel:{
      get() {
        const lang_interface = this.userPreferences?.lang_interface;
        if (lang_interface === undefined) return { title: 'en', id: '1' };
        return (
          this.localeItems.find((i) => Number(i.id) === lang_interface) || {
            title: 'en',
            id: '1',
          }
        );
      },
      set(val) {
        if (!this.isAuthenticated) {
          this.SET_DATA_BY_KEY({
            key: 'userPreferences',
            value: { lang_interface: Number(val.id) },
          });
          localStorage.setItem('rememberLocaleOnLogin', true);
        } else {
          this.setUserPreferences({
            ...this.userPreferences,
            lang_interface: Number(val.id),
          });
        }
      },

      

    },
    getMail() {
      return process.env.VUE_APP_MAIL;
    },
  },
  components: {
    LogoSvg,
  },
  methods: {
    ...mapActions('ui', ['setUserPreferences']),
    ...mapActions(["logOutUser"]),
    handleLogoClick() {
      this.$router.push('/');
    },
    handleReportBug() {
      window.location.href = `mailto:${process.env.VUE_APP_MAIL}`;
    },
    handleAboutClick() {
      this.$router.push('/about');
    },
      handleLogOut() {
       const cb = () => {

        this.$router.push("/login");
        }
      this.logOutUser(cb);
    },
    selectLanguage(item) {
      this.langModel = item;
      this.menuVisible = false; 
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible; 
    }
  },
  mounted() {
    console.log('t', this.$t)
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.commit('SET_DATA_BY_KEY', {
        key: 'isAuthenticated',
        value: true,
      });
    }
    this.$store.commit('SET_DATA_BY_KEY', {
      key: 'sizes',
      value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
    });

    window.addEventListener('resize', () => {
      this.$store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
          vh:
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight,
          vw:
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth,
        },
      });
    });

    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('ip-from', data.ip);
      })
      .catch((error) => {
        console.error('Error fetching IP:', error);
      });
  },
  watch: {
    isAuthenticated: {
      immediate: true,
      handler: async function (val) {
        if (val === true) {
          this.$store.dispatch('ui/getSettingsAndPreferences');
          // this.$store.dispatch('ui/getUserSettings');
          // this.$store.dispatch('ui/getUserPreferences');
        }
      },
    },
    userPreferences: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val && val.lang_interface !== undefined) {
          const targ = this.localeItems.find(
            (i) => Number(i.id) === val.lang_interface,
          );
          this.$i18n.locale = targ?.title || 'en';
        }
      },
    },
  },
};
</script>
<style>

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html {
  font-family: "Century Gothic", sans-serif !important;
}
.pointer {
  cursor: pointer;
}
.custom-input input {
  padding-left: 50px;
}

.custom-app-bar {
  border-bottom: 1px solid #3c3c3c; 
}

.v-select .v-select__append-inner {
  display: none; 
}

.content {
  background-color: #171717;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}


.custom-disabled-btn.v-btn--disabled {
  background-color: rgba(43,44,49, 1) !important;  
  color: rgba(255,255,255, 1) !important; 
  border-color: #007bff !important; 
  opacity: 0.1 !important;  
  filter: none !important;
}

.custom-disabled-btn.--v-theme-overlay-multiplier{
  background-color: rgba(43,44,49, 1) !important;  
}
 
 /* width */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255,255,255,.4);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(255,255,255,.8);
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 9999px;
    min-height: 40px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(255,255,255,1);
  }

</style>
