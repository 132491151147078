import defaultState from './state'

export default {
    "SET_DATA_BY_KEY": (state, {key, value, freeze=false }) => {
        if(!freeze) {
            state[key] = value
        } else {
            state[key] = Object.freeze(value)
        }
        
    },
    RESET: (state) => {
        const keys = Object.keys(state)
        for(const key of keys) {
            state[key] = defaultState[key]
        }
    }
};