import axios from '@/plugins/axiosWrapper';
import cloneDeep from 'lodash/cloneDeep';
import deepFind from '@/helpers/deepFind';
import fixPrefs from '@/helpers/fixPrefs';
import orch from '@/plugins/requestOrchestrator';
import { unzip } from 'lodash';


// axios("https://lichess.org/api/user/bakhmatov")
// axios("https://api.chess.com/pub/player/bakhmatov")

export default {
  async updateCompareFilters({ dispatch, commit, state }, { filters, heroid }) {
    let clone;
    if (state.selectedOpening) {
      clone = cloneDeep(state.selectedOpening);
    }

    await commit('SET_DATA_BY_KEY', { key: 'compareFilters', value: filters });
    // await commit("SET_DATA_BY_KEY", { key: "selectedMistake", value: null });
    await commit('SET_DATA_BY_KEY', { key: 'openingsTree', value: null });
    await commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: null });
    // await dispatch("getMistakesOpening", { heroid });
    if (clone) {
      const { hm, position_id, herowhite } = clone;
 
      dispatch('getOpeningsHeroNode', {
        posid: position_id,
        heroid,
        hm,
        herowhite: state.selectedOpening?.herowhite || 1
      });
    }
  },
  async modifyPositionLabel(
    { dispatch, state },
    { opening, scenario, heroid, herocats },
  ) {
    const token = localStorage.getItem('token');

    if (scenario === 'toWatchlist') {
      const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',

        //new API
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          heroid: Number(heroid),
          posid: opening.position_id,
          mtd: 'openings/addposlabel',
          labid: 2,
        },
        // signal: register({
        //   heroid: Number(heroid),
        //   posid: opening.position_id,
        //   mtd: 'openings/addposlabel',
        //   labid: 2,
        // }).signal

      }).catch((e) => console.log(e));

      if (resp?.data?.message !== 'OK') return;
    }

    if (scenario === 'toIgnore') {
      await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',
        //new API
        // url: `${process.env.VUE_APP_DEV_API}/api/`,
        // data: {heroid, posfromid: opening.position_id , "mtd": "openings/delposlabel", labid:2 },

        url: `${process.env.VUE_APP_DEV_API}/api/`,

        data: {
          heroid: Number(heroid),
          posid: Number(opening.position_id),
          dellabid: 2,
          addlabid: 3,
          mtd: 'openings/updateposlabel',
        },
      }).catch((e) => console.log(e));

      // const removeResp = await axios({
      //   headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      //   method: "post",
      //   //new API
      //   // url: `${process.env.VUE_APP_DEV_API}/api/`,
      //   // data: {heroid, posfromid: opening.position_id , "mtd": "openings/delposlabel", labid:2 },

      //   url: `${process.env.VUE_APP_DEV_API}/api/`,

      //   data: {
      //     heroid: Number(heroid),
      //     posid: Number(opening.position_id),
      //     labid: 2,
      //     mtd: "openings/delposlabel"
      //   },
      // }).catch((e) => console.log(e));

      // if (removeResp?.data?.message !== "OK") return;

      // const addResp = await axios({
      //   headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      //   method: "post",
      //   //new API
      //   // url: `${process.env.VUE_APP_DEV_API}/api/`,
      //   // data: {heroid, posfromid: opening.position_id , "mtd": "openings/addposlabel", labid:3 },
      //   url: `${process.env.VUE_APP_DEV_API}/api/`,

      //   data: {
      //     heroid: Number(heroid),
      //     posid: Number(opening.position_id),
      //     labid: 3,
      //     mtd: "openings/addposlabel"
      //   },

      // }).catch((e) => console.log(e));

      // if (addResp?.data?.message !== "OK") return;
    }

    if (scenario === 'toDefault') {
      const removeResp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,

        data: {
          heroid: Number(heroid),
          posid: Number(opening.position_id),
          labid: 3,
          mtd: 'openings/delposlabel',
        },
      }).catch((e) => console.log(e));

      if (removeResp?.data?.message !== 'OK') return;
    }

    if (scenario === 'toStudied') {
      const removeResp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,

        data: {
          heroid: Number(heroid),
          posid: Number(opening.position_id),
          labid: 1,
          mtd: 'openings/addposlabel',
        },
      }).catch((e) => console.log(e));

      if (removeResp?.data?.message !== 'OK') return;
    }

    dispatch('getMistakesOpening', { heroid, herocats });
  },
  async getOpenings(
    { commit, dispatch },
    { fen, heroid, position_id, hm, herocats,herowhite },
  ) {

   
    const token = localStorage.getItem('token');
    if (fen) {
      const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          fen,
          mtd: 'fentoposid',
        },
        signal: orch.register({
          fen,
          mtd: 'fentoposid',
        }).signal
      });

      orch.kill({
        fen,
        mtd: 'fentoposid',
      })

      commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: null });
      // console.log('resp', resp?.data)
      if (resp && resp.data) {
        
       

        commit('SET_DATA_BY_KEY', {
          key: 'parsedFen',
          value: resp.data,
        });

          dispatch('getOpeningsHeroNode', {
          posid: resp.data?.posid,
          heroid,
          herowhite: resp.data?.herowhite,
          herocats,
          hm: resp.data?.hm
        });
      }
    }

    if (position_id) {
    //  console.log(herocats)
      dispatch('getOpeningsHeroNode', {
        posid: position_id,
        heroid,
        hm,
        herocats,
        herowhite
      });
    }
  },
  async getOpeningsHeroNode(
    { commit, state, dispatch, rootState },
    {
      heroid = 31,
      herocats = '1,2,3,4',
      hm = 40,
      // pgid = 2,
      // avelo = "2400,2700",
      // categories = "1,2,3",
      // elodiff = "1,3,4,5",
      posid = 10,
      datefrom = '1970-01-01 00:00:00',
      dateto = '2030-12-31 23:59:59',
      herowhite = 1,
    },
  ) {
    const token = localStorage.getItem('token');

    const userPreferences = rootState.ui.userPreferences;
    const { compare_pgid, compare_tc, compare_elo, compare_ed } =
      fixPrefs(userPreferences);
    // const compareFilters = state.compareFilters;
    // const { avelo, categories, pgid, elodiff } = compareFilters;

    const par = {
      mtd: 'openings/heronode',
      heroid: Number(heroid),
      posid: Number(posid),
      hm: Number(hm || state.parsedFen.hm),
      herowhite,
      datefrom,
      dateto,
      herocats: Array.isArray(herocats)
        ? herocats
        : herocats.split(',').map((i) => Number(i)),
    }

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from')  },
      method: 'post',
      signal: orch.register(par).signal,
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: par,
    });
    orch.kill(par)

    if (resp?.data) {
      const obj = {
        ...resp.data,
        children: [],
        id: "gggfh37900",
        nodethis: "gggfh37900",
        // showChildren: true,
        visibleChildren: 0,
        hm: Number(hm || state.parsedFen.hm),
        posid: Number(posid || state.parsedFen.posid)
        // hm: hm || state.parsedFen.hm,
        // un_id: Math.random(),
      };

      commit('SET_DATA_BY_KEY', { key: 'openingsTree', value: obj });

      commit('SET_DATA_BY_KEY', { key: 'selectedOpening', value: obj });

      commit('SET_DATA_BY_KEY', { key: 'openSequense', value: 3 });
    }
  },
  async getMistakesOpening({ commit, state, rootState }, params) {
    const token = localStorage.getItem('token');
    const selectedMistake = state.selectedMistake;
    const userPreferences = rootState.ui.userPreferences;
    const { mistakes_deviation } = userPreferences;

    const paramsToRequest = {
      heroid: 31,
      herocats: '1,2,3,4',
      fen: '',
      datefrom: '1970-01-01 00:00:00',
      dateto: '2030-12-31 23:59:59',
      resdev: mistakes_deviation || 40,
      filters: '0,0,0,0',
      sort: 0,
      ...params,
    };

    if (!paramsToRequest.fen) {
      delete paramsToRequest.fen;
    }

    const par = {
      mtd: 'usergames/mistakesopening',
      heroid: Number(paramsToRequest.heroid),
      datefrom: paramsToRequest.datefrom,
      dateto: paramsToRequest.dateto,
      herocats: paramsToRequest.herocats.split(',').map((i) => Number(i)),
      fen: paramsToRequest.fen,
    }

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      method: 'post',
      data: par,
      signal: orch.register(par).signal,
    }).catch((e) => console.log(e));
    orch.kill(par)
    if (resp && resp.data && resp.data) {
      commit('SET_DATA_BY_KEY', {
        key: 'mistakesOpenings',
        value: resp.data,
      });

      if (selectedMistake) {
        const occurance = resp.data.find(
          (mistake) =>
            mistake.fen === selectedMistake.fen &&
            mistake.prevmove === selectedMistake.prevmove &&
            mistake.position_id === selectedMistake.position_id,
        );
        if (occurance) {
          commit('SET_DATA_BY_KEY', {
            key: 'selectedMistake',
            value: occurance,
          });
        }
      }

      commit('SET_DATA_BY_KEY', { key: 'openingOptionsFilter', value: 1 });
    }
  },
  async loadChildren(
    { state, dispatch, commit },
    { _node_id, auto = false, heroid, preventOpen = false, herocats, posid, nodeid },
  ) {

    if(state.childrenLoadingProgress[nodeid]) {
      return
    } else {
      const clone = cloneDeep(state.childrenLoadingProgress)
      clone[nodeid] = true
      commit('SET_DATA_BY_KEY', { key: 'childrenLoadingProgress', value: clone });
 
    }
    // await new Promise(res => setTimeout(res,10))
    const clone = cloneDeep(state.openingsTree);
    
    if (!clone) return;
    
    let target;
    if (nodeid === clone.nodethis) {
      target = clone;
    } else {
      target = deepFind({ array: clone.children, value: nodeid, key: 'nodethis' });
    }

    // if (!preventOpen) {
    //   console.log(target)
    //   target.showChildren = !target.showChildren;
    // }

  
    const localDepthFull = localStorage.getItem("depthfull") && Number(localStorage.getItem("depthfull")) || 3
    const localDepthFilter = localStorage.getItem("depthfilter") && Number(localStorage.getItem("depthfilter")) || 11

    if(!target) return
    if (target.children?.length === 0) {
     
      const isRoot = target.nodethis === clone.nodethis
     
     
      target.children = await dispatch('getHeroVariants', {
        posid: Number(posid),
        hm_child: target.hm_child,
        hm: Number(target.hm),
        heroid: Number(heroid),
        herocats: Array.isArray(herocats)
          ? herocats
          : herocats.split(',').map((i) => Number(i)),
        depthfull: isRoot ? localDepthFull : 2,
        depthfilter: isRoot ? localDepthFilter : 0,
        herowhite: clone.hm % 2 === 0 ? 0 : 1,
        nodeid
      });
    }

    await commit('SET_DATA_BY_KEY', { key: 'openingsTree', value: clone, freeze: true });


    const clone2 = cloneDeep(state.childrenLoadingProgress)
      delete clone2[nodeid]
      commit('SET_DATA_BY_KEY', { key: 'childrenLoadingProgress', value: clone2 });


    // if(auto) {
    //   await commit("SET_DATA_BY_KEY", {
    //     key: "openSequense",
    //     value: state.openSequense - 1,
    //   });
    // }

    // if (
    //   target &&
    //   auto &&
    //   state.openSequense > 0
    // ) {

    //   for (let i = 0; i < target.children.length; i++) {
    //     const child = target.children[i];
    //     if (i === 0 && child && child.iscrown === 0) {
    //       await dispatch("loadChildren", {
    //         _node_id: child.id,
    //         auto: i === 0,
    //         needChangeStatus: false,
    //         heroid,
    //       });
    //     }
    //   }
    // }
  },
  async getHeroVariants({ commit, rootState }, requestParams) {
    const userPreferences = rootState.ui.userPreferences;
    const { showmove_deviation } = userPreferences;

    const rootId = requestParams?.nodeid || "gggfh37900"


    const defaultParams = {
      heroid: 31,
      herocats: [1, 2, 3, 4],
      hm: 40,



      posid: 10,
      datefrom: '1970-01-01 00:00:00',
      dateto: '2030-12-31 23:59:59',
      herowhite: 1, // брать на основе рутового hm
      depthfull: 2,
      depthfilter: 0,
      // generations: 3
    };

    const token = localStorage.getItem('token');
    const compareFilters = localStorage.getItem('compareFilters');

    // if (compareFilters) {
    //   const filtersObj = JSON.parse(compareFilters);
    //   if (filtersObj.avelo) {
    //     requestParams.avelo = filtersObj.avelo;
    //   }
    //   if (filtersObj.categories) {
    //     requestParams.categories = filtersObj.categories;
    //   }
    //   if (filtersObj.elodiff) {
    //     requestParams.elodiff = Array.isArray(filtersObj.elodiff)
    //       ? filtersObj.elodiff
    //       : filtersObj.elodiff.split(',').map((i) => Number(i));
    //   }
    //   if (filtersObj.pgid) {
    //     requestParams.pgid = filtersObj.pgid;
    //   }
    // }

    const par = {
      mtd: 'openings/herovariants',
      ...defaultParams,
      ...requestParams,
      nodeid: rootId
    }

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: par,
      signal: orch.register(par).signal, 
    });

    orch.kill(par)

    if (resp) {
      const mapped = resp?.data?.map((i) => ({
        ...i,
        // children: [],
        id: i.nodethis,
        // un_id: Math.random(),
        // showChildren: false,
        visibleChildren: 0,
        lines: true,
    
      }));
      // .sort((a, b) => {
      //   const aVal = a.pos_from_id;
      //   const bVal = b.pos_from_id;

      //   return aVal > bVal ? 1 : -1;
      // });

      const reduceAndSortArray = (array) => {
        const groups = array.reduce((acc, item) => {
          if (!acc[item.nodeparent]) {
            acc[item.nodeparent] = [];
          }
          acc[item.nodeparent].push(item);
          return acc;
        }, {});

        const sortedArrays = Object.values(groups).map((group) => {
          const sorted = group.sort((a, b) => {
            const aVal = a.waste;
            const bVal = b.waste;

            return aVal > bVal ? 1 : -1;
          });
          return sorted;
        });
        return sortedArrays.flat();
      };
      if(!mapped) return []
      const reducedAndSorted = reduceAndSortArray(mapped)

      let result = [];
      // console.log(reducedAndSorted) 
      for (let child of reducedAndSorted) {
        const { nodeparent } = child;
        
        if (nodeparent == rootId) {
          result.push({...child});
        } else {

          const parent = deepFind({ array: result, value: nodeparent, key: 'nodethis' });

          if (parent) {
              console.log('p1')
            parent.children.push({ ...child });
          } else {
            const parent = deepFind({
              array: reducedAndSorted,
              value: nodeparent, key: 'nodethis'
            });
            if (parent) {
              console.log('p2')
              parent.children.push({ ...child });
            }

            // result.push(child);
          }
        }
      }

      const openChildren = (item) => {
        if (item.children.length > 0) {
          // item.showChildren = true;
          if (item.children && item.children[0]) {
            openChildren(item.children[0]);
          }
        }
      };

      // openChildren(result[0]);
// console.log(result)
      return result;
    }
    return [];
  },
  async getHeroesList({ commit }) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'heroes/list' },
      signal: orch.register({ mtd: 'heroes/list' }).signal,

    }).catch((e) => {
      console.log(e);
    });

    orch.kill({ mtd: 'heroes/list' })

    if (resp && resp.data) {
      commit('SET_DATA_BY_KEY', { key: 'heroesList', value: resp.data });
    }
  },
  async getHeroNicknames({ commit, state, dispatch }, hero_id) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'nicknames/list' },
      signal: orch.register({ mtd: 'nicknames/list' }).signal,
    }).catch((e) => {
      console.log(e);
    });
    orch.kill({ mtd: 'nicknames/list' })

    if (resp && resp.data) {
      const clone = cloneDeep(state.heroNicknames);
      clone[hero_id] = resp.data;
      await commit('SET_DATA_BY_KEY', { key: 'heroNicknames', value: clone });
      await commit('SET_DATA_BY_KEY', { key: 'heroNicknamesRaw', value: resp.data });
      dispatch('getUsergameSummary', hero_id);
    }
  },
  async getUnfinishedExercises({commit}, heroid) {
    const token = localStorage.getItem('token');
    const data = { mtd: 'training/incomplete'}

    if(heroid) {
      data.heroid = heroid
    }

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: data,
      signal: orch.register(data).signal,
    }).catch((e) => {
      console.log(e);
    });

    orch.kill(data)
    if (resp && resp.data) {
      await commit('SET_DATA_BY_KEY', { key: 'unfinishedExercises', value: resp.data });
    }


  }, 
  async renameHero(
    { commit, dispatch },
    { heroNameModel, siteModel, heroId },
  ) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        mtd: 'heroes/rename',
        name: heroNameModel,
        heroid:heroId,
        // pgid: Number(siteModel),
        // heroid: Number(heroId),
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroesList');
      return
    } else if(resp.errorMessage) {
      return {errorMessage: resp.errorMessage}
    }
  },


  async addHero(
    { commit, dispatch },
    { heroNameModel, siteModel, heroId },
  ) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        mtd: 'heroes/add',
        name: heroNameModel,
        // pgid: Number(siteModel),
        // heroid: Number(heroId),
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroesList');
      return
    } else if(resp.errorMessage) {
      return {errorMessage: resp.errorMessage}
    }
  },
  async deleteHero(
    { commit, dispatch },
    { heroId },
  ) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        mtd: 'heroes/delete',
        heroid: heroId,
        // pgid: Number(siteModel),
        // heroid: Number(heroId),
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroesList');
      return
    } else if(resp.errorMessage) {
      return {errorMessage: resp.errorMessage}
    }
  },
  async addHeroNickName(
    { commit, dispatch },
    { nickNameModel, siteModel, heroId },
  ) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        mtd: 'nicknames/add',
        nickname: nickNameModel,
        pgid: Number(siteModel),
        heroid: Number(heroId),
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroNicknames', heroId);
      return
    } else if(resp.errorMessage) {
      return {errorMessage: resp.errorMessage}
    }
  },
  async deleteNickName({ dispatch }, { nickname_id, hero_id }) {
    console.log('nickname_id, hero_id',nickname_id, hero_id)
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
     //  data:{mtd: "help"},
      data: { mtd: 'nicknames/zip', nickid: Number(nickname_id) },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroNicknames', hero_id);
    }
  },
  async unzipNickname({ dispatch }, { nickname_id, hero_id }) {
    console.log('nickname_id, hero_id',nickname_id, hero_id)
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
     //  data:{mtd: "help"},
      data: { mtd: 'nicknames/unzip', nickid: Number(nickname_id) },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getHeroNicknames', hero_id);
    }
  },
  async toggleNickname({ commit, dispatch, state }, { command, nickname_id, heroid }) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: `nicknames/${command}`, nickid: Number(nickname_id) },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      const clone = cloneDeep(state.heroNicknames);
      clone[heroid].forEach((element) => {
        if (element.nickname_id === nickname_id) {
          element.enabled = !element.enabled;
        }
      });

      commit('SET_DATA_BY_KEY', { key: 'heroNicknames', value: clone });

      dispatch('getUsergameSummary', heroid)
    }
  },
  async reassignNickname({ dispatch, state, commit }, params) {
    const token = localStorage.getItem('token');

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        nickid: Number(params.nickid),
        heroid: Number(params.hid),
        mtd: 'nicknames/reassign',
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      const clone = cloneDeep(state.heroNicknames);

      const heroids = Object.keys(clone);

      for (let key of heroids) {
        clone[key] = clone[key].filter(
          (nick) => params.nickid !== nick.nickname_id,
        );
      }

      await commit('SET_DATA_BY_KEY', { key: 'heroNicknames', value: clone });

      await dispatch('getHeroNicknames', params.hid);
    }
  },
  async getUsergameSummary({ commit, state }, hero_id) {
    const token = localStorage.getItem('token');
    const heroNicknamesIds = state.heroNicknames[hero_id];
    if (!heroNicknamesIds || heroNicknamesIds.length === 0) return;

    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'usergames/summary' },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      const clone = cloneDeep(state.heroStatistics);
      clone[hero_id] = resp.data;
      commit('SET_DATA_BY_KEY', { key: 'heroStatistics', value: clone });
    }
  },
  async uploadGames({ dispatch }, { requestParams, heroId }) {
    const token = localStorage.getItem('token');
    const prepareTimeControls = (arr) => {
      try {
        const merged = arr.join(',')
        return merged.split(',').map(i => Number(i))
      } catch(e) {
        return arr
      }
    }
    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: {
        mtd: 'loadgames',
        nickid: Number(requestParams.nickid),
        timecontrols: prepareTimeControls(requestParams.timecontrols),
        datefrom: requestParams.date_from,
        dateto: requestParams.date_to,
        pgn: '',
      },
    }).catch((e) => {
      console.log(e);
    });
    if (resp && resp.data) {
      dispatch('getUsergameSummary', heroId);

      console.log('success');
    }
  },
  async addToExercises({dispatch}, requestParams) {
    const token = localStorage.getItem('token');
    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'training/create', ...requestParams },
    }).catch((e) => {
      console.log(e);
    });

    if(resp && resp.data) {
      dispatch("getExercisesList", {heroid: requestParams.heroid} )
    }
  },
  async removeFromExercises({dispatch}, requestParams) {
    const token = localStorage.getItem('token');
    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'training/delete', ...requestParams },
    }).catch((e) => {
      console.log(e);
    });

    if(resp && resp.data) {
      dispatch("getExercisesList", {heroid: requestParams.heroid} )
    }
  },
  async getExercisesList({commit}, requestParams) {
    const token = localStorage.getItem('token');
    const resp = await axios({
      headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
      method: 'post',
      url: `${process.env.VUE_APP_DEV_API}/api/`,
      data: { mtd: 'training/getList', ...requestParams },
    }).catch((e) => {
      console.log(e);
    });

    if(resp && resp.data) {
      commit('SET_DATA_BY_KEY', { key: 'exercisesList', value: resp.data });
    }
  }
};
