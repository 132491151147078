import { createApp } from "vue/dist/vue.esm-bundler.js";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from '@/plugins/router'
import store from "@/store";

// import messages from "@/i18n/index.js";
import {i18n} from "@/i18n/index.js"

import PortalVue from "portal-vue";

import VueYandexMetrika from 'vue3-yandex-metrika' 
let worker

try {
  worker =  new Worker("stockfish/stockfish.js");
  worker?.postMessage("uci").catch(e => {});
  worker?.postMessage("ucinewgame").catch(e => {});
  worker?.postMessage("setoption name Use NNUE value true").catch(e => console.log(e));
  worker?.postMessage("setoption name Hash value 64").catch(e => console.log(e));

  worker?.postMessage("setoption name MultiPV value 1").catch(e => console.log(e));

  worker?.postMessage("setoption name Threads value 2").catch(e => console.log(e));
  worker?.postMessage("setoption name UCI_AnalyseMode value true").catch(e => console.log(e));

} catch {
  console.log('no worker allowed')
}


// worker.onmessage = function(event) {
//   console.log(event.data)
// }

import 'vue-chessground/chessboard.css';

// options = new Map([["Threads", "1"], ["Hash", "16"], ["MultiPV", "1"], ["UCI_Variant", "chess"]]),
// this.setOption("UCI_AnalyseMode", "true"),
// this.setOption("Analysis Contempt", "Off"),
// this.setOption("UCI_Chess960", "true"),

// t.emit({
//   fen: t.currentFen,
//   maxDepth: r ? 99 : this.opts.defaultDepth,
//   depth: ((l = s.pvs[0]) === null || l === void 0 ? void 0 : l.depth) || 0,
//   knps: s.nodes / Math.max(s.time, 1),
//   nodes: s.nodes,
//   cp: (c = s.pvs[0]) === null || c === void 0 ? void 0 : c.cp,
//   mate: (d = s.pvs[0]) === null || d === void 0 ? void 0 : d.mate,
//   millis: s.time,
//   pvs: s.pvs
// })

loadFonts();








const deepFind = ({ array, value, key = 'id' }) => {
  if (!array) return null;
  const fields = Object.keys(array);
  if (fields.includes(key) && array[key] === value) {
    return array;
  } else {
    let result;
    for (const field of fields) {
      if (typeof array[field] === 'object' && field !== 'parentObject') {
        if (Array.isArray(array[field])) {
          for (const item of array[field]) {
            if (typeof item === 'object') {
              if (
                (result = deepFind({
                  array: item,
                  value,
                  key,
                })) !== null
              )
                return result;
            }
          }
        } else {
          if (
            (result = deepFind({
              array: array[field],
              value,
              key,
            })) !== null
          )
            return result;
        }
      }
    }
  }
  return null;
}

const vueApp = createApp(App);
vueApp.use(PortalVue);
vueApp.mixin({
  methods: {
    $cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    $parseArray(str) {
      if (!str) return [];
      if(Array.isArray(str)) return str
      const replaced = str.replace("{", "[").replace("}", "]");

      return JSON.parse(replaced);
    },
    $getMoveQualityFill(moveCount, deviation = 30,waste) {
  
      if(!moveCount) return 
      const redPercent =  waste > deviation ? moveCount : 0
 
      return 100 * (redPercent/moveCount)
    },
    $getFill(obj, extended = true,) {
 
      if (!obj || !obj.array) return [];
      const colorsArr = ["#efeded", "#c1b6b8", "#a99b9d"];
      // const colorsArr = ["#34aadc", "#c1b6b8", "#a99b9d"];


      const percentil = obj.array.map((num) => (Number(num / obj.totalCount* 100)));
      // if(Number.isNaN(percentil[0])) {
      //   console.log(JSON.parse(JSON.stringify(obj.array)), obj.totalCount)
      // }
      

      if(extended) {
        const reducedObj = percentil.reduce((acc, item, index) => {
          if (!item) return acc;
          const actualPercent = index === 0 ? 0 : item + acc.accumVal
          acc.array.push({ number: actualPercent, color: colorsArr[index] });

          if (index < percentil.length-1) {
            const innerValue = index === percentil.length - 1 ? 100 : (acc.accumVal + item)
            acc.array.push({ number: innerValue, color: colorsArr[index] });
            acc.array.push({ number: innerValue, color: colorsArr[index + 1] });

          }
          acc.accumVal += item
          return acc;

        }, {array: [], accumVal: 0});

        return reducedObj.array
      } else {
       
        return percentil.reduce((acc, item, index) => {
          if (!item) return acc;
          acc.push({ number: item, color: colorsArr[index] });
          if (extended && index < percentil.length - 1) {
            acc.push({ number: item, color: colorsArr[index + 1] });
          }
          return acc;
        }, []);
      }
      
    },
   
  },
  computed: {
    $stockfish() {
      return worker;
    },
    $deepFind() {
      return deepFind
    },
    $getMoveText() {
      const { lang_pieces } = this.$store?.state?.ui?.userPreferences;
      
      return (item) => {
        if(lang_pieces === undefined) {
          return ''
        }
        const move_lang = item?.move_lang || '';
        if (!move_lang) {
          return 'Root';
        }
        if (move_lang && move_lang[lang_pieces]) {
          return move_lang[lang_pieces];
        }
        return  move_lang[0] || '';
      }; 
    }
  },
});

vueApp.use(vuetify).use(store).use(router).use(i18n).use(VueYandexMetrika, {
	id: 94166251,
	router: router,
	env: process.env.NODE_ENV
    // other options
}).mount("#app");
