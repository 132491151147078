<template>
  <v-card width="32vw">

    <v-card-title>{{$t('compare.title')}}</v-card-title>
    <v-card-text v-if="localModel">
      <div style="width: 30vw">
        <v-select
          :label="$t('playground.site')"
          density="compact"
          :items="siteItems"
          v-model="siteModel"
          item-title="text"
          item-value="id"
          chips
        />
      </div>
      <div style="width: 30vw">
        <v-select
          :label="$t('compare.aveloRange')"
          density="compact"
          :items="rangeItems"
          v-model="rangeModel"
          multiple
          item-title="text"
          item-value="id"
          chips
          :disabled="localModel && localModel.compare_pgid === 1"
        />
      </div>
      <div style="width: 30vw">
        <v-select
          :label="$t('compare.timeControl')"
          density="compact"
          :items="timeControlItems"
          v-model="timeControlModel"
          multiple
          item-title="text"
          item-value="id"
          chips
          :disabled="localModel && localModel.compare_pgid === 1"
        >
          <template v-slot:selection="{ item }">
            <v-chip v-if="!(localModel.compare_pgid === 3 && item.id === 2)">
              <span>{{ item.text }}</span>
            </v-chip>
          </template>
        </v-select>
      </div>
      <div style="width: 30vw">
        <v-select
          :label="$t('compare.rivals')"
          density="compact"
          :items="rivalsItems"
          v-model="rivalsModel"
          item-title="text"
          item-value="id"
          chips
          :disabled="localModel && localModel.compare_pgid === 1"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn size="small" @click="$emit('close')">{{$t('common.cancel')}}</v-btn>
      <v-btn size="small" @click="reset">{{$t('common.reset')}}</v-btn>
      <v-btn size="small" @click="handleSave">{{$t('common.ok')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapState, mapActions } from "vuex";
import fixPrefs from '@/helpers/fixPrefs'

const defaultCorresp = {
  compare_elo: [2000],
  compare_tc: [1],
  compare_ed: [1],
};

const defaultFide = {
  compare_elo: [2900],
  compare_tc: [2],
  compare_ed: [2,3,4,5,6],
};

const defaultLichess = {
  compare_elo: [2100, 2500, 2900],
  compare_tc: [4],
  compare_ed: [2,3,4,5,6],
};

export default {
  emits: ["close"],
  data: () => ({
    localModel: null,
  }),
  computed: {
    ...mapState("data", ["compareFilters"]),
    ...mapState("ui", ["userPreferences"]),
	
	siteItems() { return [
      {
        id: 1,
        text: this.$t('playground.pg1'),
      },
      {
        id: 2,
        text: this.$t('playground.pg2'),
      },
      {
        id: 3,
        text: this.$t('playground.pg3'),
      },
    ] },
	
	rivalsItems () { return [
      {
        id: "4",
        text: this.$t('compare.ed4'),
      },
      {
        id: "3,4,5",
        text: this.$t('compare.ed345'),
      },
      {
        id: "2,3,4,5,6",
        text: this.$t('compare.ed23456'),
      },
    ] },

    siteModel: {
      get() {
        return this.localModel?.compare_pgid;
      },
      set(val) {
        

        // if (clone.pgid === 3 && val === 3) {
        //   if (
        //     clone.categories.indexOf(1) > -1 &&
        //     clone.categories.indexOf(2) === -1
        //   ) {
        //     clone.categories.push(3);
        //   } else {
        //     if (
        //       clone.categories.indexOf(2) > -1 &&
        //       clone.categories.indexOf(1) === -1
        //     ) {
        //       clone.categories.splice(clone.categories.indexOf(3), 1);
        //     }
        //   }
        // }

        const mapper = {
          1: defaultCorresp,
          2: defaultFide,
          3: defaultLichess,
        };
        const clone = cloneDeep(mapper[val]);
        clone.compare_pgid = val;

        this.localModel = clone;
      },
    },
    rangeItems() {
      const pgid = this.localModel?.compare_pgid;
      if (!pgid) return [];
      if (pgid === 1) return [];

      return [
        {
          id: 2100,
          text: pgid === 2 ? "1800-2199" : "2000-2399",
        },
        {
          id: 2500,
          text: pgid === 2 ? "2200-2599" : "2400-2799",
        },
        {
          id: 2900,
          text: pgid === 2 ? "2600+" : "2800+",
        },
      ];
    },
    rangeModel: {
      get() {

        const compare_elo = this.localModel.compare_elo
        if(typeof compare_elo === 'string') return this.$parseArray(compare_elo)
        return this.localModel.compare_elo;
      },
      set(val) {
        const clone = cloneDeep(this.localModel);
        clone.compare_elo = val;
        this.localModel = clone;
      },
    },
    timeControlItems() {
      const pgid = this.localModel?.compare_pgid;
      if (!pgid) return [];
      if (pgid === 1)
        return [
          {
            id: 1,
            text: "-",
          },
        ];
      if (pgid === 2) {
        return [
          {
            id: 2,
            text: this.$t('compare.tc2_2'),
          },
          {
            id: 3,
            text: this.$t('compare.tc2_3'),
          },
          {
            id: 4,
            text: this.$t('compare.tc2_4'),
          },
        ];
      }

      return [
        {
          id: 3,
          text: this.$t('compare.tc2_3'),
        },
        {
          id: 4,
          text: this.$t('compare.tc2_4'),
        },
      ];
    },
    timeControlModel: {
      get() {
        
        const compare_tc = this.localModel.compare_tc
        if(typeof compare_tc === 'string') return this.$parseArray(compare_tc)
        return this.localModel.compare_tc;
      },
      set(val) {

        const clone = cloneDeep(this.localModel);

        // if (pgid === 2) {
        //   if (val.indexOf(1) > -1 && val.indexOf(2) === -1) {
        //     val.push(2);
        //   } else {
        //     if (val.indexOf(2) > -1 && val.indexOf(1) === -1) {
        //       val.splice(val.indexOf(2), 1);
        //     }
        //   }
        // }
        clone.compare_tc = val;
        this.localModel = clone;
      },
    },
    rivalsModel: {
      get() {
        const compare_ed = this.localModel.compare_ed
        //  if(compare_ed?.indexOf("{") > -1) return this.$parseArray(compare_ed).join(',')
        return this.rivalsItems.find(i => i.id === compare_ed.join(','))
        // return this.localModel.compare_ed;
      },
      set(val) {
        const clone = cloneDeep(this.localModel);

        clone.compare_ed = val.split(',');
        this.localModel = clone;
      },
    },
  },
  methods: {
    ...mapActions("data", ["updateCompareFilters"]),
     ...mapActions('ui', ['setUserPreferences']),
    reset() {
      this.localModel = cloneDeep(this.compareFilters);
    },
    handleSave() {
      // localStorage.setItem("compareFilters", JSON.stringify(this.localModel));
      this.updateCompareFilters({
        filters: this.localModel,
        heroid: this.$route.params.heroid,
      });

      this.setUserPreferences(cloneDeep({...this.userPreferences, ...fixPrefs(this.localModel)}))
      this.$emit("close");
    },
  },

  watch: {
    userPreferences: {
      deep: true,
      immediate: true,
      handler: function(val) { 
        if(val) {
          this.localModel = cloneDeep(val);
        }
      }
    },
    siteModel: {
      handler: function (val, oldVal) {
        if (!this.localModel) return;
        if (!oldVal) return;

        // if ((val === 2 && oldVal === 3) || (val === 3 && oldVal === 2)) {
        //   const filtered = this.timeControlModel.filter((i) => i !== 2);
        //   this.timeControlModel = filtered;
        // }
      },
    },
  },
};
</script>

<style scoped></style>
