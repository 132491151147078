<template>
  <div>
    <div class="d-flex mt-1">
      <div style="flex: 1">
        {{ $t('opening1.side') }}
        <v-radio-group v-model="sideModel" density="compact">
          <v-radio
            :value="option.id"
            v-for="option in sideOptions"
            :key="'so' + option.id"
          >
            <template v-slot:label>
              <div class="text-caption">{{ option.text }}</div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div style="flex: 1">
        {{ $t('opening1.studied') }}
        <v-radio-group v-model="studiedModel" density="compact">
          <v-radio
            :value="option.id"
            v-for="option in studiedOptions"
            :key="'st' + option.id"
          >
            <template v-slot:label>
              <div class="text-caption">{{ option.text }}</div>
            </template></v-radio
          >
        </v-radio-group>
      </div>
      <div style="flex: 1">
        {{ $t('opening1.watchlist') }}
        <v-radio-group v-model="watchlistModel" density="compact">
          <v-radio
            :value="option.id"
            v-for="option in watchListOptions"
            :key="'wl' + option.id"
          >
            <template v-slot:label>
              <div class="text-caption">{{ option.text }}</div>
            </template></v-radio
          >
        </v-radio-group>
      </div>
    </div>
    <v-divider class="my-1" />
    <div class="pa-2" style="height: 118px">
      <div
        class="d-flex align-center mb-2"
        style="gap: 1em"
        v-if="getMistakesMinMax && getMistakesMinMax.length > 0"
      >
        <div style="flex: 2">
          <v-text-field
            density="compact"
            variant="outlined"
            hide-details
            :label="$t('opening1.moveNumFrom')"
            type="number"
            v-model="minModel"
          />
        </div>
        <div style="flex: 8">
          <v-range-slider
            v-model="hmRangeModel"
            :step="1"
            :min="getMistakesMinMax[0]"
            :max="getMistakesMinMax[1]"
          />
        </div>
        <div style="flex: 2">
          <v-text-field
            density="compact"
            variant="outlined"
            hide-details
            :label="$t('opening1.moveNumTo')"
            type="number"
            v-model="maxModel"
          />
        </div>
      </div>
      <v-autocomplete
        v-if="extendedOpeningsVariants && extendedOpeningsVariants.length > 1"
        variant="outlined"
        :label="$t('opening1.opening')"
        density="compact"
        v-model="openingModel"
        :items="extendedOpeningsVariants"
        item-title="text"
        item-value="id"
        hide-details
      />
    </div>
    <v-divider class="mb-1" />
    <v-radio-group v-model="sortByModel" density="compact">
      <v-radio
        :value="option.id"
        v-for="option in sortByOptions"
        :key="'sb' + option.id"
      >
        <template v-slot:label>
          <div class="text-caption">{{ option.text }}</div>
        </template></v-radio
      >
    </v-radio-group>
    <v-divider />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  data: () => ({}),
  computed: {
    ...mapGetters('data', ['getOpeningsVariants', 'getMistakesMinMax']),
    ...mapState('data', [
      'sideOptionsFilter',
      'studiedOptionsFilter',
      'watchListOptionsFilter',
      'openingOptionsFilter',
      'sortByOptionsFilter',
      'hmRange',
    ]),
    extendedOpeningsVariants() {
        return  this.getOpeningsVariants(this.$t) 
    },
    sortByOptions() {
      return [
        {
          id: 1,
          text: this.$t('opening1.sortByTotalShortage'),
        },
        {
          id: 2,
          text: this.$t('opening1.sortByAverageShortage'),
        },
        {
          id: 3,
          text: this.$t('opening1.sortByNumMistakes'),
        },
        {
          id: 4,
          text: this.$t('opening1.sortByMoveDesc'),
        },
        {
          id: 5,
          text: this.$t('opening1.sortByMoveAsc'),
        },
      ];
    },

    sideOptions() {
      return [
        {
          id: 1,
          text: this.$t('common.allCapital'),
        },
        {
          id: 2,
          text: this.$t('opening1.sideWhite'),
        },
        {
          id: 3,
          text: this.$t('opening1.sideBlack'),
        },
      ];
    },
    studiedOptions() {
      return [
        {
          id: 1,
          text: this.$t('common.allCapital'),
        },
        {
          id: 2,
          text: this.$t('opening1.studiedYes'),
        },
        {
          id: 3,
          text: this.$t('opening1.studiedNo'),
        },
      ];
    },
    watchListOptions() {
      return [
        {
          id: 1,
          text: this.$t('common.allCapital'),
        },
        {
          id: 2,
          text: this.$t('opening1.watchlistListed'),
        },
      ];
    },

    minModel: {
      get() {
        const hmRangeModel = this.hmRangeModel;
        return hmRangeModel[0] || 0;
      },
      set(val) {
        const hmRangeModel = this.hmRangeModel;

        debounce(function () {
          this.SET_DATA_BY_KEY({
            key: 'hmRange',
            value: [Number(val) || 0, hmRangeModel[1]],
          });
        }, 500);
      },
    },
    maxModel: {
      get() {
        const hmRangeModel = this.hmRangeModel;
        return hmRangeModel[1] || 0;
      },
      set(val) {
        const hmRangeModel = this.hmRangeModel;

        debounce(function () {
          this.SET_DATA_BY_KEY({
            key: 'hmRange',
            value: [hmRangeModel[0], Number(val) || 0],
          });
        }, 500);
      },
    },
    hmRangeModel: {
      get() {
        if (this.hmRange === 0) {
          return this.getMistakesMinMax;
        }
        return this.hmRange;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'hmRange', value: val });
      },
    },
    sideModel: {
      get() {
        return this.sideOptionsFilter;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'sideOptionsFilter', value: val });
      },
    },
    studiedModel: {
      get() {
        return this.studiedOptionsFilter;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'studiedOptionsFilter', value: val });
      },
    },
    watchlistModel: {
      get() {
        return this.watchListOptionsFilter;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'watchListOptionsFilter', value: val });
      },
    },
    openingModel: {
      get() {
        return this.openingOptionsFilter;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'openingOptionsFilter', value: val });
      },
    },
    sortByModel: {
      get() {
        return this.sortByOptionsFilter;
      },
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'sortByOptionsFilter', value: val });
      },
    },
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
  },
  watch: {
    getMistakesMinMax: {
      deep: true,
      handler: function (val) {
        this.SET_DATA_BY_KEY({ key: 'hmRange', value: val });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
