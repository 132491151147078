import Vuex from "vuex";
import axios from '@/plugins/axiosWrapper'
import uiModule from "./ui";
import dataModule from "./data";

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    token: "",
    sizes: {vh: 0, vw: 0}
  },
  mutations: {
    "SET_DATA_BY_KEY": (state, {key, value, }) => state[key] = value 
  },
  actions: {
    authUser: async ({ commit}, {email, password, remember, callBack}) => {
     
      const resp = await axios({
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {email, password, "mtd": "signin", remember: remember ? 1 : 0},


        headers: { "Content-Type": "application/json; charset=utf-8",'x-forwarded-for': localStorage.getItem('ip-from') },

      }).catch((e) => console.log(e))


      if(resp && resp.data?.token) {
        localStorage.setItem('token', resp.data.token)
         await commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: true})
        callBack()
      } else {
        return resp
      }
   
    },
    logOutUser: async ({ commit }, callBack) => { 
      const token = localStorage.getItem("token"); 
      await axios({
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: { "mtd": "signout"},
 
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },

      }).catch((e) => console.log(e))
      localStorage.removeItem('token')
      

      await commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: false})
      await commit("ui/RESET")
      await commit("data/RESET")


      callBack()
    }
  },
  modules: {
    ui: uiModule,
    data: dataModule,
  },
  getters: {},
});
