<template>
    <g  style="cursor:pointer">
        <circle fill="white" stroke="#3f4141" :cx="left - borderRadius" :cy="top" :r="borderRadius" />
        <g v-if="!status">
            <path stroke="#3f4141" stroke-width="2" :d="`M${left - (borderRadius) },${top-(borderRadius/2)} v${borderRadius}z`"/>
            <path stroke="#3f4141" stroke-width="2" :d="`M${left - (borderRadius + (borderRadius/2)) },${top} h${borderRadius}z`"/>

        </g>
        <g v-else>
           <path stroke="#3f4141" stroke-width="2" :d="`M${left - (borderRadius + (borderRadius/2)) },${top} h${borderRadius}z`"/>
        </g>
    </g>
</template>

<script>
    export default {

        props: ["top", "left", "status", "borderRadius"]
    }
</script>

<style scoped>

</style>