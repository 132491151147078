<template>
  <div
    class="d-flex flex-row justify-end" 
    :style="{
      'background-image': 'url(' + require('../assets/backGroundPicture.png') + ')',
      'background-color': '#000000',
      'background-position': 'bottom left',
      'background-size': '60%'
    }"
  >
    
   <!-- <div> -->
    <!-- <div style="width: 66vw" class="pa-2 about-info">
      <h2>О компании</h2>
      <p>
        Мы, компания "Интеллектуальные Системы", представляем уникальное видение
        в области шахматной аналитики. Наша цель – объединить шахматный мир с
        передовыми технологиями, создав уникальные решения как для
        профессиональных игроков, так и для любителей шахмат. Мы
        специализируемся на разработке программных комплексов для анализа
        больших данных.
      </p>
      <h2>О проекте</h2>
      <p>
        Мы разделяем страсть к шахматам и стремимся изменить традиционный подход
        к системе подготовки, используя передовые технологии. В настоящее время
        нами ведётся активная работа над онлайн-сервисом по анализу шахматных
        партий. Мы ожидаем, что использование нашего сервиса будет
        способствовать ускорению процесса обучения шахматистов на уровне от 4
        разряда до мастера. Наша работа открывает новые горизонты для
        шахматистов, позволяя им понимать игру на более глубоком уровне,
        принимать более качественные решения и совершенствовать свои навыки.
      </p>
      <v-divider class="my-2"></v-divider>
      <p>Наш стек технологий включает в себя:</p>
      <ul class="ml-6">
        <li>
          языки программирования: Python, Go и Rust для создания
          высокопроизводительных и масштабируемых решений;
        </li>
        <li>
          базы данных: для хранения и быстрого доступа к большим данным мы
          применяем СУБД PostgreSQL, MongoDB и Redis;
        </li>

        <li>
          веб-технологии: фронтенд и бэкенд технологии React, Vue.js, Node.js и
          другие;
        </li>

        <li>
          облачные решения: Amazon Web Services (AWS), VK Cloud или Google Cloud
          Platform.
        </li>
      </ul>
      <p>
        Если вы заинтересованы в сотрудничестве, будем рады обсудить детали
        вашего проекта, для этого пишите на указанный ниже адрес электронной
        почты.
      </p>
      <v-divider class="my-2"></v-divider>
      <div class="h2">
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИНТЕЛЛЕКТУАЛЬНЫЕ СИСТЕМЫ"
      </div>
      <div class="my-4">Разработка аналитического программного обеспечения</div>
      <div class="my-4">ИНН: 2801277294</div>
      <div class="my-4">КПП: 280101001</div>
      <div>
        Электронная почта:
        <a href="mailto:solutions.chess@gmail.com">
          solutions.chess@gmail.com
        </a>
      </div>
      <div>
        Контактный телефон: +7(914)615-56-62
      </div>
      <div class="my-4">
        Адрес: Амурская область, г.Благовещенск уг.Горького 174 офис 407
      </div>
      <v-img class="my-2" src="/images/map.JPG" width="50vw"> </v-img>
    </div> -->
      <!-- </div> -->
      <div class="d-flex justify-center pa-4" style="width: 570px; ">  <!-- height: 100vh -->
      <v-card style="width: 100%; height: 100%">
        <div class="pt-12">
        <LogoSvg class="logo-svg"/>
        </div>
        <div class="pt-8 px-15"> 
            <div v-if="currentMode === 'login'">
            <LoginComponent @switch-to-register="switchModeToRegister" @switch-to-recovery="switchModeToRecovery"/>  
            </div>
            <div v-if="currentMode === 'register'">
            <RegisterComponent @switch-to-login="switchModeToLogin" /> 
            </div>
            <div v-if="currentMode === 'recovery'">
            <RecoveryComponent @switch-to-login="switchModeToLogin"/>
            </div>
        </div>   
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import LoginComponent from '../components/LoginPage/LoginComponent.vue';
import RegisterComponent from '../components/LoginPage/RegisterComponent.vue';
import RecoveryComponent from '../components/LoginPage/RecoveryComponent.vue';
import LogoSvg from '../assets/LogoSvg.vue';   

export default {
  components: {
    LoginComponent,
    RegisterComponent,
    RecoveryComponent,
    LogoSvg
  },
  setup() {
    const currentMode = ref('login');

    const switchModeToRegister = () => {
      currentMode.value = 'register';
    };

    const switchModeToLogin = () => {
      currentMode.value = 'login';
    };

    const switchModeToRecovery = () => {
      currentMode.value = 'recovery';
    };
 
    return {
      currentMode,
      switchModeToRegister,
      switchModeToLogin,
      switchModeToRecovery, 
    };
  }
};
</script>

<style scoped>
.logo-svg {
  display: block;
  margin: 0 auto;
}

.about-info p {
  margin: 8px 0px
}
</style>
