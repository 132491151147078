import axios from 'axios';
import router from '@/plugins/router';
// import requestOrchestrator from './requestOrchestrator';


const api = axios.create();
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //тут обработка для ческома
    if (error?.config?.url?.indexOf('api.chess.com') > -1) {
      if (error?.response?.status === 400) {
        return { errorMessage: error?.response?.data?.message };
      }
      if (error?.code === 'ERR_NETWORK') {
        return { errorMessage: 'User not found' };
      }
    }

    if (error?.config?.url?.indexOf('lichess.org') > -1) {
      if (error?.response?.status === 404) {
        return { errorMessage: 'User not found' };
      }
    }

    if (
      error?.response?.data?.message === 'Failed to get userid by token' ||
      error?.response?.status === 401
    ) {
      router.push('/login');
    }

    // whatever you want to do with the error
    return { errorMessage: error?.response?.data?.message || 'Some problem' };
  },
);



export default api;
