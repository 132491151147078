<template>
  <v-card>
    <v-card-title>{{ $t('accountMenu.preferences') }}</v-card-title>
    <v-card-text>
      <div v-if="localModel">
        <div style="width: 300px">
          <v-select
            v-model="localModel.lang_pieces"
            :items="moveOptions"
            item-title="title"
            :rules="[required]"
            :label="$t('preferences.piecesDisplayedAs')"
            variant="outlined"
            item-value="id"
          />
        </div>

        <div style="width: 300px">
          <v-switch
            v-model="flip_board_black_model"
            :rules="[]"
            :label="$t('preferences.flipWhenBlack')"
            variant="outlined"
          />
        </div>
        <div style="width: 300px">
          <v-text-field
            type="number"
            v-model="localModel.showmove_deviation"
            :label="$t('preferences.goodMovesBound')"
            variant="outlined"
          />
        </div>
        <div style="width: 300px">
          <v-text-field
            type="number"
            v-model="localModel.mistakes_deviation"
            :label="$t('preferences.mistakesSensivity')"
            variant="outlined"
          />
        </div>
      </div>
    </v-card-text>
    <v-card-text>
      <div>------ dev -----</div>
      <v-text-field
        type="number"
        v-model="depthfull"
        label="depthfull"
        variant="outlined"
      />
      <v-text-field
        type="number"
        v-model="depthfilter"
        label="depthfilter"
        variant="outlined"
      />
    </v-card-text>
    <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
      <v-btn @click="$emit('close')">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="blue" :disabled="!localModel" @click="handleSave">{{
        $t('common.ok')
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/i18n';
export default {
  emits: ['close'],
  data: () => ({
    localModel: null,
  }),
  computed: {
    flip_board_black_model: {
      get() {
        return this.localModel.flip_board_black === 1;
      },
      set(val) {
        this.localModel.flip_board_black = val ? 1 : 0;
      },
    },
    use_short_notation_model: {
      get() {
        return this.localModel.use_short_notation === 1;
      },
      set(val) {
        this.localModel.use_short_notation = val ? 1 : 0;
      },
    },
    moveOptions() {
      // const locale = this.$i18n.locale - has to commit actual lang_interface
      // const lang_interface = this.localModel.lang_interface

      const allOptions = [
        {
          id: 0,
          title: '♘♗♖♕♔♞♝♜♛♚',
        },
        {
          id: 1,
          title: 'N B R Q K',
        },

        {
          id: 2,
          title: 'S L T D K',
        },
        {
          id: 3,
          title: 'C F T D R',
        },

        {
          id: 4,
          title: 'К С Л Ф Кр',
        },
        {
          id: 5,
          title: '马象车后王',
        },

        {
          id: 6,
          title: 'न ब र क्यू क',
        },
        {
          id: 7,
          title: 'C A T D R',
        },
        {
          id: 8,
          title: 'C B T D R',
        },
        {
          id: 9,
          title: 'К С Т Ф Кр',
        },
      ];

      return allOptions;
      //  const locale = i18n?.global?.locale

      //   const mainOptions = [
      //     {
      //       id: 0,
      //       title: '♘♗♖♕♔♞♝♜♛♚',
      //     },
      //     {
      //       id: 1,
      //       title: 'N B R Q K',
      //     },
      //   ];

      //   if(locale === 'en') {
      //     return mainOptions
      //   }
      //   const appendix = [
      //     {
      //       id: 2, //if this number saves to preferences directly, then it has to set lang_interface, but not "2"
      //       title: this.$t('preferences.langPiecesNational'),
      //     },
      //   ];

      //   return [...mainOptions, ...appendix];
    },
    depthfull: {
      get() {
        return localStorage.getItem('depthfull') || 3;
      },
      set(val) {
        localStorage.setItem('depthfull', val);
      },
    },
    depthfilter: {
      get() {
        return localStorage.getItem('depthfilter') || 11;
      },
      set(val) {
        localStorage.setItem('depthfilter', val);
      },
    },
  },
  methods: {
    ...mapActions('ui', ['setUserPreferences']),
    handleSave() {
      console.log('here');
      this.setUserPreferences(this.localModel);
      this.$emit('close');
    },
  },
  mounted() {
    this.localModel = cloneDeep(this.$store.state.ui.userPreferences);
  },
};
</script>

<style scoped></style>
