<template>
  <div style="position: relative; width: 100%">
    <!-- <v-app-bar color="grey" prominent> </v-app-bar> -->
    <!-- <portal to="app-bar-left-portal"> 
      
       </portal> -->

    <portal to="app-bar-portal">
      <div class="d-flex align-center" style="height: 60px;">
        <div style="width: 30%">
          <v-select
            density="compact"
            variant="outlined"
            item-title="title"
            item-value="id"
            hide-details
            :label="$t('opening1.barMode')"
            :items="treeModes"
            v-model="treeModel"
            class="text-caption ml-2"
            style="transform: scale(0.7)"
          />
        </div>
        <div style="width: 10%">
          <!-- <v-select
            density="compact"
            variant="outlined"
            item-title="title"
            item-value="id"
            hide-details
            :label="$t('opening1.displayMoves')"
            :items="movesOptions"
            v-model="movesModel"
            class="text-caption"
          /> -->

          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" size="small" class="text-caption">
                {{ $t('opening1.displayMoves') }}
              </v-btn>
            </template>
            <v-card class="pa-4">
              <div class="my-2">
                <v-select
                  density="compact"
                  variant="outlined"
                  item-title="title"
                  item-value="id"
                  hide-details
                  :label="$t('opening1.cTO')"
                  :items="TOOptions"
                  v-model="TOMode"
                  class="text-caption"
                />
              </div>
              <div class="my-2">
                <v-select
                  density="compact"
                  variant="outlined"
                  item-title="title"
                  item-value="id"
                  hide-details
                  :label="$t('opening1.cPractice')"
                  :items="practiceOptions"
                  v-model="practiceMode"
                  class="text-caption"
                />
              </div>
              <div class="my-2">
                <v-select
                  density="compact"
                  variant="outlined"
                  item-title="title"
                  item-value="id"
                  hide-details
                  :label="$t('opening1.cEmphasis')"
                  :items="emphasisOptions"
                  v-model="emphasisMode"
                  class="text-caption"
                />
              </div>
            </v-card>
          </v-menu>
        </div>
        <div style="width: 20%" class="mx-2">
          <v-select
            density="compact"
            variant="outlined"
            item-title="title"
            item-value="id"
            hide-details
            :label="
              getListboxName === 'lbHero'
                ? $t(`moveMarking.lbHero`)
                : $t(`moveMarking.lbOpp`)
            "
            :items="getListboxItems"
            v-model="listBoxModel"
            class="text-caption"
            style="transform: scale(0.7)"
          />

          <!-- <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn class="bg-primary" v-bind="props" :disabled="getListboxDisabled"> {{getListboxName}} </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in getListboxItems"
                :key="item.title"
                :value="index"
                @click="item.onClick"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </div>

        <div
          @click="compareFiltersDialog = true"
          style="width: 40%"
          class="text-center text-caption"
        >
          {{
            $t('opening1.compareWithBeforeHeroname') +
            `${getHeroName}` +
            $t('opening1.compareWithAfterHeroname') +
            `${getCompareString}`
          }}
        </div>
      </div>
      <div style="width: 200px">
        <div v-if="selectedMistake" class="d-flex align-center">
          <div class="mx-2 pointer" @click="handleEyeClick(selectedMistake)">
            <v-icon :color="getEyeColor(selectedMistake.labels)">
              {{ getEyeIcon(selectedMistake.labels) }}
            </v-icon>
          </div>
          <div
            class="mx-2 pointer"
            v-if="selectedMistake"
            :disabled="markStudiedDisabled"
            @click="markStudied(selectedMistake)"
          >
            <v-icon color="white">mdi-check</v-icon>
            <!-- <v-btn >{{
          $t('opening1.setStudied')
        }}</v-btn> -->
          </div>
          <div class="mx-2 pointer" v-if="selectedMistake" @click="addToTest()">
            <v-icon color="white">mdi-alarm-plus</v-icon>
            <!-- <v-btn >{{
          $t('opening1.setStudied')
        }}</v-btn> -->
          </div>
          <div
            class="mx-2 pointer"
            v-if="selectedMistake"
            @click="removeFromTest()"
          >
            <v-icon color="white">mdi-alarm-off</v-icon>
            <!-- <v-btn >{{
          $t('opening1.setStudied')
        }}</v-btn> -->
          </div>

          <div
            class="mx-2 pointer"
            v-if="selectedMistake"
            @click="gotToExsercise()"
          >
            <v-icon color="white">mdi-arrow-right</v-icon>
          </div>

          <div
            class="mx-2 pointer"
            v-if="selectedOpening && selectedOpening.pos_to_id"
            @click="handleToogleBookmark(selectedOpening)"
          >
            <v-icon color="white">
              {{
                getAddedToBookmarks(selectedOpening)
                  ? 'mdi-bookmark-minus'
                  : 'mdi-bookmark-plus'
              }}
            </v-icon>
          </div>
        </div>
      </div>
    </portal>

    <v-dialog v-model="compareFiltersDialog" persistent width="30vw">
      <compare-filters @close="compareFiltersDialog = false" />
    </v-dialog>
    <v-dialog v-model="optionsDialogModel" width="420px">
      <analysis-settings
        v-if="dialogType === 'settings'"
        @close="dialogType = null"
      />
      <analysis-preferences
        v-if="dialogType === 'preferences'"
        @close="dialogType = null"
      />
    </v-dialog>

    <v-card
      v-if="tooltip"
      height="200px"
      width="200px"
      :style="`position: absolute; 
      ${tooltip.top ? 'top: ' + tooltip.top + 'px;' : ''}
      ${
        tooltip.left
          ? 'left: ' +
            (showLeftMenu ? 500 + tooltip.left : tooltip.left) +
            'px;'
          : ''
      }
      ${tooltip.bottom ? 'bottom: ' + tooltip.bottom + 'px;' : ''}
      ${tooltip.right ? 'right: ' + tooltip.right + 'px;' : ''} z-index: 5000`"
    >
      <advanced-board
        :size="`100%`"
        :config="{
          viewOnly: true,
          fen: tooltip && tooltip.fen,
          lastMove: getLastMoveArray(tooltip),
          orientation: tooltip && tooltip.herowhite == 0 ? 'black' : 'white',
        }"
        :key="'tooltip'"
      />
    </v-card>

    <div
      :style="{
        left: showLeftMenu ? '0px' : '-500px',
        height: '80vh',
        top: '0px',
      }"
      class="left-menu"
    >
      <div
        class="left-menu-toggler d-flex align-center justify-center"
        @click="() => (showLeftMenu = !showLeftMenu)"
      >
        <v-icon size="small">mdi-filter</v-icon>
      </div>
      <div style="width: 500px">
        <h3>{{ $t('opening1.weak') }}</h3>
        <AnalysisFilters />
        <MistakesList
          @mistakeClick="handleMistakeClick"
          :toggleTooltip="toggleTooltip"
        />

        <div style="width: calc(100% - 1em)" class="px-2">
          <div>
            <v-text-field
              v-model="searchModel"
              variant="outlined"
              hide-details
              density="compact"
              clearable
            />
          </div>
          <div class="d-flex align-center py-2">
            <v-btn
              size="small"
              @click="handleSearchDefaultPosition"
              color="blue"
              class="mr-2"
            >
              <v-tooltip :text="$t('opening1.searchStartHint')">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">{{ $t('opening1.searchStart') }}</span>
                </template>
              </v-tooltip>
            </v-btn>

            <v-btn
              size="small"
              :disabled="!searchModel"
              @click="handleSearch"
              color="blue"
            >
              <v-tooltip :text="$t('opening1.searchHint')">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">{{ $t('opening1.search') }}</span>
                </template>
              </v-tooltip>
            </v-btn>
            <v-btn
              size="small"
              @click="handleSearchAfter"
              color="blue"
              class="ml-2"
            >
              <v-tooltip :text="$t('opening1.searchAfterHint')">
                <template v-slot:activator="{ props }">
                  <span v-bind="props">{{ $t('opening1.searchAfter') }}</span>
                </template>
              </v-tooltip>
            </v-btn>
            <!-- reload mistakesopening, but use fen from input -->
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100vw; height: calc(90vh - 40px)" class="d-flex">
      <!-- <div style="position: absolute">
              {{openedNodesArr.map(i => i.nodethis)}}
      </div> -->
      <div style="height: calc(90vh - 40px); flex: 10">
        <!--  -->

        <div
          style="width: 100%; height: calc(100vh - 100px); position: relative"
        >
          <!-- <MiniMap
            v-if="selectedMistake"
            :items="getFlatArray"
            :movesModel="movesModel"
            :selectedOpening="selectedOpening"
            :handleTooltip="toggleTooltip"
            :handleClick="handleSelectOpening"
          /> -->
          <div
            :style="`overflow: auto;
              width: ${showLeftMenu ? '44vw' : 'calc(44vw + 500px)'};
              height: calc(90vh - 17px);
              position: absolute;
              padding-top: 10px;
              left: ${showLeftMenu ? 500 : 0}px`"
            ref="treeContainer"
          >
            <svg-tree
              v-if="openingsTree"
              :items="getFlatArray"
              :getPaddingTop="getPaddingTop"
              :loadChildren="toggleNode"
              :blockHeight="blockHeight"
              :blockWidth="blockWidth"
              :getParentCoords="getParentCoords"
              :setTooltip="toggleTooltip"
              :handleSelectOpening="handleSelectOpening"
              :selectedOpening="selectedOpening"
              :mode="treeModel"
              :movesModel="movesModel"
              :handleArrowPress="handleArrowPress"
              :rootColor="openingsTree.hm % 2 === 0 ? 'black' : 'white'"
              :simple="false"
              :size="900"
              :mistakeFens="mistakeFens"
            />

            <!-- :height="blockHeight * 3" -->
          </div>
          <div
            :style="`margin-left: ${
              showLeftMenu ? 500 : 0
            }px; height: 30px; max-width: ${
              showLeftMenu ? '44vw' : 'calc(44vw + 500px)'
            }; overflow-x: auto; overflow-y:hidden;`"
          >
            <v-chip
              size="small"
              class="mx-1"
              v-for="bookMark in bookMarks"
              :key="bookMark.pos_to_id"
              @click="goToBookmark(bookMark)"
              @mouseenter="
                (e) =>
                  toggleTooltip({
                    top: 40,
                    left: e.clientX - (showLeftMenu ? 500 : 0),
                    fen: bookMark.fen,
                  })
              "
              @mouseleave="toggleTooltip(null)"
              >{{ `${bookMark.movenum} ${$getMoveText(bookMark)}` }}</v-chip
            >
          </div>
        </div>
      </div>

      <div
        style="width: 30vw; height: calc(90vh - 40px); position: relative"
        class="pa-2 pr-7"
      >
        <div style="width: 100%" class="mb-2 d-flex">
          <div style="width: 40px; height: 100%">
            <!-- <v-tooltip text="Этот ход встречался уже три раза (по-умному)">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" class="ma-2" color="error"
                  >mdi-alert</v-icon
                >
              </template>
            </v-tooltip> -->
            <v-btn
              icon="mdi-refresh"
              size="small"
              @click="resetBoard"
              class="mb-2"
            >
            </v-btn>
            <!-- <div class="d-flex align-center" style="gap: 1em">
            <v-btn icon="mdi-arrow-left" size="small" class="mb-2"> </v-btn>
            <v-btn icon="mdi-arrow-right" size="small" class="mb-2"> </v-btn>
        
         </div> -->
          </div>

          <advanced-board
            :size="Math.floor(sizes.vw * 0.18) + 'px'"
            :config="{
              viewOnly: false,
              fen:
                customMovesArr.length > 0
                  ? customMove
                  : stockFishFen || (selectedOpening && selectedOpening.fen),
              orientation: currentSide,
              drawable: {
                brushes: getBrush,
              },
              lastMove: getLastMove,
            }"
            :arrows="getSelectedOpeningArrows"
            :allMoves="[]"
            :currentMoveIndex="undefined"
            :movePieces="true"
            ref="bigBoard"
            @customMove="handleCustomeMove"
          />
        </div>

        <div v-show="moveBoard !== null">
          <v-card
            :style="`position: absolute; left: 0; top: ${
              (moveBoard && moveBoard.top + 'px') || 0
            }; z-index: 100; display: ${moveBoard ? 'block' : 'none'}`"
            width="250"
            height="250"
            class="bg-white d-flex align-center justify-center"
            variant="outlined"
          >
            <advanced-board
              size="200px"
              :config="{
                viewOnly: true,
                fen: customMove || (selectedOpening && selectedOpening.fen),
                orientation: currentSide,
              }"
              :allMoves="stockfishMoves || []"
              :currentMoveIndex="moveBoard && moveBoard.movesArrayIndex"
            />
          </v-card>
        </div>

        <div style="width: 100%" class="d-flex align-center justify-start">
          <div
            style="flex: 1"
            class="d-flex align-center justify-start ml-4 mt-2"
          >
            <v-switch
              class="ml-2"
              density="compact"
              v-model="useStockFish"
              hide-details
              :label="$t('opening1.switchEngine')"
            />
            <v-btn
              icon
              size="small"
              v-if="selectedStockFishArr && selectedStockFishArr.moves"
              @click="resetStockFishArr"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <v-spacer />
        </div>

        <div
          v-if="currentStockFishIndex !== undefined && selectedStockFishArr"
          style="width: 100%"
          class="d-flex align-center flex-wrap text-caption bg-light-grey"
          @mouseleave="toggleTooltip(null)"
        >
          <div
            v-for="(move, i) in selectedStockFishArr.moves"
            :key="i"
            class="pa-1 text-caption"
          >
            <span class="mx-1">{{ move.moveStr }}</span>

            <span
              :class="`px-1 hoverableStockFishItem ${
                currentStockFishIndex === i &&
                currentStockFishFigurineIndex === u
                  ? 'bg-black text--white'
                  : ''
              }`"
              @click="
                currentStockFishIndex = i;
                currentStockFishFigurineIndex = u;
                customMove = moveFugurine.fen;
              "
              @mouseover="
                (e) =>
                  toggleTooltip({
                    fen: moveFugurine.fen,
                    right: 200,
                    top: e.pageY,
                  })
              "
              v-for="(moveFugurine, u) in move.moves"
              :key="moveFugurine.moveIndex + 'figurine'"
              style="cursor: pointer; width: 46px"
            >
              {{ moveFugurine.moveName }}
            </span>

            <!-- {{ getPrettyMoves[itemIndex] &&  getPrettyMoves[itemIndex][i] }} -->
            <!-- <v-chip size="x-small">{{ move }}</v-chip> -->
          </div>
        </div>
        <div style="width: 100%" @mouseleave="toggleTooltip(null)">
          <div
            v-for="(item, itemIndex) in getPrettyMoves"
            :key="item.string"
            :class="
              toggledRow(itemIndex)
                ? 'stockfish-node__toggled'
                : 'stockfish-node'
            "
          >
            <div class="d-flex align-center flex-wrap text-caption">
              <v-chip size="small" class="bg-black">
                {{ item.string }}
              </v-chip>
              <span
                style="cursor: pointer"
                class="text-subtitle-1 px-2"
                @click="toggledRow = itemIndex"
                >{{ toggledRow(itemIndex) ? '↑' : '↓' }}</span
              >

              <div
                v-for="(move, i) in item.moves"
                :key="i"
                class="pa-1 text-caption"
              >
                <span class="mx-1">{{ move.moveStr }}</span>

                <span
                  class="px-1 hoverableStockFishItem"
                  @click="
                    selectedStockFishArr = prepareStockFishArray(item);
                    currentStockFishIndex = i;
                    currentStockFishFigurineIndex = u;
                  "
                  @mouseover="
                    (e) =>
                      toggleTooltip({
                        fen: moveFugurine.fen,
                        right: 200,
                        top: e.pageY,
                      })
                  "
                  v-for="(moveFugurine, u) in move.moves"
                  :key="moveFugurine.moveIndex + 'figurine'"
                  style="cursor: pointer; width: 46px"
                >
                  {{ moveFugurine.moveName }}
                </span>

                <!-- {{ getPrettyMoves[itemIndex] &&  getPrettyMoves[itemIndex][i] }} -->
                <!-- <v-chip size="x-small">{{ move }}</v-chip> -->
              </div>
            </div>
            <v-divider />
          </div>
        </div>

        <analysis-summary
          :selectedOpening="getFullNode"
          v-if="getFullNode"
          :compareString="getCompareString"
          :heroName="getHeroName"
          :handleSelectOpening="handleSelectOpening"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import axios from '@/plugins/axiosWrapper';
import SvgTree from '@/components/SvgTree';
import AnalysisFilters from '@/components/AnalysisFilters';
import MistakesList from '@/components/MistakesList';
import AnalysisSummary from '@/components/AnalysisSummary';
import AnalysisSettings from '@/components/AnalysisSettings.vue';
import AnalysisPreferences from '@/components/AnalysisPreferences.vue';
import AdvancedBoard from '@/components/AdvancedBoard';
import MiniMap from '@/components/MiniMap';
import CompareFilters from '@/components/CompareFilters';
import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import deepObjectSearch from '@/helpers/deepObjectSearch';
import { Chess } from 'chess.js';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

function transform(xy, angle, xy0) {
  // put x and y relative to x0 and y0 so we can rotate around that
  const rel_x = xy[0] - xy0[0];
  const rel_y = xy[1] - xy0[1];

  // compute rotated relative points
  const new_rel_x = Math.cos(angle) * rel_x - Math.sin(angle) * rel_y;
  const new_rel_y = Math.sin(angle) * rel_x + Math.cos(angle) * rel_y;

  return [xy0[0] + new_rel_x, xy0[1] + new_rel_y];
}

const getStockFishObj = (str) => {
  const splitted = str.split(' ');
  const result = {};
  for (let i = 0; i < splitted.length; i++) {
    const item = splitted[i];
    if (item === 'depth') {
      result.depth = splitted[i + 1];
    }
    if (item === 'multipv') {
      result.multipv = splitted[i + 1];
    }

    if (item === 'cp') {
      result.cp = splitted[i + 1];
    }

    if (item === 'mate') {
      result.mate = splitted[i + 1];
    }

    if (item === 'pv') {
      result.pv = [];

      for (let u = i + 1; u < splitted.length; u++) {
        result.pv.push(splitted[u]);
      }

      return result;
    }
  }
  return result;
};

const makeMove = (move, chess) => {
  try {
    chess.move(move.substring(0, 2) + '-' + move.substring(2, 4));
    return chess.fen();
  } catch {
    return;
  }
};

const convertToFigurineNotation = (moves, chess) => {
  if (!moves || moves.length === 0) {
    return;
  }
  const pieceSymbols = {
    w: { p: '', r: '♖', n: '♘', b: '♗', q: '♕', k: '♔' },
    b: { p: '', r: '♜', n: '♞', b: '♝', q: '♛', k: '♚' },
  };
  const fen = chess.fen();
  let color = fen.split(' ')[1];

  let moveNumber = fen.split(' ')[5];

  const pgnMoves = moves.reduce(
    (acc, sanMove, index) => {
      const figurine = chess.get(sanMove.substring(0, 2));
      const allMoves = chess.moves({ verbose: true });
      if (!figurine) {
        return acc;
      }

      const symbol = pieceSymbols[figurine.color][figurine.type];
      if (!symbol && figurine.type !== 'p') {
        console.log('!!!!!!', figurine);
      }
      let pgnNotation;

      const [A, B, C, D, E] = sanMove.split('');

      if (figurine.type === 'p') {
        if (A === C) {
          pgnNotation = `${C}${D}`;
        } else {
          pgnNotation = `${A}x${C}${D}`;
        }
        if (E) {
          pgnNotation += pieceSymbols[figurine.color][E];
        }
      }

      if (figurine.type !== 'p') {
        let X = '',
          Y,
          Z = `${C}${D}`;

        if (chess.get(`${C}${D}`)) {
          Y = 'x';
        } else {
          Y = '';
        }

        if (figurine.type === 'k') {
          if (A === 'e' && C == 'g') {
            pgnNotation = 'O-O';
          } else if (A === 'e' && C == 'c') {
            pgnNotation = 'O-O-O';
          } else {
            pgnNotation = `${
              pieceSymbols[figurine.color][figurine.type]
            }${Y}${Z}`;
          }
        } else {
          const similarPieces = allMoves.filter(
            (m) =>
              m.piece !== 'p' &&
              m.color === figurine.color &&
              m.piece === figurine.type &&
              m.from !== sanMove.substring(0, 2) &&
              m.to === `${C}${D}`,
          );

          if (similarPieces.length > 0) {
            const sameMoveTo = similarPieces[0];
            // if(sameMoveTo) {
            const sameVertical = sameMoveTo.from.substring(0, 1) === A;
            const sameHorizontal = sameMoveTo.from.substring(1, 2) === B;

            // if(sameVertical || sameHorizontal) {
            //   console.log(sameMoveTo, )
            //   console.log( `${symbol}${A}${Y}${Z}` )
            // }

            if (sameVertical && !sameHorizontal) {
              X = B;
            } else if (sameHorizontal && !sameVertical) {
              X = A;
            } else if (sameHorizontal && sameVertical) {
              X = `${A}${B}`;
            } else {
              X = A;
            }

            // }
          }
          pgnNotation = `${symbol}${X}${Y}${Z}`;
        }
      }

      if (color === 'w') {
        if (index !== 0) {
          moveNumber++;
          acc.push({
            moveStr: `${moveNumber}.`,
            moves: [],
          });
        }
      }
      acc[acc.length - 1].moves.push({
        moveIndex: index,
        moveName: pgnNotation,
        originalMove: sanMove,
        fen: chess.fen(),
      });

      color = color === 'w' ? 'b' : 'w';
      makeMove(sanMove, chess);
      return acc;
    },
    [
      {
        moveStr: `${moveNumber}${color === 'b' ? '...' : '.'}`,
        moves: [],
      },
    ],
  );

  return pgnMoves;
};

import deepFind from '@/helpers/deepFind';
import requestOrchestrator from '@/plugins/requestOrchestrator';
import { getDb } from '@/plugins/db.js';

export default {
  data: function () {
    this.sfChess = null;
    return {
      dialogType: null,
      searchModel:
        // "rnbq1rk1/ppp1bppp/3p1n2/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1 b - - 6 7",
        // 'rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5',
        '',
      tooltip: null,
      stockfishCalculationsCount: 0,
      preparedTree: null,
      blockHeight: 60,
      blockWidth: 120,
      // boardApi: boardApi,
      // smallBoardApi: smallBoardApi,
      moveBoardApi: null,
      stockFishStrings: [],
      //если выбрана ветвь стокфиша
      selectedStockFishArr: [],
      currentStockFishIndex: undefined,
      currentStockFishFigurineIndex: undefined,
      rowsToggled: [],
      customMovesArr: [],
      currentCustomMoveIndex: undefined,
      bookMarks: [],
      moveBoard: null,
      compareFiltersDialog: false,
      showLeftMenu: true,
      treeModel: 1,
      movesModel: 2,
      stockFishSync: undefined,
      stockfishMoveIndex: undefined,
      stockfishItemIndex: undefined,
      customMove: '',
      preventedByCustomMove: false,
      originalOpening: null,
      openedNodesArr: [],
      TOMode: 1,
      practiceMode: 0,
      emphasisMode: 2,
    };
  },
  components: {
    SvgTree,
    AnalysisFilters,
    CompareFilters,
    AnalysisSummary,
    AnalysisSettings,
    AnalysisPreferences,
    AdvancedBoard,
    MistakesList,
    MiniMap,
  },
  computed: {
    ...mapState(['isAuthenticated', 'sizes']),
    ...mapState('data', [
      'openingsTree',
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'currentSide',
      'parsedFen',
    ]),
    ...mapState('ui', ['userPreferences']),
    ...mapGetters('data', ['getMistakesOpenings']),
    mistakeFens() {
      const allMistakes = this.getMistakesOpenings;
      if (!allMistakes) {
        return {};
      }
      return allMistakes.reduce((acc, item) => {
        acc[item.position_id] = item;
        return acc;
      }, {});
    },
    stockFishFen() {
      const {
        currentStockFishIndex,
        currentStockFishFigurineIndex,
        selectedStockFishArr,
      } = this;

      if (!selectedStockFishArr || currentStockFishIndex === undefined) return;

      const current =
        selectedStockFishArr.moves?.[currentStockFishIndex]?.moves?.[
          currentStockFishFigurineIndex
        ];
      if (!current) return;

      return current?.fen;
    },
    listBoxModel: {
      get() {
        const selectedOpening = this.selectedOpening;
        const getListboxName = this.getListboxName;

        if (
          !getListboxName ||
          !selectedOpening ||
          selectedOpening?.status === undefined ||
          selectedOpening.pos_to_id === null
        )
          return undefined;

        if (getListboxName === 'LBhero') {
          if (selectedOpening.status === 0) {
            return 'h0';
          }
          if (selectedOpening.status === 1) {
            return 'h1';
          }
          return 'h2';
        }

        if (getListboxName === 'LBopp') {
          if (selectedOpening.status === 0) {
            return 'o0';
          }
          if (selectedOpening.status === 1) {
            return 'o1';
          }
          return 'o2';
        }

        return undefined;
      },
      async set(val) {
        const handleListboxClick = this.handleListboxClick;
        const selectedOpening = cloneDeep(this.selectedOpening);
        const rootColor = this.openingsTree.hm % 2 === 0 ? 'black' : 'white';
        const heromove =
          (rootColor === 'black' && selectedOpening.hm % 2 !== 0) ||
          (rootColor === 'white' && selectedOpening.hm % 2 === 0)
            ? 1
            : 0;

        const handlers = {
          h0: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 0,
              heromove,
            }),
          h1: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 1,
              heromove,
            }),
          h2: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 2,
              heromove,
            }),
          o0: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 0,
              heromove,
            }),
          o1: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 1,
              heromove,
            }),
          o2: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 2,
              heromove,
            }),
        };

        if (handlers[val]) {
          handlers[val]();

          const clone = cloneDeep(this.treeData);
          const targets = deepObjectSearch({
            target: [clone],

            cb: (i) =>
              i.pos_from_id === selectedOpening.pos_from_id &&
              i.pos_to_id === selectedOpening.pos_to_id,
          });
          //           const target = this.$deepFind({
          //             array: clone,
          //             key: 'nodethis',
          //             value: selectedOpening.nodethis,
          //           });
          // console.log('target', target)
          if (targets) {
            for (let u = 0; u < targets.length; u++) {
              targets[u].target.status = Number(val.substring(1));
              // targets[u].target.node_backlight = resp.node_backlight;
            }

            const target = targets.find(
              (i) => i.target.nodethis === selectedOpening?.nodethis,
            );
            this.treeData = clone;
            this.SET_DATA_BY_KEY({
              key: 'selectedOpening',
              value: target.target,
            });
            // updateNode(target, val)
          }
        } else {
          console.log('err', val);
        }
      },
    },
    getListboxDisabled() {
      const { selectedMistake, selectedOpening } = this;

      if (!selectedMistake || !selectedOpening) return false;
      const preparedTree = this.preparedTree;
      return selectedOpening?.pos_to_id === preparedTree?.pos_to_id;
    },
    getListboxName() {
      const getListboxDisabled = this.getListboxDisabled;
      const tree = this.openingsTree;
      if (getListboxDisabled || !tree) return '';
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening || !selectedOpening.fen) return '';
      // if (!selectedOpening?.node_bgcolor) return '';
      // if (selectedOpening?.node_bgcolor === '#CCFFCCFF') return 'LBhero';
      // if (selectedOpening?.node_bgcolor === '#E0E0FFFF') return 'LBopp';
      // return '';
      // return selectedOpening.node_bgcolor === '#CCFFCCFF' ? 'LBhero' : 'LBopp'
      const rootColor = tree.hm % 2 === 0 ? 'b' : 'w';
      if (selectedOpening)
        return selectedOpening.fen.split(' ')?.[1] === rootColor
          ? 'LBopp'
          : 'LBhero';
    },
    getListboxItems() {
      const getListboxName = this.getListboxName;
      const selectedOpening = this.selectedOpening;

      if (!getListboxName) return [];

      const heroItems = [
        {
          title: this.$t('moveMarking.hchoice'),
          id: 'h0',
        },
        {
          title: this.$t('moveMarking.hignore'),
          id: 'h1',
        },
        {
          title: this.$t('moveMarking.hbydefault'),
          id: 'h2',
          // onClick: () => console.log('потом разберусь')
        },
      ];

      const oppItems = [
        {
          title: this.$t('moveMarking.ochoice'),
          id: 'o0',
        },
        {
          title: this.$t('moveMarking.oignore'),
          id: 'o1',
        },
        {
          title: this.$t('moveMarking.obydefault'),
          id: 'o2',
        },
      ];

      return getListboxName == 'LBhero' ? heroItems : oppItems;
    },
    openedNodesModel: {
      get() {
        const openedNodesArr = this.openedNodesArr;
        return (node) => {
          if (!node) return false;

          return (
            openedNodesArr.findIndex(
              (item) => item.nodethis === node.nodethis,
            ) > -1
          );
        };
      },
      set(node) {
        const openedNodesArr = cloneDeep(this.openedNodesArr);
        const index = openedNodesArr.findIndex(
          (item) => item.nodethis === node.nodethis,
        );

        if (index === -1) {
          this.openedNodesArr = [...openedNodesArr, node];
          // } else if (index === 0) {
          //   this.openedNodesArr = [];
        } else {
          openedNodesArr.splice(index, 1);
          this.openedNodesArr = openedNodesArr;
        }
      },
    },
    getPrettyMoves() {
      const stockFishStrings = this.stockFishStrings;
      if (!stockFishStrings || stockFishStrings.length === 0) {
        return [];
      }

      const all = stockFishStrings
        .filter((item) => item && item.moves && item.moves.length > 0)
        .map((item) => {
          const chess = new Chess(this.customMove || this.selectedOpening?.fen);

          const res = convertToFigurineNotation(item.moves, chess);
          // const result = item.moves.reduce((acc, move) => {
          //   const newFen = chess.fen();
          //   const figurine = convertToFigurineNotation(newFen, move);
          //   acc.push(figurine);
          //   chess.move(move.substring(0, 2) + '-' + move.substring(2, 4));

          //   return acc;
          // }, []);
          // const currentValue = Number(item.string)
          // const fixedValue = color === 'b' ? currentValue * -1 : currentValue

          return {
            moves: res,
            //  string: `${Number(item.string) * color === 'b' ? -1 : 1}`,
            string: item.string,
          };
        });

      console.log(all);

      return all;
    },
    getTooltipLastMove() {
      const tooltip = this.tooltip;
      if (!tooltip) {
        return [];
      }
      const move = tooltip.move_uci;
      if (move) {
        return [move.substring(0, 2), move.substring(2, 4)];
      } else {
        if (tooltip.prevmove) {
          return [
            tooltip.prevmove.substring(0, 2),
            tooltip.prevmove.substring(2, 4),
          ];
        } else {
          const prevmove = this.selectedMistake?.prevmove;
          if (prevmove) {
            return [prevmove.substring(0, 2), prevmove.substring(2, 4)];
          }
        }
      }

      return [];
    },
    getLastMove() {
      const parsedFen = this.parsedFen;

      if (parsedFen && parsedFen.prevmove) {
        return [
          parsedFen.prevmove.substring(0, 2),
          parsedFen.prevmove.substring(2, 4),
        ];
      }

      if (this.currentCustomMoveIndex !== undefined) {
        const move = this.customMovesArr[this.currentCustomMoveIndex];

        if (move?.prevMove) {
          // разобраться, почему доска не перестраивается
          return move.prevMove;
        }
      }

      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) {
        return [];
      }
      const move = selectedOpening.move_uci;
      if (!move) {
        const selectedMistake = this.selectedMistake;
        const prevmove = selectedMistake?.prevmove;
        if (prevmove) {
          return [prevmove.substring(0, 2), prevmove.substring(2, 4)];
        }
      } else {
        return [move.substring(0, 2), move.substring(2, 4)];
      }
      // console.log(JSON.parse(JSON.stringify(selectedOpening)))
      return [];
    },
    stockfishMoves() {
      if (!this.stockFishSync) return [];

      //  console.log(JSON.stringify(this.stockFishStrings))
      return this.stockFishStrings[this.stockfishItemIndex]?.moves || [];
    },
    markStudiedDisabled() {
      const selectedMistake = this.selectedMistake;
      if (!selectedMistake) return true;
      const labels = selectedMistake.labels;
      if (!labels) return false;
      const labelsArray = this.$parseArray(labels);
      return labelsArray.indexOf(1) > -1;
    },
    toggledRow: {
      get() {
        const rowsToggled = this.rowsToggled;
        return (index) => rowsToggled.indexOf(index) > -1;
      },
      set(index) {
        const rowsToggled = this.rowsToggled.slice();

        const curIndex = rowsToggled.indexOf(index);

        if (curIndex === -1) {
          this.rowsToggled = [...rowsToggled, index];
        } else {
          rowsToggled.splice(curIndex, 1);
          this.rowsToggled = rowsToggled;
        }
      },
    },
    menuItems() {
      return [
        {
          title: this.$t('accountMenu.settings'),
          id: 'settings',
        },
        {
          title: this.$t('accountMenu.preferences'),
          id: 'preferences',
        },
      ];
    },
    treeModes() {
      return [
        {
          id: 1,
          title: this.$t('opening1.barModeResults'),
        },
        {
          id: 2,
          title: this.$t('opening1.barModeDecisions'),
        },
      ];
    },
    TOOptions() {
      return [
        {
          id: 0,
          title: this.$t('opening1.tOIgnore'),
        },
        {
          id: 1,
          title: this.$t('opening1.tOBest'),
        },
        {
          id: 2,
          title: this.$t('opening1.tOAll'),
        },
      ];
    },
    visibilityFilters() {
      const { TOMode, practiceMode, emphasisMode } = this;

      return { TOMode, practiceMode, emphasisMode };
    },
    practiceOptions() {
      return [
        {
          id: 0,
          title: this.$t('opening1.practiceIgnore'),
        },
        {
          id: 1,
          title: this.$t('opening1.practiceHero'),
        },
        {
          id: 2,
          title: this.$t('opening1.practiceAll'),
        },
      ];
    },
    emphasisOptions() {
      return [
        {
          id: 0,
          title: this.$t('opening1.emphasisIgnore'),
        },
        {
          id: 1,
          title: this.$t('opening1.emphasis3'),
        },
        {
          id: 2,
          title: this.$t('opening1.emphasis2'),
        },
        {
          id: 3,
          title: this.$t('opening1.emphasis1'),
        },
      ];
    },
    useStockFish: {
      get() {
        return this.stockFishSync;
      },
      set(val) {
        if (!val) this.$stockfish?.postMessage('stop');
        localStorage.setItem('useStockfish', val ? '1' : '0');
        this.stockFishSync = val;
      },
    },
    getBrush() {
      const selectedOpening = this.selectedOpening;
      const vw = this.$store.state.sizes.vw;
      const tileSize = ((vw / 100) * 18) / 8;

      if (!selectedOpening) return {};
      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });
      if (!target) return {};

      const children = target.children;

      if (!children || children.length === 0) {
        return {};
      }

      const brushes = children.reduce((acc, child) => {
        const { b_arrow_color, b_arrow_thickness } = child;

        acc[b_arrow_color] = {
          key: b_arrow_color,
          color: b_arrow_color,
          opacity: 1,
          // lineWidth: Math.round(20 * (b_arrow_thickness / 100)),
          // lineWidth: Math.round(b_arrow_thickness / 100 * tileSize),
          lineWidth: Math.round(tileSize * 0.35 * (b_arrow_thickness / 100)),
        };
        return acc;
      }, {});
      return brushes;
    },
    compareFilters() {
      const userPreferences = this.userPreferences;
      if (!userPreferences) return null;
      const compareObj = {
        pgid: userPreferences?.compare_pgid,
        avelo:
          (typeof userPreferences?.compare_elo === 'string'
            ? this.$parseArray(userPreferences?.compare_elo)
            : userPreferences?.compare_elo) || [],
        categories:
          (typeof userPreferences?.compare_tc === 'string'
            ? this.$parseArray(userPreferences?.compare_tc)
            : userPreferences?.compare_tc) || [],
        elodiff:
          (userPreferences?.compare_ed?.indexOf('{') > -1
            ? this.$parseArray(userPreferences?.compare_ed).join(',')
            : userPreferences?.compare_ed) || [],
      };
      return compareObj;
    },
    getSelectedOpeningArrows() {
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) return [];

      const selectedStockFishArr = this.selectedStockFishArr;

      if (selectedStockFishArr?.moves) {
        return [];
      }

      const customMovesArr = this.customMovesArr;

      if (customMovesArr.length > 0) {
        return [];
      }
      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });
      if (!target) return [];

      const children = target.children;

      if (!children || children.length === 0) {
        return [];
      }

      const arrows = children.reduce((acc, child) => {
        if (!child.visible) {
          return acc;
        }
        const coords = this.getMoveCoords(child.move_uci);
        if (!coords?.[0] || !coords?.[1]) return acc;
        acc.push({
          orig: coords[0],
          dest: coords[1],
          brush: child.b_arrow_color,
        });
        return acc;
      }, []);

      return arrows;
    },
    orientation() {
      const rootEl = this.preparedTree;

      if (rootEl) {
        return rootEl.hm % 2 === 0 ? 'white' : 'black';
      }
      return 'white';
    },
    optionsDialogModel() {
      return this.dialogType ? true : false;
    },
    getFullNode() {
      const selectedOpening = this.selectedOpening;
      const preparedTree = this.preparedTree;

      if (!selectedOpening || !preparedTree) {
        return null;
      }
      const searchResult = deepObjectSearch({
        target: [preparedTree],
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });

      if (searchResult && searchResult[0] && searchResult[0].target) {
        return searchResult[0].target;
      }
      if (selectedOpening.id && selectedOpening.id === preparedTree.id) {
        return preparedTree;
      }
      return null;
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
    weakItems() {
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) return [];

      return [];
    },
    treeData: {
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'openingsTree', value: val });
      },
      get() {
        return this.openingsTree;
      },
    },
    getFlatArray() {
      const tree = this.preparedTree;

      const openedNodesModel = this.openedNodesModel;
      if (!tree) return [];
      if (!openedNodesModel(tree)) {
        return [[tree]];
      }
      const reducer =
        (iteration, parentIndex = 0) =>
        (acc, item, index) => {
          if (!acc[iteration]) {
            acc[iteration] = [];
          }

          if (!item.visible) {
            return acc;
          }
          acc[iteration].push({ ...item, parentIndex: parentIndex });

          if (!openedNodesModel(item)) {
            return acc;
          } else {
            if (!item.children) {
              item.children = [];
            } else {
              item.children.reduce(reducer(iteration + 1, index), acc);
            }
          }

          return acc;
        };

      const result = tree.children.reduce(reducer(1, 0), [[tree]]);

      return result;
    },
    getHeroName() {
      const { heroesList, heroid } = this;
      if (!heroesList) return '';

      return heroesList.find((hero) => hero.hero_id == heroid)?.name || '';
    },
    getCompareString() {
      const compareFilters = this.compareFilters;
      if (!compareFilters || Object.keys(compareFilters).length === 0)
        return '';

      const { pgid, avelo, categories, elodiff } = compareFilters;
      const name = this.getHeroName;

      if (pgid === 1) {
        return this.$t('playground.pg1');
      }

      const siteMap = {
        1: this.$t('playground.pg1'),
        2: this.$t('playground.pg2'),
        3: this.$t('playground.pg3'),
      };

      const aveloMap = {
        2100: this.$t('compare.avelo1'),
        2500: this.$t('compare.avelo2'),
        2900: this.$t('compare.avelo3'),
      };

      const categoriesMap = {
        2: this.$t('compare.tc2_2'),
        3: this.$t('compare.tc2_3'),
        4: this.$t('compare.tc2_4'),
      };

      const elodiffMap = {
        4: this.$t('compare.headered4'),
        '3,4,5': this.$t('compare.headered345'),
        '2,3,4,5,6': this.$t('compare.headered23456'),
      };

      return (
        `${siteMap[pgid]}
      ${avelo.map((i) => aveloMap[i]).join(this.$t('compare.separator1'))}` +
        this.$t('compare.separator3') +
        `${categories
          .map((i) => categoriesMap[i])
          .join(this.$t('compare.separator1'))}
      ${elodiffMap[elodiff]}
      `
      );
    },
    getAddedToBookmarks() {
      const bookMarks = this.bookMarks;
      return (item) =>
        bookMarks.find(
          (i) => i.pos_to_id === item.pos_to_id && i.hm === item.hm,
        );
    },
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakesOpening',
      'modifyPositionLabel',
      'getHeroesList',
      'addToExercises',
      'removeFromExercises',
      'getExercisesList',
    ]),
    ...mapActions(['logOutUser']),
    handleCustomMouseLeave(e) {
      // (!e.target.dataset || e.target.dataset && e.target.dataset.parent != 1) ? toggleTooltip(null) : null
    },
    resetStockFishArr() {
      this.selectedStockFishArr = [];
      this.currentStockFishIndex = undefined;
      this.currentStockFishFigurineIndex = undefined;
      this.customMove = '';
    },
    addUserMoveToStockFishArr({ fen, moveName, originalMove }) {
      const selectedMistake = this.selectedMistake;
      if (!this.selectedStockFishArr || !this.selectedStockFishArr.moves) {
        const tmp = {
          moves: [
            {
              moves: [],
              moveStr: `${
                (this.selectedMistake.hm - (this.selectedMistake.hm % 2)) / 2
              }${this.selectedMistake.hm % 2 === 0 ? '...' : '.'}`,
            },
          ],
        };
        this.currentStockFishIndex = 0;
        this.currentStockFishFigurineIndex =
          this.selectedMistake.hm % 2 === 0 ? 1 : 0;

        tmp.moves[0].moves[0] = {
          fen: selectedMistake.fen,
          moveIndex: selectedMistake.hm % 2 === 0 ? 1 : 0,
          prevMove: selectedMistake.prevmove,
          originalMove: selectedMistake.prevmove,
          moveName: selectedMistake.prevmove,
        };

        this.selectedStockFishArr = tmp;
      }

      const {
        selectedStockFishArr,
        currentStockFishIndex,
        currentStockFishFigurineIndex,
      } = this;

      const current =
        this.selectedStockFishArr?.moves?.[this.currentStockFishIndex]?.moves?.[
          this.currentStockFishFigurineIndex
        ];

      const clone = cloneDeep(this.selectedStockFishArr);

      const getNewObj = () => ({
        custom: true,
        fen: fen,
        moveIndex: current
          ? current.moveIndex + 1
          : selectedMistake.hm % 2 === 0
          ? 1
          : 0,

        moveName, //"♗f2"
        originalMove, // "e3f2"
        prevmove: current ? current.originalMove : selectedMistake.prevmove,
      });

      const getMoveStr = (str) => {
        if (str.indexOf('...' > -1)) {
          const num = Number(str.split('...')[0]);
          return `${num + 1}.`;
        } else {
          const num = Number(str.split('.')[0]);
          return `${num + 1}.`;
        }
      };

      if (current?.custom !== true) {
        clone.moves = selectedStockFishArr?.moves?.slice(
          0,
          currentStockFishIndex + 1,
        );
      }
      const newObj = getNewObj();

      if (currentStockFishFigurineIndex === 1) {
        console.log(
          'currentStockFishIndex',
          currentStockFishIndex,
          'this.selectedStockFishArr?.moves',
          this.selectedStockFishArr?.moves,
        );
        clone.moves.push({
          moveStr: getMoveStr(
            this.selectedStockFishArr?.moves?.[currentStockFishIndex].moveStr,
          ),
          moves: [newObj],
        });
      } else {
        clone.moves[currentStockFishIndex].moves[1] = newObj;
      }

      this.selectedStockFishArr = clone;

      if (currentStockFishFigurineIndex === 1) {
        this.currentStockFishIndex += 1;
      }
      this.currentStockFishFigurineIndex =
        currentStockFishFigurineIndex === 1 ? 0 : 1;

      console.log(this.selectedStockFishArr);
    },
    prepareStockFishArray(array) {
      const clone = cloneDeep(array);

      const selectedMistake = this.selectedMistake;
      const customMove = this.customMove;
      // console.log(array, selectedMistake)
      const sfChess = this.sfChess;
      sfChess.load(customMove || selectedMistake.fen);
      let lastMove = customMove ? null : selectedMistake.prevmove;
      clone.moves.forEach((item) => {
        item.moves.forEach((move) => {
          sfChess.move(
            move.originalMove.substring(0, 2) +
              '-' +
              move.originalMove.substring(2, 4),
          );
          move.prevmove = lastMove;
          lastMove = move.originalMove;
          move.fen = sfChess.fen();
        });
      });

      return clone;
    },
    async handleListboxClick(params) {
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) return;
      const heroid = Number(this.heroid);
      const postoid = selectedOpening.pos_to_id;
      const posfromid = selectedOpening.pos_from_id;
      const baseParams = {
        heroid,
        postoid,
        posfromid,
      };
      const resp = await axios({
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: { ...baseParams, ...params },
      });

      if (resp && resp.data) {
        return resp.data;
      } else {
        return null;
      }
    },
    removeFromTest() {
      const selectedMistake = this.selectedMistake;

      if (!selectedMistake) return;

      this.removeFromExercises({
        heroid: Number(this.heroid),
        roots: [
          {
            posid: selectedMistake.position_id,
            herowhite: selectedMistake.herowhite,
          },
        ],
      });
    },
    addToTest() {
      const selectedMistake = this.selectedMistake;

      if (!selectedMistake) return;

      this.addToExercises({
        heroid: Number(this.heroid),
        depth: 30,
        barrier: 250,
        posid: selectedMistake.position_id,
        herowhite: selectedMistake.herowhite,
        // roots: [
        //   {
        //     posid: selectedMistake.position_id,
        //     herowhite: selectedMistake.herowhite,
        //   },
        // ],
      });
    },
    async toggleNode(node, preventGoToChild = true) {
      const id = node.nodethis;

      const isOpened = this.openedNodesModel(node);
      if (isOpened) {
        //  this.openedNodesModel = node;
      } else {
        const currentNode = deepFind({
          array: [this.preparedTree],
          value: id,
          key: 'nodethis',
        });
        const hasChildren = currentNode?.children?.length > 0;

        if (hasChildren) {
          //  this.openedNodesModel = node;
        } else {
          await this.loadChildren({
            _node_id: id,
            auto: false,
            heroid: this.$route.params.heroid,
            needChangeStatus: false,
            herocats: this.$route.params.categories,
            hm: node.hm,
            posid: Number(node.posid || node.pos_to_id),
            nodeid: node.nodethis,
          });

          // this.openedNodesModel = node;
        }
      }
      this.openedNodesModel = node;
      console.time('prepare tree');
      const prepared = this.prepareTree(this.treeData);
      console.timeEnd('prepare tree');
      this.preparedTree = cloneDeep(prepared);

      const newNode = deepFind({
        array: [prepared],
        value: id,
        key: 'nodethis',
      });

      if (newNode && newNode.children.length > 0 && !preventGoToChild) {
        const movesModel = this.movesModel;

        if (movesModel === 2) {
          for (let child of newNode.children) {
            if (child.show_move === 1) {
              this.goToNode(child);
              return;
            }
          }
        } else {
          this.goToNode(newNode.children[0]);
        }
      } else {
        this.goToNode(newNode);
      }
    },
    getLastMoveArray(opening) {
      if (!opening || !opening.prevmove) {
        return;
      }

      return [
        opening.prevmove.substring(0, 2),
        opening.prevmove.substring(2, 4),
      ];
    },
    handleToogleBookmark(item) {
      const bookMarks = this.bookMarks;
      const index = bookMarks.findIndex(
        (i) => i.pos_to_id === item.pos_to_id && i.hm === item.hm,
      );
      const clone = cloneDeep(bookMarks);
      if (index === -1) {
        clone.push(item);
      } else {
        clone.splice(index, 1);
      }

      this.bookMarks = clone;
    },
    goToBookmark(item) {
      this.handleSelectOpening(item);
      this.goToNode(item);
    },
    resetBoard() {
      this.preventedByCustomMove = false;
      if (this.$refs.bigBoard?.resetBoard) {
        this.$refs.bigBoard?.resetBoard();
        this.customMove = null;
        this.customMovesArr = [];
        this.currentCustomMoveIndex = undefined;
      }

      if (this.selectedOpening && this.originalOpening) {
        this.SET_DATA_BY_KEY({
          key: 'selectedOpening',
          value: this.originalOpening,
        });
      }

      if (this.$stockfish) {
        this.$stockfish?.postMessage('stop');
        this.stockFishStrings = [];
        this.stockfishCalculationsCount = 0;

        this.$stockfish.onmessage = this.handleStockFishMessage;

        if (this.originalOpening) {
          this.$stockfish.postMessage(
            `position fen ${this.originalOpening.fen}`,
          );
          this.$stockfish.postMessage('go infinite');
        }
      }
    },
    handleLogOut() {
      const cb = () => this.$router.push('/login');
      this.logOutUser(cb);
    },
    async goToNode(node, offset = { top: 0, left: 0 }) {
      await new Promise((res) => setTimeout(res, 50));

      const el = document.getElementById('block' + node.nodethis);

      if (el) {
        const container = this.$refs.treeContainer;

        if (container) {
          const coords = el.dataset.cornerCoords;
          container.scrollTo({
            left: JSON.parse(coords)[0] + offset.left,
            top: JSON.parse(coords)[1] + offset.top,
            behavior: 'smooth',
          });
        }

        // el.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'end',
        //   inline: 'end',
        // });
      }
    },
    handleStockFishStringChange(direction) {
      const {
        currentStockFishIndex,
        currentStockFishFigurineIndex,
        selectedStockFishArr,
      } = this;
      const moves = selectedStockFishArr.moves;
      if (direction === 'up' || direction === 'down') {
        return;
      }

      if (direction === 'right') {
        if (currentStockFishFigurineIndex === 1) {
          const nextMove = moves?.[currentStockFishIndex + 1]?.moves?.[0];
          if (!nextMove) {
            return;
          }
          this.currentStockFishIndex += 1;
          this.currentStockFishFigurineIndex = 0;
        } else {
          const nextMove = moves?.[currentStockFishIndex]?.moves?.[1];
          if (!nextMove) {
            return;
          }
          // this.stockFishFen = nextMove.fen
          this.currentStockFishFigurineIndex = 1;
        }
      }

      if (direction === 'left') {
        if (currentStockFishFigurineIndex === 1) {
          const nextMove = moves?.[currentStockFishIndex]?.moves?.[0];
          if (!nextMove) {
            return;
          }
          // this.stockFishFen = nextMove.fen
          this.currentStockFishFigurineIndex = 0;
        } else {
          const nextMove = moves?.[currentStockFishIndex - 1]?.moves?.[1];
          if (!nextMove) {
            return;
          }
          this.currentStockFishIndex -= 1;
          this.currentStockFishFigurineIndex = 1;
        }
      }

      return;
    },
    handleCustomMoveChange(direction) {
      if (direction === 'up' || direction === 'down') {
        return;
      }
      if (direction === 'right') {
        if (this.currentCustomMoveIndex === this.customMovesArr.length - 1) {
          return;
        }

        this.currentCustomMoveIndex++;
      }

      if (direction === 'left') {
        if (this.currentCustomMoveIndex === 0) {
          return;
        }

        this.currentCustomMoveIndex--;
      }

      const newMove = this.customMovesArr[this.currentCustomMoveIndex];

      if (newMove) {
        this.customMove = newMove.fen;

        if (this.useStockFish && this.$stockfish) {
          this.stockfishCalculationsCount = 0;
          this.$stockfish.postMessage('stop');
          this.stockFishStrings = [];
          this.$stockfish.postMessage(`position fen ${newMove.fen}`);
          this.$stockfish.postMessage('go infinite');
        }
      }
    },
    handleArrowPress(direction) {
      if (this.currentStockFishIndex !== undefined) {
        this.handleStockFishStringChange(direction);
        return;
      }

      if (this.customMovesArr.length > 0) {
        this.handleCustomMoveChange(direction);
        return;
      }

      const selectedOpening = this.getFullNode;
      const openedNodesModel = this.openedNodesModel;
      const onlyGood = this.movesModel === 2;

      if (!selectedOpening) return;

      if (direction === 'right') {
        if (selectedOpening.iscrown === 1 || selectedOpening.iscrown === null)
          return;

        if (
          openedNodesModel(selectedOpening) &&
          selectedOpening.children.length > 0
        ) {
          if (!onlyGood) {
            this.handleSelectOpening(selectedOpening.children[0]);
          } else {
            const children = selectedOpening.children;

            for (let child of children) {
              if (child.show_move === 1) {
                this.handleSelectOpening(child);
                // this.goToNode(child, {left: -(this.blockWidth * 2)})
                return;
              }
            }
          }

          // this.goToNode(selectedOpening.children[0]);
          return;
        } else {
          this.toggleNode(selectedOpening, true);
          // this.openedNodesModel =selectedOpening
          // this.loadChildren({
          //   _node_id: selectedOpening.id,
          //   preventOpen: false,
          //   heroid: this.heroid,
          //   herocats: this.heroCategories,
          // });
          return;
        }
      }

      const searchResult = deepObjectSearch({
        target: [this.preparedTree],
        key: 'nodethis',
        value: selectedOpening.nodeparent,
      });

      if (!searchResult || !searchResult[0] || !searchResult[0].target) {
        return;
      }

      const parent = searchResult[0].target;

      if (direction === 'left') {
        this.handleSelectOpening(parent);
        this.goToNode(parent);

        return;
      }

      const siblings = parent.children;

      if (siblings.length === 1) {
        return;
      }

      const currentIndex = siblings.findIndex(
        (el) => el.nodethis === selectedOpening?.nodethis,
      );

      if (currentIndex === -1) {
        return;
      }

      if (direction === 'up') {
        const newIndex = currentIndex - 1;
        if (newIndex < 0 || !siblings[newIndex]) {
          return;
        }

        if (onlyGood) {
          for (let i = newIndex; i >= 0; i--) {
            const sibling = siblings[i];
            if (sibling && sibling.show_move === 1) {
              this.handleSelectOpening(sibling);
              this.goToNode(sibling);
              return;
            }
          }
        } else {
          this.handleSelectOpening(siblings[newIndex]);
          this.goToNode(siblings[newIndex]);
        }

        return;
      }

      if (direction === 'down') {
        const newIndex = currentIndex + 1;
        if (newIndex > siblings.length - 1 || !siblings[newIndex]) {
          return;
        }

        if (onlyGood) {
          for (let i = newIndex; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling && sibling.show_move === 1) {
              this.handleSelectOpening(sibling);
              this.goToNode(sibling);
              return;
            }
          }
        } else {
          this.handleSelectOpening(siblings[newIndex]);
          this.goToNode(siblings[newIndex]);
        }

        return;
      }
    },
    getEyeColor(labels) {
      if (!labels) return 'grey';
      const has3 = labels.indexOf(3) > -1;
      const has2 = labels.indexOf(2) > -1;

      if (has2 || has3) return 'black';
      return 'grey';
    },
    getEyeIcon(labels) {
      if (!labels || labels.indexOf(2) > -1) {
        return 'mdi-eye';
      }
      return 'mdi-eye-off';
    },
    async setCurrentTreeToDB() {
      const selectedMistake = this.selectedMistake;

      if (selectedMistake && localStorage.getItem('useIDB') == 1) {
        const obj = {
          position_id: selectedMistake.position_id,
          tree: cloneDeep(this.preparedTree),
          openedNodesArr: cloneDeep(this.openedNodesArr),
          selectedOpening: cloneDeep(this.selectedOpening),
        };

        const localDb = await getDb();
        if (localDb) {
          const tx = localDb.transaction('tree', 'readwrite');

          const store = tx.objectStore('tree');

          store.put(obj);

          // const index = store.index('position_id');

          // if (index) {
          //   const request = index.get(selectedMistake.position_id);
          //   request.onsuccess = function () {
          //     const matching = request.result;
          //     if (matching !== undefined) {
          //       // A match was found.
          //       console.log(matching.tree);
          //     } else {
          //       // No match was found.

          //       console.log(null);
          //     }
          //   };
          // }
        }
      }
    },
    async handleMistakeClick(item) {
      await requestOrchestrator.killAll();
      this.bookMarks = [];
      this.originalOpening = null;
      this.customMovesArr = [];
      this.currentCustomMoveIndex = undefined;
      this.customMove = null;
      this.searchModel = '';

      this.SET_DATA_BY_KEY({ key: 'parsedFen', value: null });

      const flip_board_black = this.userPreferences.flip_board_black;
      this.SET_DATA_BY_KEY({
        key: 'currentSide',
        value:
          item.herowhite === 0 && flip_board_black === 1 ? 'black' : 'white',
      });

      //tmp
      if (localStorage.getItem('useIDB') == 1) {
        const selectedMistake = this.selectedMistake;
        if (
          selectedMistake &&
          selectedMistake.position_id !== item.position_id
        ) {
          await this.setCurrentTreeToDB();
        }
        this.SET_DATA_BY_KEY({ key: 'selectedOpening', value: null });
        const localDb = await getDb();
        if (localDb) {
          const tx = localDb.transaction('tree', 'readonly');

          const store = tx.objectStore('tree');
          const index = store.index('position_id');

          if (index) {
            const request = index.get(item.position_id);
            request.onsuccess = () => {
              const matching = request.result;
              if (matching !== undefined) {
                // A match was found.
                //console.log(matching)

                this.preparedTree = matching.tree;
                this.SET_DATA_BY_KEY({
                  key: 'openingsTree',
                  value: matching.tree,
                });
                this.openedNodesArr = matching.openedNodesArr;
                if (matching.selectedOpening) {
                  this.SET_DATA_BY_KEY({
                    key: 'selectedOpening',
                    value: matching.selectedOpening,
                  });
                  this.goToNode(matching.selectedOpening);
                }
              } else {
                // No match was found.
                this.getOpenings({
                  position_id: item.position_id,
                  hm: item.hm,
                  heroid: this.heroid,
                  herocats: this.heroCategories,
                  herowhite: item.herowhite,
                });
              }
            };
          }
        }
      } else {
        this.getOpenings({
          position_id: item.position_id,
          hm: item.hm,
          heroid: this.heroid,
          herocats: this.heroCategories,
          herowhite: item.herowhite,
        });
      }

      //tmp-end

      // console.log(this.heroCategories);
      this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: item });

      await this.$nextTick();
      const isBlack = item.herowhite == 0;
      const current = this.boardApi?.getTurnColor();

      if (
        (current === 'black' && !isBlack) ||
        (isBlack && current === 'white')
      ) {
        this.boardApi?.toggleOrientation();
      }
    },
    handleEyeClick(item) {
      const labelsArray = this.$parseArray(item.labels);
      const heroid = this.heroid;
      if (labelsArray.indexOf(2) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toIgnore',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      if (labelsArray.indexOf(3) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toDefault',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      this.modifyPositionLabel({
        opening: item,
        scenario: 'toWatchlist',
        heroid,
        herocats: this.heroCategories,
      });
    },
    markStudied(item) {
      this.modifyPositionLabel({
        opening: item,
        scenario: 'toStudied',
        heroid: this.heroid,
        herocats: this.heroCategories,
      });
    },
    handleMouseOver(
      e,
      { move, itemIndex, moveIndex, useCustomStockfish = false },
    ) {
      if (!useCustomStockfish) {
        this.stockfishItemIndex = itemIndex;
        this.stockfishMoveIndex = moveIndex;
      }
      this.stockfishItemIndex = itemIndex;
      this.stockfishMoveIndex = moveIndex;

      if (!this.moveBoard) {
        this.moveBoard = {
          top: e.pageY,
          move,
          movesArrayIndex: moveIndex,
          useCustomStockfish,
        };
      } else {
        if (this.moveBoard.move == move) return;
        this.moveBoard = {
          top: e.pageY,
          move,
          movesArrayIndex: moveIndex,
          useCustomStockfish,
        };
      }
    },
    handleMouseLeave() {
      this.moveBoard = null;
    },
    validateStockFishResponse(str) {
      if (!str) return;
      const has_pv = (str) => str.indexOf(' pv ') > -1;
      if (!has_pv(str)) return true;
      if (str.indexOf(' bound ') > -1) return true;
      return false;
    },
    getMoveCoords(str) {
      if (!str) return [];
      return [str.substring(0, 2), str.substring(2, 4)];
    },
    handleSelectOpening(opening) {
      this.resetStockFishArr();
      this.customMove = null;
      this.customMovesArr = [];
      this.currentCustomMoveIndex = undefined;

      this.SET_DATA_BY_KEY({ key: 'selectedOpening', value: opening });

      // const node = this.$deepFind({
      //   array: this.getFlatArray || [],
      //   key: 'id',
      //   value: opening.id,
      // });
      this.originalOpening = opening;
    },
    gotToExsercise() {
      this.$router.push({
        name: 'ExercisePage',
        params: {
          heroid: this.$route.params.heroid,
          posid: this.selectedMistake.position_id,
          categories: this.$route.params.categories,
          herowhite: this.selectedMistake.herowhite,
        },
      });
    },
    getParentCoords(yIndex, column, allItems, columnIndex) {
      const parentSlice = allItems[columnIndex - 1];
      let result = 0;
      if (parentSlice) {
        const parentIndex = parentSlice.findIndex(
          (item) => item.nodethis === column[yIndex]?.nodeparent,
        );
        if (parentIndex === -1) return 0;
        const parentTop = this.getPaddingTop(
          parentIndex,
          parentSlice,
          allItems,
          columnIndex - 1,
        );
        result = parentTop;
      }
      return result;
    },
    getPaddingTop(yIndex, column, allItems, columnIndex) {
      const movesModel = this.movesModel;
      //go back and find the parent, then calculate the top padding for it
      const coordsY = this.getParentCoords(
        yIndex,
        column,
        allItems,
        columnIndex,
      );
      let result = coordsY;

      for (let i = 0; i < yIndex; i++) {
        const neightbourItem = column[i];
        if (neightbourItem.nodeparent !== column[yIndex].nodeparent) {
          continue;
        }
        const neightbourCount = neightbourItem.visibleChildren;

        result += (neightbourCount ? neightbourCount : 1) * this.blockHeight;
      }

      return result;
    },
    updateNodeVisibility({ node, nodeIndex, parent, siblings }) {
      // const TOmode = this.TOMode; // 0,1,2
      // const practiceMode = this.practiceMode; // 0,1,2
      // const emphasisMode = this.emphasisMode; //0,1,2,3
      const { TOMode, practiceMode, emphasisMode } = this.visibilityFilters;
      const updateParentVisibleChildren = () => {
        // parent.visibleChildren++;
        // let parentNode = parent;
        // // const visibleCount = parentNode.children.reduce((acc, item) => {
        // //             if(item.visible) {
        // //               acc+= 1
        // //             }
        // //             return acc
        // //           }, 0)
        // //           if(visibleCount === 1) {
        // //             return
        // //           }
        // while (parentNode) {
        //   parentNode.visibleChildren++;
        //   parentNode = parentNode?.parentObject;
        // }
      };

      if (node.hidden === 2) {
        return;
      }

      if (node.hidden === 0 || !parent) {
        if (!node.visible) {
          node.visible = true;
        }
        return;
      }

      // при выборе opening1.tOIgnore не имеет значения пойдёт вершина в тренировку или нет, перечень вершин по этому фильтру пуст (то есть дерево будет строиться только по значениям других выпадашек)
      const handleTo0 = () => {};

      // При выборе opening1.tOBest в перечень к отображению должна попасть та из тренировочных позиций (синих или зелёных вершин), которая в приходящем от API дереве стоит выше.
      const handleTo1 = () => {
        //чем меньше waste - тем лучше ход
        const sorted = siblings.slice().sort((a, b) => a.waste - b.waste);

        if (
          sorted.find((item) => {
            const cond1 = item.visible === true;
            const cond2 =
              item.status === 0 ||
              (item.status === 2 && item.default_training === 1);
            return cond1 && cond2;
          })
        ) {
          return;
        }

        for (let i = 0; i < sorted.length; i++) {
          const currentSibling = sorted[i];
          if (currentSibling && currentSibling.visible) {
            return;
          }

          if (
            currentSibling.status === 0 ||
            (currentSibling.status === 2 &&
              currentSibling.default_training === 1)
          ) {
            if (!currentSibling.visible) {
              currentSibling.visible = true;
              return;
            }
          }
        }

        // if (nodeIndex > 0) {
        //   for (let i = nodeIndex - 1; i >= 0; i--) {
        //     const currentSibling = sorted[i];

        //     if (
        //       currentSibling &&
        //       currentSibling.visible &&
        //       (currentSibling.status === 0 ||
        //         (currentSibling.status === 2 &&
        //           currentSibling.default_training === 1))
        //     ) {
        //       return;
        //     }
        //   }

        //   if (
        //     node.status === 0 ||
        //     (node.status === 2 && node.default_training === 1)
        //   ) {
        //     if (!node.visible) {
        //       node.visible = true;
        //     }
        //   }
        // }
      };
      // При выборе opening1.tOAll все тренировочные позиции отображаются в перечне.
      const handleTo2 = () => {
        if (
          node.status === 0 ||
          (node.status === 2 && node.default_training === 1)
        ) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };

      const TOhandlers = [handleTo0, handleTo1, handleTo2];

      const { hm } = node;
      const herowhite = this.herowhite;

      const oppMove =
        (hm % 2 === 0 && herowhite === 0) || (hm % 2 !== 0 && herowhite === 1);

      // при выборе opening1.practiceIgnore не имеет значения встречалась ли данная вершина в партиях героя или нет, перечень вершин по этому фильтру пуст.
      const handlePractice0 = () => {};
      // При выборе opening1.practiceHero в перечень к отображению должны попасть те вершины,
      // которые возникали в практике героя после хода героя.
      const handlePractice1 = () => {
        // если не ход героя и move_count_hero !== 0

        if (oppMove && node.move_count_hero !== 0) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };
      //При выборе opening1.practiceAll все встречавшиеся в практике героя вершины отображаются в перечне.
      const handlePractice2 = () => {
        // если ход героя, move_count_hero !== 0

        if (node.move_count_hero !== 0) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };

      const practiceHandlers = [
        handlePractice0,
        handlePractice1,
        handlePractice2,
      ];

      //при выборе opening1.emphasisIgnore не имеет значения какой emphasis (акцент) у вершины, перечень вершин по этому фильтру пуст
      const handleEmphasis0 = () => {};
      //При выборе opening1.emphasis3 в перечень попадают все вершины с emphasis=3
      const handleEmphasis1 = () => {
        if (node.emphasis === 3) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };
      //при выборе opening1.emphasis2 в перечень попадают все вершины с emphasis in (2, 3)
      const handleEmphasis2 = () => {
        if (node.emphasis >= 2) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };
      //при выборе opening1.emphasis1 в перечень попадают все вершины с emphasis in (1, 2, 3)
      const handleEmphasis3 = () => {
        if (node.emphasis >= 1) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };

      const emphasisHandlers = [
        handleEmphasis0,
        handleEmphasis1,
        handleEmphasis2,
        handleEmphasis3,
      ];

      TOhandlers[TOMode]();
      if (node.visible) {
        return;
      }
      practiceHandlers[practiceMode]();
      if (node.visible) {
        return;
      }

      emphasisHandlers[emphasisMode]();
    },
    prepareTree(obj) {
      if (!obj) return;

      const treeData = cloneDeep(obj);

      const iterateChildren = (item, index, parent, siblings) => {
        item.nodeparent = parent?.nodethis;
        // item.parentObject = parent;
        item.visibleChildren = 0;
        const isOpened = this.openedNodesModel(item);
        if (item.children && isOpened) {
          item.children = item.children.map((ch) => ({
            ...ch,
            visible: false,
          }));
          item.children?.forEach((child, i, arr) => {
            iterateChildren(child, i, item, arr);
          });
        }
        this.updateNodeVisibility({
          node: item,
          nodeIndex: index,
          parent: parent,
          siblings,
        });
      };

      iterateChildren(treeData, 0, null, []);
      const openedNodesModel = this.openedNodesModel;
      const reducer = () => (acc, item) => {
        if (openedNodesModel(item)) {
          acc += item.children?.reduce(reducer(), 0) || 0;
        } else {
          if (item.visible) {
            acc++;
          }
        }
        return acc;
      };
      const addOpenedChildrenCount = (item, nodeparent) => {
        item.nodeparent = nodeparent;
        if (!openedNodesModel(item)) {
          item.visibleChildren = 0;
          // item.parent = cloneDeep(parent)
        } else {
          item.visibleChildren = item.children?.reduce(reducer(), 0) || 0;

          //  item.parent =  cloneDeep(parent)
          item.children?.forEach((child) =>
            addOpenedChildrenCount(child, item.nodethis),
          );
        }
      };
      addOpenedChildrenCount(treeData, treeData.nodethis, 0);

      const rootColor = treeData.hm % 2 === 0 ? 'b' : 'w';

      const setBgDisplayOption = (item, parentShown) => {
        const { status, default_training } = item;

        if (parentShown === false) {
          item.showBg = false;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            item.showBg = true;
          } else if (status === 2 && default_training === 0) {
            item.showBg = false;
          } else {
            item.showBg = false;
          }
        }

        if (item.children) {
          item.children.forEach((child) =>
            setBgDisplayOption(child, item.showBg),
          );
        }
      };

      treeData.children.forEach((child) => setBgDisplayOption(child, true));

      return treeData;
    },
    handleSearchDefaultPosition() {
      this.searchModel = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq -';
      this.handleSearch();
    },
    handleSearch() {
      this.getOpenings({
        fen: this.searchModel,
        heroid: this.heroid,
        herocats: this.heroCategories,
      });
    },
    handleSearchAfter() {
      this.getMistakesOpening({
        heroid: this.heroid,
        fen: this.searchModel,
        herocats: this.heroCategories,
      });
    },
    toggleTooltip(coords) {
      if (!coords) {
        this.tooltip = null;
        return;
      }
      const tooltip = this.tooltip;

      // if (coords.top === tooltip?.top && coords.left === tooltip?.left) {
      //   this.tooltip = null;
      //   return;
      // }

      if (coords.tree) {
        const treeContainer = this.$refs.treeContainer;

        if (treeContainer) {
          const scrollTop = treeContainer.scrollTop;
          const scrollLeft = treeContainer.scrollLeft;
          coords.top -= scrollTop;
          coords.left -= scrollLeft;
        }
      }

      this.tooltip = coords;
    },
    prepareString(str) {
      // if (!selectedOpening || !selectedOpening.hm) return '';

      const result = {
        multipv: 0,
        string: '',
        moves: [],
      };
      const obj = getStockFishObj(str);
      if (obj.depth < 14) return '';
      const chess = new Chess(this.customMove || this.selectedOpening?.fen);

      const color = chess.turn();

      if (obj) {
        if (obj.cp) {
          const currentValue = Number((obj.cp / 100).toFixed(2));

          const fixedValue = color === 'b' ? currentValue * -1 : currentValue;

          result.string += `${fixedValue} `;
        } else {
          if (obj.mate) {
            result.string += `# ${obj.mate}`;
          }
        }
        result.moves = obj.pv;
        result.multipv = obj.multipv;
      } else {
        return '';
      }

      return result;
    },
    getMistakeOpeningColor(item) {
      if (!item.labels) return 'grey--text';
    },
    updateNodeEmphasis(node) {
      const clone = cloneDeep(this.treeData);
      const targets = deepObjectSearch({
        target: [clone],

        cb: (i) =>
          i.pos_from_id === node.pos_from_id && i.pos_to_id === node.pos_to_id,
      });
      //           const target = this.$deepFind({
      //             array: clone,
      //             key: 'nodethis',
      //             value: selectedOpening.nodethis,
      //           });
      // console.log('target', target)
      if (targets) {
        for (let u = 0; u < targets.length; u++) {
          targets[u].target.emphasis = 1;
          // targets[u].target.node_backlight = resp.node_backlight;
        }

        const target = targets.find(
          (i) => i.target.nodethis === node?.nodethis,
        );
        this.treeData = clone;
        return target;
      }
    },
    handleCustomeMove(fen, orig, dest) {
      this.customMove = fen;
      // if (this.selectedStockFishArr?.moves) {
      this.addUserMoveToStockFishArr({
        fen,
        moveName: orig + dest,
        originalMove: orig + dest,
      });
      // }
      // else {
      //   const currentCustomMoveIndex = this.currentCustomMoveIndex;

      //   if (currentCustomMoveIndex === undefined) {
      //     this.customMovesArr.push({ fen, prevMove: [orig, dest] });
      //     this.currentCustomMoveIndex = 0;
      //   } else {
      //     if (currentCustomMoveIndex > this.customMovesArr.length - 1) {
      //       const clone = cloneDeep(this.customMovesArr);
      //       clone.slice(0, currentCustomMoveIndex);
      //       clone.push({ fen, prevMove: [orig, dest] });
      //       this.customMovesArr = clone;
      //       this.currentCustomMoveIndex++;
      //     } else {
      //       this.customMovesArr.push({ fen, prevMove: [orig, dest] });
      //       this.currentCustomMoveIndex++;
      //     }
      //   }
      // }

      if (this.useStockFish && this.$stockfish) {
        this.stockfishCalculationsCount = 0;
        this.$stockfish.postMessage('stop');
        this.stockFishStrings = [];
        this.$stockfish.postMessage(`position fen ${fen}`);
        this.$stockfish.postMessage('go infinite');
      }
    },
    handleStockFishMessage(event) {
      if (event.data && event.data.indexOf('info depth') > -1) {
        this.stockfishCalculationsCount += 1;

        const needToIgnore = this.validateStockFishResponse(event.data);

        if (needToIgnore) {
          return;
          // this.$stockfish.postMessage("stop");
        } else {
          //  console.log(event.data)

          const newItem = this.prepareString(event.data);

          if (newItem && newItem.moves.length > 3) {
            const clone = this.stockFishStrings.reduce((acc, item) => {
              if (newItem.multipv === item.multipv) {
                acc.push(newItem);
              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            if (
              clone.findIndex((item) => item.multipv === newItem.multipv) === -1
            ) {
              clone.push(newItem);
            }

            this.stockFishStrings = clone;
          }
        }
      }
    },
    handleUpdateTooltipBoard: throttle(function (val) {
      this.smallBoardApi?.setPosition(val.fen);
      const coords = this.getMoveCoords(val.move_uci);

      if (coords.length === 2) {
        if (val.arrowColor) {
          this.smallBoardApi?.setShapes([
            { orig: coords[0], dest: coords[1], brush: val.arrowColor },
          ]);
        }
      }
    }, 200),
  },
  watch: {
    customMove: {
      handler: async function (val, oldVal) {
        if (val !== oldVal) {
          if (val) {
            if (this.useStockFish && this.$stockfish) {
              this.stockfishCalculationsCount = 0;
              this.$stockfish.postMessage('stop');
              this.stockFishStrings = [];
              this.$stockfish.postMessage(`position fen ${val}`);
              this.$stockfish.postMessage('go infinite');
            }

            if (this.selectedOpening) {
              const selectedOpening = this.getFullNode;
              const openedNodesModel = this.openedNodesModel;

              let occurance = selectedOpening.children.find(
                (op) => op.fen.split(' ')[0] === val.split(' ')[0],
              );
              // console.log(JSON.parse(JSON.stringify(selectedOpening.children)))
              this.preventedByCustomMove = true;
              if (occurance) {
                if (occurance.emphasis === -1 || occurance.emphasis === 0) {
                  occurance = this.updateNodeEmphasis(occurance);
                }

                if (
                  openedNodesModel(selectedOpening) &&
                  selectedOpening.children.length > 0
                ) {
                  this.goToNode(occurance);
                } else {
                  await this.loadChildren({
                    _node_id: selectedOpening.id,
                    preventOpen: false,
                    heroid: this.heroid,
                    herocats: this.heroCategories,
                    nodethis: selectedOpening?.nodethis,
                    posid: Number(
                      selectedOpening.posid || selectedOpening.pos_to_id,
                    ),
                    nodeid: selectedOpening?.nodethis,
                  });
                  // this.goToNode(occurance);
                }

                this.SET_DATA_BY_KEY({
                  key: 'selectedOpening',
                  value: occurance,
                });
                this.goToNode(occurance);
              }
            }
          }
        }
      },
    },
    treeData: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (!val) return;
        const prepared = this.prepareTree(val);
        this.preparedTree = cloneDeep(prepared);
      },
    },
    selectedOpening: {
      deep: true,
      handler: async function (val) {
        if (val) {
          // await this.$nextTick();

          // this.boardApi?.resetBoard();
          // this.boardApi?.setPosition(val.fen);

          // this.smallBoardApi?.orientation(val.hm % 2 === 0 ? 'black' : 'white');

          // this.moveBoardApi?.resetBoard();
          // this.moveBoardApi?.setPosition(this.selectedOpening.fen);

          // this.boardApi?.move("g1f3");
          // this.boardApi?.move("f8g7");

          if (this.preventedByCustomMove) {
            this.preventedByCustomMove = false;
          } else {
            this.customMove = null;
            this.customMovesArr = [];
            this.currentCustomMoveIndex = undefined;
          }

          if (this.useStockFish && this.$stockfish) {
            this.stockfishCalculationsCount = 0;
            this.$stockfish.postMessage('stop');
            this.stockFishStrings = [];
            this.$stockfish.postMessage(`position fen ${val.fen}`);
            this.$stockfish.postMessage('go infinite');
          }

          if (val && val.iscrown === 0) {
            if (val?.children?.length === 0) {
              // console.log(111)
              await this.loadChildren({
                _node_id: val.id,
                preventOpen: false,
                heroid: this.heroid,
                herocats: this.heroCategories,
                nodethis: val.nodethis,
                posid: Number(val.posid || val.pos_to_id),
                nodeid: val.nodethis,
              });
            }
            if (!this.openedNodesModel(val) && val?.children?.length > 0) {
              this.toggleNode(val);
            }
          }

          // const shapeSet3: DrawShape[] = [{ orig: 'e5', brush: 'blue' }];
          // drawMove(orig: Square, dest: Square, brushColor: BrushColor): void;
          // const coords = this.getMoveCoords(val.move_uci);
          // if (coords.length === 2) {
          //   this.boardApi?.drawMove(coords[0], coords[1], "green");
          // }
        }
      },
    },
    tooltip: {
      deep: true,
      handler: async function (val) {
        if (val && val.fen) {
          await this.$nextTick();
          this.handleUpdateTooltipBoard(val);
        }
      },
    },
    userPreferences: {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (!this.heroesList) {
            this.getHeroesList();
          }
          if (this.$stockfish) {
            this.$stockfish.onmessage = this.handleStockFishMessage;
          }

          this.getMistakesOpening({
            heroid: this.heroid,
            herocats: this.heroCategories,
          });
        }
      },
    },
    stockFishSync: {
      handler: function (val) {
        if (val) {
          const selectedOpening = this.selectedOpening;
          const customMove = this.customMove;

          if (this.$stockfish && (customMove || selectedOpening?.fen)) {
            const fen = customMove || selectedOpening?.fen;

            this.stockfishCalculationsCount = 0;
            this.$stockfish.postMessage('stop');
            this.stockFishStrings = [];
            this.$stockfish.postMessage(`position fen ${fen}`);
            this.$stockfish.postMessage('go infinite');
          }
        }
      },
    },
    parsedFen: {
      handler: function (val) {
        const userPreferences = this.userPreferences;
        if (val) {
          if (val.herowhite === 0) {
            this.SET_DATA_BY_KEY({
              key: 'currentSide',
              value:
                val.herowhite === 0 && userPreferences.flip_board_black === 1
                  ? 'black'
                  : 'white',
            });
          }
        }
      },
    },
    visibilityFilters: {
      deep: true,
      handler: function () {
        const treeData = this.treeData;
        if (treeData) {
          this.preparedTree = cloneDeep(this.prepareTree(treeData));
        }
      },
    },
    // openedNodesArr: {
    //   deep: true,
    //   handler: function (val) {
    //     console.log(val);
    //   },
    // },
    // currentCustomMoveIndex: {
    //   handler: function (val) {
    //     if (val !== undefined) {

    //     }
    //   },
    // },
  },
  beforeDestroy() {
    this.$stockfish?.postMessage('stop');
  },
  mounted() {
    this.sfChess = new Chess();
    if (this.stockFishSync === undefined) {
      const local = localStorage.getItem('useStockfish') === '1';
      this.stockFishSync = local;
    }
    this.getExercisesList({ heroid: Number(this.heroid) });
  },
  provide() {
    return {
      openedNodesArr: () => this.openedNodesArr,
    };
  },
};
</script>

<style>
.main-wrap {
  width: 100% !important;
}

.left-menu {
  position: absolute;
  top: 6 0px;
  transition: left smooth;
  z-index: 100;
}

.left-menu-toggler {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  right: -30px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  cursor: pointer;
}

.stockfish-node {
  overflow: hidden;
  height: 34px;
}

.stockfish-node__toggled {
  overflow: visible;
  height: auto;
}

.hoverableStockFishItem:hover {
  font-weight: bold;
}
</style>
