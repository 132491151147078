<template>
  <div style="width: 100%" >
    <div class="text-center">{{ heroName }}</div>
    <v-divider />
    <div class="d-flex align-center" style="height: 30px">
      <div style="flex: 1">{{$t('statistics.numberOfGames')}}</div>
      <div style="flex: 1">
        {{ heroSummary }}
      </div>
    </div>
    <v-divider />
    <div style="width: 100; height: 20px" class="d-flex align-center">
      <div style="flex: 1">{{$t('statistics.resultsPerc')}}</div>
      <div style="flex: 1" class="d-flex align-center">
        <div
          v-for="(colorObj, index) in $getFill(
            { array: summaryArray, totalCount: heroSummary },
            false
          )"
          class="text-black"
          :key="'asfasf' + index"
          :style="`overflow:hidden; height20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
        >
          {{ Math.round(colorObj.number) }}
        </div>
      </div>
    </div>
    <v-divider />
    <div
      style="width: 100; height: 20px;"
      class="d-flex align-center"
     
    >
      <div style="flex: 1"   >{{$t('statistics.decisonsPerc')}}</div>
      <div style="flex: 1" class="d-flex align-center"  v-if="heroDecisionsColors">
        <div
          v-for="(colorObj, index) in heroDecisionsColors"
          
          :key="'asfasf' + index"
                class="d-flex align-center justify-start text-black"

          :style="`overflow:hidden; height:20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
        >
          {{ Math.round(colorObj.number) }}
        </div>
      </div>
    </div>
    <v-divider />

    <div class="text-center">{{ compareString }}</div>

    <v-divider />
    <div class="d-flex align-center" style="height: 20px">
      <div style="flex: 1">{{$t('statistics.numberOfGames')}}</div>
      <div style="flex: 1">
        {{ groupSumary }}
      </div>
    </div>
    <v-divider />
    <div style="width: 100; height:20px;" class="d-flex align-center">
      <div style="flex: 1">{{$t('statistics.resultsPerc')}}</div>
      <div style="flex: 1" class="d-flex align-center">
        <div
          v-for="(colorObj, index) in $getFill(
            { array: groupArray, totalCount: groupSumary },
            false
          )"
                class="d-flex align-center justify-start text-black"

          :key="'asfasf' + index"
          :style="`overflow:hidden; height:20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
        >
          {{ Math.round(colorObj.number) }}
        </div>
      </div>
    </div>
    <v-divider />
    <div
      style="width: 100; height: 20px;"
      class="d-flex align-center"
     
    >
      <div style="flex: 1" >{{$t('statistics.decisonsPerc')}}</div>
      <div style="flex: 1" class="d-flex align-center"  v-if="groupDecisionColors">
        <div
          v-for="(colorObj, index) in groupDecisionColors"
          :key="'asfasf' + index"
          :style="`overflow:hidden; height: 20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
           class="text-black"
        >
          {{ Math.round(colorObj.number) }}
        </div>
      </div>
    </div>
    <v-divider />
    <div
      v-if="
        selectedOpening &&
        selectedOpening.children &&
        selectedOpening.children.length > 0
      "
    >
      <div style="width: 100$" class="d-flex align-center">
        <div style="flex: 1" class="d-flex align-center">
          <div style="flex: 1">{{$t('statistics.moveEval')}}</div>
          <div style="flex: 1">{{$t('statistics.numberOfMoveMade')}}</div>
        </div>
        <div style="flex: 1">
          <div style="flex: 1">{{$t('statistics.resultsPerc')}}</div>
        </div>
      </div>
      <div
        v-if="
          selectedOpening &&
          selectedOpening.children &&
          selectedOpening.children.length > 0
        "
        style="height: calc(20vh - 8px); overflow-y: auto; width: 100"

      >
        <div v-for="child in selectedOpening.children" :key="child.id" class="my-1">
          <v-divider />

          <div style="width: 100; cursor: pointer" class="d-flex align-center" @click="handleSelectOpening(child)">
            <div style="flex: 1" class="d-flex align-center">
              <div style="flex: 1">
                {{ $getMoveText(child) }}{{ `(${child.ev_text})` }}
              </div>
              <div style="flex: 1" class="d-flex align-center">
                <div style="flex: 1">
			
                  {{ getChildCountPercent(child, selectedOpening.children) }}%
			
                </div>
                <div style="flex: 1">{{ child.move_count_groups }}</div>
              </div>
            </div>
            <div style="flex: 1" class="d-flex align-center">
              <div
                v-for="(colorObj, index) in $getFill(
                  {
                    array: getChildResults(child),
                    totalCount: getChildrenResultGroupsCount(child),
                  },
                  false
                )"
                :key="'asfasf' + index"
                class="d-flex align-center justify-start text-black"
                :style="`overflow:hidden; height20px; background-color:${colorObj.color};width: ${colorObj.number}%`"
              >
                {{ Math.round(colorObj.number) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["selectedOpening", "heroName", "compareString","handleSelectOpening",],
  computed: {
    summaryArray() {
      if (!this.selectedOpening) return [];
      const { hm, results_hero } =
        this.selectedOpening;
      const arr = results_hero;
      const parsedArray = this.$parseArray(arr).map((i) => Number(i));
      return parsedArray;
    },
    heroSummary() {
      if (!this.selectedOpening) return null;
      const summaryArray = this.summaryArray;
      return summaryArray.reduce((acc, item) => (acc += Number(item)), 0);
    },
    groupArray() {
      const { results_groups } = this.selectedOpening;
      const parsedArray = this.$parseArray(results_groups).map((i) =>
        Number(i)
      );
      return parsedArray;
    },
    groupSumary() {
      if (!this.selectedOpening) return null;
      const summaryArray = this.groupArray;
      return summaryArray.reduce((acc, item) => (acc += Number(item)), 0);
    },
    heroDecisionsColors() {
      const { 
        // waste, move_count_hero_black, move_count_hero_white, hm, 
        children,  id, gooddec_hero } =  this.selectedOpening;

      // const moveCount =
      //   hm % 2 === 0 ? move_count_hero_black : move_count_hero_white;
      const greenCount = gooddec_hero 
      // children.find(item => item.pos_from_id === id)?.parentdecisions_hero
      // const redCount = this.$getMoveQualityFill(
      //   moveCount,
      //   this.$store.state.data.deviation,
      //   waste
      // );
      if (greenCount === undefined) return null;
      if (greenCount === null) return null;

      return [
        {
          color: "#80ff80",
          number: (greenCount || 0),
        },
        {
          color: "#ff8080",
          number: 100 - (greenCount || 0),
        },
      ];
    },
    groupDecisionColors() {
      // const { waste, move_count_groups } = this.selectedOpening;

      const { 
        // waste, move_count_hero_black, move_count_hero_white, hm, 
        children,  id, gooddec_groups } =  this.selectedOpening;
      // const moveCount =
      //   hm % 2 === 0 ? move_count_hero_black : move_count_hero_white;
      const greenCount = gooddec_groups
      //  children.find(item => item.pos_from_id === id)?.parentdecisions_groups
      // const redCount = this.$getMoveQualityFill(
      //   moveCount,
      //   this.$store.state.data.deviation,
      //   waste
      // );
      if (greenCount === null) return null;

      if (greenCount === undefined) return null;

      return [
        {
          color: "#80ff80",
          number: (greenCount || 0),
        },
        {
          color: "#ff8080",
          number: 100 - (greenCount || 0),
        },
      ];
    },
  },
  methods: {
    getChildCountPercent(child, children) {
      const total = children.reduce(
        (acc, item) => (acc += Number(item.move_count_groups || 0)),
        0
      );
      if (!total || !child.move_count_groups) return 0;
      return (100 * (child.move_count_groups / total)).toFixed(0);
    },
    getChildrenResultGroupsCount(child) {
      const { results_groups } = child;

      const reduced = results_groups?.reduce((acc, item) => {
        acc += Number(item)
        return acc
        }, 0);
      if(!reduced) return null
      return reduced
    },
    getChildResults(child) {
      if (!child) return [];
      return child.results_groups;
    },
  },
  watch: {
    selectedOpening: {
      deep: true,
      handler: function (val) {
       
      },
    },
  },
};
</script>

<style scoped></style>
