const sortFn = (key, asc) => (a, b) => {
  const aVal = a?.[key];
  const bVal = b?.[key];
  if (asc) {
    return aVal > bVal ? 1 : -1;
  }
  return aVal > bVal ? -1 : 1;
};


export default {
  getOpeningsVariants(state, getters, rootState) {
    return (t) => {

      const base = t ? { id: 1, text: t("common.allCapital") } : { id: 1, text: "All" }
      if (!state.mistakesOpenings) return [base];
    return [
      base,
      ...state.mistakesOpenings.reduce((acc, { eco_opening }) => {
        if (acc.findIndex((item) => item.text === eco_opening) > -1) return acc;
        acc.push({ id: acc.length + 2, text: eco_opening });
        return acc;
      }, []).sort(sortFn('text', true)),
    ];
    }
    
    
  },
  getMistakesOpenings(state, getters) {
    const {
      sideOptionsFilter,
      studiedOptionsFilter,
      watchListOptionsFilter,
      openingOptionsFilter,
      sortByOptionsFilter,
      mistakesOpenings,
      hmRange,
    } = state;

    const { getOpeningsVariants } = getters;
    const [min, max] = hmRange
    
    if (!mistakesOpenings) return [];
    const currentVariant = getOpeningsVariants().find(
      ({ id }) => id === openingOptionsFilter
    );


    const hmFiltered = mistakesOpenings.filter((item) => {
      const hm = item.hm 

      if (!hmRange || hmRange.length === 0) return true;

      return (hm >= 2 * (min - 1) && hm <= 2 * max);
    });

    const reduced = hmFiltered.reduce((acc, item) => {
      if (item.herowhite === 1 && sideOptionsFilter === 3) return acc;
      if (item.herowhite === 0 && sideOptionsFilter === 2) return acc;

      if (item.studied === 0 && studiedOptionsFilter === 2) return acc;
      if (!item.labels && watchListOptionsFilter === 2) return acc;
      if (currentVariant.id !== 1 && currentVariant.text !== item.eco_opening)
        return acc;
      acc.push(item);
      return acc;
    }, []);

    

    const sortWaste = (a, b) => {
      const aVal = a?.total_shortage / (a?.total_count || 1);
      const bVal = b?.total_shortage / (b?.total_count || 1);

      return aVal > bVal ? -1 : 1;
    };

    const sortByEye = (array) => {
      // const fixString = (str) => str.replace("{", "[").replace("}", "]");

      const groups = array.reduce(
        (acc, item) => {
          const labels = item.labels;
          if (!labels) {
            acc.undecided.push(item);
            return acc;
          }
     

          if (labels.indexOf(2) > -1) {
            acc.active.push(item);
          } else {
            acc.hidden.push(item);
          }
          return acc;
        },
        {
          active: [],
          undecided: [],
          hidden: [],
        }
      );

      const sortByMdegree = (array) => {
        const mdegreeGroups = array.reduce(
          (acc, item) => {
            const { mdegree } = item;
            if (mdegree === 0) {
              acc.green.push(item);
            }
            if (mdegree === 1) {
              acc.black.push(item);
            }
            if (mdegree === 2) {
              acc.red.push(item);
            }
            return acc;
          },
          {
            green: [],
            black: [],
            red: [],
          }
        );

        return [
          ...mdegreeGroups.red,
          ...mdegreeGroups.black,
          ...mdegreeGroups.green,
        ];
      };

      return [
        ...sortByMdegree(groups.active),
        ...sortByMdegree(groups.undecided),
        ...sortByMdegree(groups.hidden),
      ];
    };

    const sortMapper = {
      1: sortFn("total_shortage"),
      2: sortWaste,
      3: sortFn("unreasonable_count"),
      4: sortFn("hm"),
      5: sortFn("hm", true),
    };

    const presorted = reduced.sort(sortMapper[sortByOptionsFilter]);
    return sortByEye(presorted);
  },
  getCurrentHeroNicknames(state) {
    const heroNicknames = state.heroNicknames;
    return (hero_id) =>
      heroNicknames[hero_id]?.filter((nick) => nick.hero_id === hero_id).filter(nick=> nick.shown ===1);
  },
  getMistakesMinMax(state) {
    const mistakesOpenings = state.mistakesOpenings;

    if (!mistakesOpenings || mistakesOpenings.length === 0) return [];

    const reduced = mistakesOpenings.reduce(
      (acc, item) => {
        const hm = item.hm % 2 === 0 ? item.hm - 1 : item.hm;

        if (hm < acc[0]) {
          acc[0] = hm;
        }

        if (hm > acc[1]) {
          acc[1] = hm;
        }

        return acc;
      },
      [Infinity, -Infinity]
    );

    reduced[0] = (reduced[0] +1) / 2
    reduced[1] =  (reduced[1] +1) / 2

    return reduced;
  },
};
