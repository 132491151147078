<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :view-box.camel="`-${blockWidth / 2} -20 ${getWidth} ${getHeight}`"
    :width="getWidth + 120"
    :height="getHeight"
    id="svg-tree"
  >
    <g v-for="(column, xIndex) in items" :key="xIndex + 'col'">
      <text
        :x="getPaddingLeft(xIndex) - (blockWidth * 0.75) / 2"
        :y="getContainerHeight * i - getContainerHeight / 2"
        v-for="i in getTextCount"
        :key="i + 'grd'"
        fill="rgba(55,55,55,.3)"
        :style="{ fontSize: `${blockHeight}px` }"
      >
        {{ column[0] && column[0].movenum }}
      </text>
      <rect
        :x="getPaddingLeft(xIndex) - blockWidth * 0.5"
        :y="0"
        :width="blockWidth + (blockWidth * 0.75) / 2"
        :height="getHeight"
        :fill="getColumnFill(column)"
      />

      <g
        v-for="(row, yIndex) in column"
        :key="xIndex + 'col' + yIndex + 'row' + row.nodeid"
      >
        <line-item
          v-if="xIndex !== 0"
          :nodeparent="row.nodeparent"
          :topPadding="getPaddingTop(yIndex, column, items, xIndex)"
          :leftPadding="getPaddingLeft(xIndex)"
          :blockWidth="blockWidth * 0.55"
          :blockHeight="blockHeight"
          :parentTopCoords="getParentCoords(yIndex, column, items, xIndex)"
          :item="row"
          :rootColor="rootColor"
          :simpleArrows="simple"
          :getBg="getBg"
        />
        <block-item
          :isAnchor="yIndex < 2"
          :blockWidth="blockWidth * 0.75"
          :blockHeight="blockHeight"
          :topPadding="getPaddingTop(yIndex, column, items, xIndex)"
          :leftPadding="getPaddingLeft(xIndex)"
          :item="updatedItem(row)"
          :nodeparent="row.nodeparent"
          :onClick="loadChildren"
          :handleSelectOpening="handleSelectOpening"
          :setTooltip="setTooltip"
          :isSelected="selectedOpening?.nodethis === row.nodethis"
          :mode="mode"
          :rootColor="rootColor"
          :key="row.nodethis"
          :simpleBlock="simple"
          :getBg="getBg"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import BlockItem from './BlockItem.vue';
import LineItem from './LineItem.vue';

export default {
  props: [
    'movesModel',
    'mode',
    'selectedOpening',
    'items',
    'getPaddingTop',
    'loadChildren',
    'blockWidth',
    'blockHeight',
    'getParentCoords',
    'setTooltip',
    'handleSelectOpening',
    'handleArrowPress',
    'rootColor',
    'simple',
    'getBg',
    'size',
    'mistakeFens'
  ],
  data: () => ({
    // size: 900,
  }),
  components: {
    BlockItem,
    LineItem,
  },
  computed: {
    getContainerHeight() {
      const vh = this.$store?.state?.sizes?.vh || 0;

      const actHeight = vh * 0.9 - 70;
      return actHeight;
    },
    getWidth() {
      const { blockWidth, items, size } = this;
      const itemsWidth = items.length * (blockWidth + blockWidth * 0.5);
      return itemsWidth > size ? itemsWidth : size;
    },
    getHeight() {
      const { items, blockHeight } = this;
      const actHeight = this.getContainerHeight * this.simple ? 0.1 : 1;

      if (!items || items.length === 0 || !items[0][0]) return 0;
      const key = 'visibleChildren';

      const res =
        items[0][0][key] * blockHeight + blockHeight * (this.simple ? 0 : 4) >
        actHeight
          ? items[0][0][key] * blockHeight + blockHeight * (this.simple ? 0 : 4)
          : actHeight;

      return res;
    },
    getTextCount() {
      const actHeight = this.getContainerHeight;
      const colHeight = this.getHeight;

      return Math.floor(colHeight / actHeight);
    },
  },
  methods: {
    updatedItem(item) {
      if(!this.mistakeFens) {
        return item
      } else {
          const correspondingMistake = this.mistakeFens[item.pos_to_id]
          if(correspondingMistake) {
            return {...item, correspondingMistake}
          }
          return item     
      }
      
    },
    getColumnFill(items) {
      if (!items || !items.length === 0) return 'rgba(255,255,255,.1)';

      return items[0]?.hm % 2 === 0
        ? 'rgba(255,255,255,.1)'
        : 'rgba(50,50,50,.1)';
    },
    getPaddingLeft(index) {
      const { blockWidth } = this;
      return (blockWidth + blockWidth * 0.3) * index;
    },
    handleKeydown(event) {
      switch (event.keyCode) {
        case 37:
          event.preventDefault();
          this.handleArrowPress('left');
          break;
        case 39:
          event.preventDefault();
          this.handleArrowPress('right');
          break;
        case 38:
          event.preventDefault();
          this.handleArrowPress('up');
          break;
        case 40:
          event.preventDefault();
          this.handleArrowPress('down');
          break;
      }
    },
  },
  beforeMount() {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style scoped></style>
