import isEqual from 'lodash/isEqual'

function orchestrator() {
    this.dict = {}

    this.findOccurance = (params) => {
        const dict = this.dict

        for(const key of Object.keys(dict)) {
            if(isEqual(JSON.parse(key), params)) {
                return {target: dict[key], key }
            }
        }

        return null
    }

    this.register = (params) => {
        const sig = this.findOccurance(params)
        if(sig) {
            sig.target.abort()
        }

        const controller = new AbortController();

        this.dict[JSON.stringify(params)] = controller
        return controller
    }

    this.kill = (params) => {
        const sig = this.findOccurance(params)
        if(sig) {
            delete this.dict[sig.key]
        }
    }

    this.killAll = () => new Promise((res) => {
        for(const sig of Object.values(this.dict)) {
            sig.abort()
        }

        this.dict = {}

        res()
    })

}

export default new orchestrator()