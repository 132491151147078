<template>
  <button
    :disabled="disabled"
    :class="[
      'custom-button',
      rounded ? `rounded-${rounded}` : '',
      block ? 'w-100' : '',
      { 'custom-disabled': disabled }
    ]"
    :style="{ height: customHeight, backgroundColor: backgroundColor, color: textColor }"
    @click="handleClick"
  >
    
   <div class="button-content" >
      <google-icon v-if="googleIcon" class="icon" />
      <span class="label">{{ label }}</span>
    </div>
    
  </button>
</template>

<script>
import googleIcon from '../../assets/googleLogo.vue';

export default {
  name: 'CustomButton',
    components: {
    googleIcon,
  },
  props: { 
    label: {
      type: String,
      default: 'Button',
    }, 
    backgroundColor: {
      type: String,
      default: '#007bff',  
    }, 
    textColor: {
      type: String,
      default: 'white',
    }, 
    customHeight: {
      type: String,
      default: 'auto',
    }, 
    googleIcon: {
      type: Boolean,
      default: false,
    }, 
    iconClass: {
      type: String,
      default: '',
    }, 
    rounded: {
      type: String,
      default: 'none',
    }, 
    block: {
      type: Boolean,
      default: false,
    }, 
    disabled: {
      type: Boolean,
      default: false,
    }, 
    onClick: {
      type: Function,
      default: () => {},
    }, 
    size: {
      type: String,
      default: 'medium',
    },
  },
  methods: { 
    handleClick(event) {
      if (!this.disabled) {
        this.onClick(event);
      }
    },
  },
};
</script>

<style scoped>
.custom-button {
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.custom-disabled {
  background-color: #2B2C31 !important;  
  color: #9a9a9a !important; 
  cursor: default;
  opacity: 0.4;
}

.rounded-none {
  border-radius: 0;
}
.rounded-small {
  border-radius: 4px;
}
.rounded-medium {
  border-radius: 8px;
}
.rounded-large {
  border-radius: 16px;
}

.w-100 {
  width: 100%;
}

.icon {
  margin-right: 2px;  
}
 
.custom-button.size-small {
  font-size: 14px;
  padding: 0.25rem 0.5rem;
}

.custom-button.size-medium {
  font-size: 16px;
  padding: 0.5rem 1rem;
}

.custom-button.size-large {
  font-size: 18px;
  padding: 0.75rem 1.5rem;
}

.button-content {
  display: flex;
  align-items: center;  
  justify-content: center;  
}
.icon {
  margin-right: 0.5rem; 
}

.label { 
    color: var(--text-secondary, #FFFFFF);
 
    font-family: "Source Sans 3";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;  
}
</style>
