<template>
  <g>
    <path
      fill="none"
      :style="{ strokeWidth: getThickness }"
      :d="pathString"
      :stroke="getColor"
    />
    <text
      :x="leftPadding - blockWidth + borderRadius * 2 - 4"
      :y="topPadding + borderRadius * 3 - 4"
      font-size="14px"
        fill="white"
      v-if="!simpleArrows"
    >
      {{ `${getMoveText}` }}
    </text>
    <!-- <text
      :x="leftPadding - blockWidth + borderRadius * 5"
      :y="topPadding + borderRadius * 3"
         font-size="14px"
    >
      {{
        `${item.movenum} ${item.move_text}`
      }}
    </text> -->

    <text
      :x="leftPadding - blockWidth + borderRadius * 2 - 4"
      :y="topPadding + borderRadius * 3 + borderRadius * 3"
      font-size="14px"
      fill="white"
      v-if="!simpleArrows"
    >
      {{ item.move_count_hero || '' }}
    </text>
    <!-- :fill="getColor" -->
    <g v-if="!simpleArrows">
      <polygon
        :fill="getColor"
        :style="{ strokeWidth: item.tr_ar_thickness }"
        :points="getArrowString"
        v-if="item.tr_ar_head === 'good'"
      />

      <circle
        :fill="'white'"
        :style="{ strokeWidth: item.tr_ar_thickness }"
        :stroke="getColor"
        :cx="leftPadding - borderRadius * 2.5"
        :cy="topPadding + blockHeight / 2"
        :r="borderRadius / 1.5"
        v-if="item.tr_ar_head === 'passably'"
      />
      <g v-if="item.tr_ar_head === 'bad'">
        <!-- <path fill="none" :style="{strokeWidth: getThickness}" :d="`M${leftPadding - borderRadius*2},${topPadding + (blockHeight/2)} h${borderRadius}`" :stroke="getColor"/> -->
        <path
          fill="none"
          :style="{ strokeWidth: item.tr_ar_thickness }"
          :d="`M${leftPadding - borderRadius * 3},${
            topPadding + blockHeight / 2 - borderRadius
          } v${borderRadius * 2}`"
          :stroke="getColor"
        />
        <path
          fill="none"
          :style="{ strokeWidth: item.tr_ar_thickness }"
          :d="`M${leftPadding - borderRadius * 2},${
            topPadding + blockHeight / 2 - borderRadius
          } v${borderRadius * 2}`"
          :stroke="getColor"
        />
      </g>
    </g>
  </g>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: [
    'nodeparent',
    'topPadding',
    'leftPadding',
    'blockWidth',
    'blockHeight',
    'parentTopCoords',
    'item',
    'rootColor',
    'simpleArrows',
    'getBg'
  ],
  data: () => ({
    parentCoords: null,
    borderRadius: 8,
  }),
  computed: {
    ...mapState('ui', ['userPreferences']),
    getMoveText() {
      const {lang_pieces} = this.userPreferences
      const move_lang = this.item?.move_lang || ''
      if(move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces]
      }
      return move_lang[0] || ''
    },
    fixedHm() {
      return this.item?.hm;
    },
    getThickness() {
      if (this.simpleArrows) {
        return 2;
      }
      return this.item.tr_arrow_thickness;
      // return Math.ceil(this.item.b_arrow_thickness / 5) || 2
    },
    getColor() {

      if(this.getBg) {

      return this.getBg(this.item.nodethis)
      }

      if (this.simpleArrows) {
        return 'black';
      }
      return this.item.b_arrow_color || 'black';
    },

    getArrowString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      return `
      ${leftPadding - borderRadius * 3},${topPadding + halfBlock}
      ${leftPadding - borderRadius * 4},${topPadding + halfBlock / 2 + 8} 
      ${leftPadding - borderRadius - 2},${topPadding + halfBlock}
       ${leftPadding - borderRadius * 4},${topPadding + halfBlock * 1.5 - 8} `;
    },
    getSquareString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      const halfBorder = borderRadius / 2;
      return `
      
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock - halfBorder} 
      ${leftPadding - halfBorder * 4},${topPadding + halfBlock}
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock + halfBorder} 
      ${leftPadding - halfBorder * 2},${topPadding + halfBlock}
      `;
    },
    pathString() {
      const {
        getParentX,
        fixedParentTopPosition,
        blockWidth,
        parentTopCoords,
        borderRadius,
        topPadding,
        blockHeight,
        getTopPosition,
      } = this;

      if (parentTopCoords === getTopPosition)
        return `M${
          getParentX - (this.simpleArrows ? borderRadius : 0)
        },${fixedParentTopPosition} h${
          this.simpleArrows ? blockWidth : blockWidth - borderRadius * 3
        } `;
      if (this.simpleArrows) {
        return `M${
          getParentX - (this.simpleArrows ? borderRadius : 0)
        },${fixedParentTopPosition} 
    h${blockWidth / 2 - 4}
    a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
    v${topPadding - fixedParentTopPosition + blockHeight / 2 - borderRadius * 2}
    a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
    h${blockWidth / 4 + borderRadius}
    `;
      }

      return `M${getParentX + borderRadius / 2 - 4},${fixedParentTopPosition} 

    a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
    v${topPadding - fixedParentTopPosition + blockHeight / 2 - borderRadius * 2}
    a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
    h${blockWidth / 4 + borderRadius}
    `;
    },
    fixedParentTopPosition() {
      const { parentTopCoords, blockHeight } = this;

      return parentTopCoords + blockHeight / 2;
    },
    getParentX() {
      const { leftPadding, blockWidth } = this;
      return leftPadding - blockWidth < 0 ? 0 : leftPadding - blockWidth;
    },
    halfXPoint() {
      const { parentCoords, leftPadding, blockWidth } = this;

      if (!parentCoords) return 0;

      return leftPadding - 8 - (parentCoords[0] - 8) / 2;
    },
    getTopPosition() {
      const { topPadding, parentCoords, blockHeight } = this;
      return this.topPadding;
      //   if(!parentCoords) return topPadding
      //   const fixedTopPosition = parentCoords[1] - (blockHeight / 2)
      //   return fixedTopPosition > topPadding ? fixedTopPosition : topPadding
    },
  },
  async mounted() {
    await this.$nextTick();
    const parentItem = document.getElementById(this.nodeparent);
    const coords = parentItem?.dataset.cornerCoords;

    this.parentCoords = coords ? JSON.parse(coords) : null;
  },
};
</script>

<style scoped></style>
