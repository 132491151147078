import { createI18n } from 'vue-i18n';

export const L10n = {
  "en": {
    "langid": 1,
    "login": {
      "signin": "Sign in",
      "signup": "Register",
      "remember": "Remember me",
      "main": "Login to continue",
      "name": "Name",
      "email": "Email", 
      "password": "Password",
      "forgotPassword": "Forgot password?",
      "accept": "Login",
      "payment": "Join membership",
      "confirmPassword": "Confirm password",
      "phone": "Phone",
      "longPassword": "The password length must be at least 8 characters",
      "passwordComplexity": "The password must match uppercase and lowercase letters, numbers and special characters",
      "passwordsMatch": "The passwords entered in both passwords fields should match",
      "confirmRegister": "Complete registration",
      "getName": "How should we address you?",

    },
    "loginPage": {
      "signin": "Log in", 
      "signup": "Sign Up",
      "enterEmail": "Enter your email",
      "enterPassword": "Enter your password",
      "confirmRegister": "Sign up",
      "dontHaveAccount":"Don’t have an account?",
      "logInViaGoogle":"Log in with Google",  
    },
    "register": {
      "signup": "Sign Up",
      "signupGoogle": "Sign up with Google",
      "title": "Sign up",
      "enterEmail": "Email",
      "enterName": "Your name",
      "enterPhone": "Phone number (optional)",
      "enterPassword": "Create a password",
      "confirmPassword": "Confirm your password",
      "alreadyHaveAccout":"Already have an account?",
      "tip":"Password must be at least 8 characters long, include mixed case letters, numbers, and a special character.",
      "agreement":"I give my consent to the processing of my personal data and agree to the ", 
      "pivacy":"Privacy policy",
      "terms":"Terms of use", 
    },
    "recovery":{
      "title": "Forgot password?",
      "enterEmail": "Enter your email",
      "submit":"Send request",
      "returnTo":"Remembered password?",
      "login":"Log in",
    },
    "rules":{
      'confirmpwd':"Please confirm your password.",
      'dontMatch':"Passwords do not match.",
      'enterEmail':"Enter your email.",
      'enterPwd':"Enter your password.",
      'enterName':"Enter your name.",
      'fieldRequired':"This field is required.",
      'emailMin':"Email must be at least",
      'pwdMin':"Password must be at least ",
      'nameMin':"Name must be at least ",
      'phoneMin':'Phone number must be at least',
      'phoneMax':"Phone number must be no more than",
      'phoneInvalid':"Phone number contains invalid characters",
      'valueMin':"Value must be at least",
      'minPostfix':'characters.',
      'pwdUpper':'Password must contain at least one uppercase letter',
      'pwdNumber':'Password must contain at least one number',
    },
    "accountMenu": {
      "settings": "Settings",
      "preferences": "Preferences",
      "logoutall": "Log out of all devices",
      "logout": "Log out"
    },
    "common": {
      "aboutCompany": "About the company",
      "footer": "ScienceChess 2024",
      "fieldRequired": "This field is required",
      "ok": "OK",
      "cancel": "Cancel",
      "reset": "Reset",
      "dateFrom": "Start date",
      "dateTo": "End date",
      "all": "all",
      "allCapital": "All"
    },
    "card": {
      "heroname": "Person name",
      "description": "Description"
    },
    "cardSummary": {
      "accounts": "Accounts",
      "editAccount": "Edit account",
      "addAccount": "Add an account",
      "loadGames": "Load games",
      "uploadButton": "Upload",
      "name": "Name",
      "gamesLoaded": "Games loaded",
      "noGames": "No data",
      "analysisQuery": "Analyze",
      "debutAnalysis": "Opening analyzed",
      "mittelspielAnalysis": "Middlegame analyzed",
      "endspielAnalysis": "Endgame analyzed"
    },
    "playground": {
      "site": "Site",
      "pg1": "Correspondence games",
      "pg2": "OTB",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Classical",
      "rapid": "Rapid",
      "blitz": "Blitz",
      "bullet": "Bullet"
    },
    "opening1": {
      "openingsOpen": "Open Games",
      "openingsSemiOpen": "Semi-Open Games",
      "openingsClosed": "Closed Games",
      "openingsSemiClosed": "Semi-Closed Games",
      "openingsIndian": "Indian Defences",
      "openingsFlank": "Flank and irregular openings",
      "headerBeforeHeroname": "Opening research: ",
      "headerAfterHeroname": "",
      "weak": "MISTAKES SUMMARY",
      "searchStart": "Start",
      "searchStartHint": "Initial position",
      "search": "Show this",
      "searchHint": "Show tree from position given by FEN",
      "searchAfter": "Mistakes after position",
      "searchAfterHint": "List mistakes in games after position given by FEN",
      "searchFieldPrompt": "Paste FEN here",
      "side": "Side",
      "sideWhite": "White",
      "sideBlack": "Black",
      "studied": "Studied",
      "studiedYes": "Yes",
      "studiedNo": "No",
      "setStudied": "Mark as studied",
      "watchlist": "Watchlist",
      "watchlistListed": "Listed",
      "moveNumHeader": "Moves range",
      "moveNumFrom": "Min",
      "moveNumTo": "Max",
      "opening": "Opening",
      "sortBy": "Sort by",
      "sortByTotalShortage": "Total shortage at position",
      "sortByAverageShortage": "Average shortage at position",
      "sortByNumMistakes": "Number of mistakes made",
      "sortByMoveDesc": "Move number descending",
      "sortByMoveAsc": "Move number ascending",
      "compareWithBeforeHeroname": "Compare ",
      "compareWithAfterHeroname": " with: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "Most common ECO in games where this position has arose",
      "mistakesdEval": "ΔEval",
      "mistakesdEvalHint": "Average shortage of evaluation in pawns value",
      "mistakesNum": "Num",
      "mistakesNumHint": "Number of games (number of mistakes made)",
      "mistakesTotalShortage": "Total shortage",
      "mistakesTotalShortageHint": "Total shortage due to mistakes",
      "mistakesStudied": "Studied",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Fault again",
      "mistakesReFailHint": "Mistake was made again after marking as studied",
      "barMode": "Bars",
      "barModeResults": "game results",
      "barModeDecisions": "decisions",
      "displayMoves": "Display moves",
      "displayMovesBestOnly": "regular list",
      "displayMovesExtended": "extended list",
      "cTO": "Training offers",
      "tOIgnore": "don't show",
      "tOBest": "single best",
      "tOAll": "all",
      "cPractice": "Encounters",
      "practiceIgnore": "don't show",
      "practiceHero": "hero's turn",
      "practiceAll": "all",
      "cEmphasis": "Additional",
      "emphasisIgnore": "don't show",
      "hintemphasisIgnore": "Disable showing the additional moves",
      "emphasis3": "single best",
      "hintemphasis3": "Exactly one move from among the strongest",
      "emphasis2": "good",
      "hintemphasis2": "Quality moves",
      "emphasis1": "extended list",
      "hintemphasis1": "All theoretical moves",
      "flipBoard": "Flip board",
      "switchEngine": "Stockfish"
    },
    "opening2": {
      "leading": "Repeat moves",
      "testing": "Check of knowledge",
      "pageLearn": "Learn",
      "pageTest": "Test",
      "buttonStart": "Start",
      "buttonAbort": "Stop",
      "buttonReset": "Reset",
      "labelCorrect": "Correct",
      "labelWrong": "Wrong"
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Comparison selector",
      "aveloRange": "Rating range",
      "timeControl": "Time control",
      "rivals": "Rivals skill comparison",
      "pg1": "Correspondence games",
      "pg2": "OTB",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "strong amateurs",
      "avelo2": "masters",
      "avelo3": "top",
      "tc2_2": "classical",
      "tc2_3": "rapid",
      "tc2_4": "blitz",
      "tc3_3": "classical, rapid",
      "tc3_4": "blitz",
      "ed4": "rivals are equal",
      "ed345": "rivals are comparable",
      "ed23456": "any",
      "headered4": " (equal)",
      "headered345": " (comparable)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Number of games",
      "resultsPerc": "Results, %",
      "decisonsPerc": "Decisions, %",
      "moveEval": "Move (eval)",
      "numberOfMoveMade": "Num"
    },
    "settings": {
      "displayedName": "Displayed name"
    },
    "preferences": {
      "piecesDisplayedAs": "Pieces displayed as",
      "shortNotation": "Use short notation",
      "flipWhenBlack": "Flip board when black moves at initial position",
      "goodMovesBound": "'Good' moves bound",
      "mistakesSensivity": "Mistakes sensitivity"
    },
    "moveMarking": {
      "lbHero": "Training",
      "hbydefault": "by default",
      "hchoice": "choice",
      "hignore": "ignore",
      "lbOpp": "Training",
      "obydefault": "by default",
      "ochoice": "consider",
      "oignore": "ignore"
    },
    "pieces": {
      "N": "N",
      "B": "B",
      "R": "R",
      "Q": "Q",
      "K": "K"
    }
  },
  "ru": {
    "langid": 4,
    "login": {
      "signin": "Вход",
      "signup": "Регистрация",
      "remember": "Запомнить меня",
      "main": "Войдите, чтобы продолжить",
      "name": "Имя",
      "email": "Адрес электронной почты",
      "password": "Пароль",
      "forgotPassword": "Забыли пароль?",
      "accept": "Войти",
      "payment": "Оформите подписку",
      "confirmPassword": "Подтверждение пароля",
      "phone": "Телефон",
      "longPassword": "Длина пароля должна быть не менее 8 символов",
      "passwordComplexity": "Пароль должен содержать строчные и заглавные буквы, цифры и спецсимволы",
      "passwordsMatch": "Пароли должны совпадать",
      "confirmRegister": "Зарегистрироваться",
      "getName": "Как можно к Вам обращаться?"
    },
    "accountMenu": {
      "settings": "Настройки",
      "preferences": "Установки",
      "logoutall": "Выйти со всех устройств",
      "logout": "Выйти"
    },
    "common": {
      "aboutCompany": "О компании",
      "footer": "ScienceChess 2024",
      "fieldRequired": "Поле обязательно для заполнения",
      "ok": "OK",
      "cancel": "Отменить",
      "reset": "Сбросить",
      "dateFrom": "Начальная дата",
      "dateTo": "Конечная дата",
      "all": "все",
      "allCapital": "Все"
    },
    "card": {
      "accounts": "Имя героя",
      "description": "Описание"
    },
    "cardSummary": {
      "accounts": "Аккаунты",
      "editAccount": "Редактировать аккаунт",
      "addAccount": "Добавить аккаунт",
      "loadGames": "Загрузить партии",
      "uploadButton": "Загрузить",
      "name": "Имя",
      "gamesLoaded": "Загружено партий",
      "noGames": "Нет данных",
      "analysisQuery": "Анализировать",
      "debutAnalysis": "Анализ дебюта",
      "mittelspielAnalysis": "Анализ миттельшпиля",
      "endspielAnalysis": "Анализ эндшпиля"
    },
    "playground": {
      "site": "Сайт",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Классика",
      "rapid": "Быстрые",
      "blitz": "Блиц",
      "bullet": "Пуля"
    },
    "opening1": {
      "openingsOpen": "Открытые дебюты",
      "openingsSemiOpen": "Полуоткрытые дебюты",
      "openingsClosed": "Закрытые дебюты",
      "openingsSemiClosed": "Полузакрытые дебюты",
      "openingsIndian": "Индийские защиты",
      "openingsFlank": "Фланговые дебюты и неправильные начала",
      "headerBeforeHeroname": "Исследование дебюта: ",
      "headerAfterHeroname": "",
      "weak": "ОБЗОР ОШИБОК",
      "searchStart": "Старт",
      "searchStartHint": "Начальная позиция",
      "search": "Показать",
      "searchHint": "Отобразить варианты, начиная с заданной через FEN позиции",
      "searchAfter": "Ошибки в партиях с позицией",
      "searchAfterHint": "Перечень ошибок, которые совершались в тех партиях, где встречалась заданная через FEN позиция",
      "searchFieldPrompt": "Вставьте FEN сюда",
      "side": "Сторона",
      "sideWhite": "Белые",
      "sideBlack": "Чёрные",
      "studied": "Изучено",
      "studiedYes": "Да",
      "studiedNo": "Нет",
      "setStudied": "Отметить как изученное",
      "watchlist": "Наблюд.",
      "watchlistListed": "В списке",
      "moveNumHeader": "Диапазон ходов",
      "moveNumFrom": "Мин",
      "moveNumTo": "Макс",
      "opening": "Дебют",
      "sortBy": "Сортировка",
      "sortByTotalShortage": "Сумма потерь в позиции",
      "sortByAverageShortage": "Средние потери в позиции",
      "sortByNumMistakes": "Количество совершённых ошибок",
      "sortByMoveDesc": "Номер хода по убыванию",
      "sortByMoveAsc": "Номер хода по возрастанию",
      "compareWithBeforeHeroname": "Сравнить ",
      "compareWithAfterHeroname": " против: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "ECO большинства партий, где встречалась эта позиция",
      "mistakesdEval": "ΔОц",
      "mistakesdEvalHint": "Средние потери оценки позиции в количестве пешек",
      "mistakesNum": "Кол",
      "mistakesNumHint": "Количество партий (количество совершённых ошибок)",
      "mistakesTotalShortage": "Общий ущерб",
      "mistakesTotalShortageHint": "Общая сумма потерь из-за совершённых в позиции ошибок",
      "mistakesStudied": "Изучено",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Вновь ошибки",
      "mistakesReFailHint": "Ошибка совершается снова после того, как позиция отмечена изученной",
      "barMode": "Гистограммы",
      "barModeResults": "результаты партий",
      "barModeDecisions": "принятые решения",
      "displayMoves": "Показ ходов",
      "displayMovesBestOnly": "обычный список",
      "displayMovesExtended": "расширенный список",
      "cTO": "Предложения по обучению",
      "tOIgnore": "не отображать",
      "tOBest": "один лучший",
      "tOAll": "все",
      "cPractice": "Встречалось",
      "practiceIgnore": "не отображать",
      "practiceHero": "при ходе героя",
      "practiceAll": "все",
      "cEmphasis": "Дополнительные",
      "emphasisIgnore": "не отображать",
      "hintemphasisIgnore": "Отключить отображение дополнительных ходов",
      "emphasis3": "один лучший",
      "hintemphasis3": "Ровно один ход из числа сильнейших",
      "emphasis2": "хорошие",
      "hintemphasis2": "Качественные ходы",
      "emphasis1": "расширенный список",
      "hintemphasis1": "Все теоретические ходы",
      "flipBoard": "Повернуть доску",
      "switchEngine": "Stockfish"
    },
    "opening2": {
      "leading": "Повторение ходов",
      "testing": "Проверка знаний",
      "pageLearn": "Обучение",
      "pageTest": "Тест",
      "buttonStart": "Начать",
      "buttonAbort": "Остановить",
      "buttonReset": "Обнулить",
      "labelCorrect": "Правильно",
      "labelWrong": "Ошибочно"
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Параметры сравнения",
      "aveloRange": "Рейтинг",
      "timeControl": "Контроль времени",
      "rivals": "Уровень соперников между собой",
      "pg1": "Заочные партии",
      "pg2": "Игра вживую",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "крепкие середняки",
      "avelo2": "мастера",
      "avelo3": "топ",
      "tc2_2": "классика",
      "tc2_3": "быстрые",
      "tc2_4": "блиц",
      "tc3_3": "классика, быстрые",
      "tc3_4": "блиц",
      "ed4": "соперники равны",
      "ed345": "соперники сопоставимы",
      "ed23456": "любые",
      "headered4": " (равные)",
      "headered345": " (сопоставимые)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Количество партий:",
      "resultsPerc": "Результаты, %",
      "decisonsPerc": "Решения, %",
      "moveEval": "Ход (оценка)",
      "numberOfMoveMade": "Кол."
    },
    "settings": {
      "displayedName": "Отображаемое имя"
    },
    "preferences": {
      "piecesDisplayedAs": "Отображение фигур",
      "shortNotation": "Использовать короткую нотацию",
      "flipWhenBlack": "Повернуть доску, когда в исходной позиции ход чёрных",
      "goodMovesBound": "Граница 'хороших' ходов",
      "mistakesSensivity": "Чувствительность к ошибкам"
    },
    "moveMarking": {
      "lbhero": "Тренировка",
      "hbydefault": "по умолч.",
      "hchoice": "выбрать",
      "hignore": "игнор.",
      "lbOpp": "Тренировка",
      "obydefault": "по умолч.",
      "ochoice": "учитывать",
      "oignore": "игнор."
    },
    "pieces": {
      "N": "К",
      "B": "С",
      "R": "Л",
      "Q": "Ф",
      "K": "Кр"
    }
  },
  "zh": {
    "langid": 5,
    "login": {
      "signin": "登入",
      "signup": "登记",
      "remember": "记住我",
      "main": "登入以继续",
      "name": "账户名",
      "email": "电子邮箱",
      "password": "密码",
      "forgotPassword": "忘记密码？",
      "accept": "登录",
      "payment": "订阅 ",
      "confirmPassword": "请再次输入您的密码",
      "phone": "电话号码",
      "longPassword": "密码长度不得少于8个字符",
      "passwordComplexity": "密码必须包含大小写字母、数字、特殊字符",
      "passwordsMatch": "输入的密码不匹配。请重新输入。",
      "confirmRegister": "加入学习国际象棋",
      "getName": "您怎么称呼？"
    },
    "accountMenu": {
      "settings": "设置",
      "preferences": "偏好",
      "logoutall": "登出所有设备",
      "logout": "登出"
    },
    "common": {
      "aboutCompany": "关于公司",
      "footer": "ScienceChess 2024",
      "fieldRequired": "栏位为必填资讯",
      "ok": "确定",
      "cancel": "取消",
      "reset": "重置",
      "dateFrom": "开始日期",
      "dateTo": "结束日期",
      "all": "都",
      "allCapital": "都"
    },
    "card": {
      "accounts": "人名",
      "description": "描述"
    },
    "cardSummary": {
      "accounts": "账户列表",
      "editAccount": "编辑帐户",
      "addAccount": "添加个帐户",
      "loadGames": "游戏上传",
      "uploadButton": "上传",
      "name": "账户名",
      "gamesLoaded": "上传游戏数量",
      "noGames": "没有数据",
      "analysisQuery": "分析",
      "debutAnalysis": "研究过的开局",
      "mittelspielAnalysis": "研究过的中局",
      "endspielAnalysis": "研究过的殘局"
    },
    "playground": {
      "site": "地点",
      "pg1": "通信游戏",
      "pg2": "现场游戏",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "常规赛",
      "rapid": "快棋",
      "blitz": "超快棋",
      "bullet": "子弹"
    },
    "opening1": {
      "openingsOpen": "开放性开局",
      "openingsSemiOpen": "半开放性开局",
      "openingsClosed": "封闭性开局",
      "openingsSemiClosed": "半封闭性开局",
      "openingsIndian": "印度体系",
      "openingsFlank": "侧翼和非正规开局",
      "headerBeforeHeroname": "",
      "headerAfterHeroname": "的开局研究",
      "weak": "错误报告",
      "searchStart": "开始",
      "searchStartHint": "开始位置",
      "search": "显示局面",
      "searchHint": "从 FEN 给出的局面显示树",
      "searchAfter": "局面后的错误",
      "searchAfterHint": "列举从 FEN 给出的局面出现后游戏中的错误",
      "searchFieldPrompt": "将 FEN 粘贴到此处",
      "side": "轮走棋方",
      "sideWhite": "白方走棋",
      "sideBlack": "黑方走棋",
      "studied": "研究过",
      "studiedYes": "是",
      "studiedNo": "不",
      "setStudied": "标记为研究过",
      "watchlist": "监视列表",
      "watchlistListed": "上市",
      "moveNumHeader": "回合数范围",
      "moveNumFrom": "从",
      "moveNumTo": "到",
      "opening": "開局名称",
      "sortBy": "排序方式",
      "sortByTotalShortage": "总短缺",
      "sortByAverageShortage": "平均短缺",
      "sortByNumMistakes": "犯错误的数量",
      "sortByMoveDesc": "回合数降序",
      "sortByMoveAsc": "回合数升序",
      "compareWithBeforeHeroname": "比拟 ",
      "compareWithAfterHeroname": " 与: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "具有此局面的游戏中最常见的 ECO",
      "mistakesdEval": "Δ评估",
      "mistakesdEvalHint": "兵价值平均短缺",
      "mistakesNum": "数量",
      "mistakesNumHint": "游戏数量(犯错误的数量)",
      "mistakesTotalShortage": "总短缺",
      "mistakesTotalShortageHint": "兵价值总短缺",
      "mistakesStudied": "已研究",
      "mistakesStudiedHint": "",
      "mistakesReFail": "又犯错了",
      "mistakesReFailHint": "标记研究局面后又犯了错误",
      "barMode": "图表栏状态",
      "barModeResults": "游戏结果",
      "barModeDecisions": "决策",
      "displayMoves": "显示移动状态",
      "displayMovesBestOnly": "常规列表",
      "displayMovesExtended": "扩展列表",
      "cTO": "磨练技能建议",
      "tOIgnore": "不显示",
      "tOBest": "一个最佳",
      "tOAll": "都",
      "cPractice": "遇到了",
      "practiceIgnore": "不显示",
      "practiceHero": "轮到这个人",
      "practiceAll": "都",
      "cEmphasis": "额外移动",
      "emphasisIgnore": "不显示",
      "hintemphasisIgnore": "禁用显示额外移动",
      "emphasis3": "一个最佳",
      "hintemphasis3": "显示唯一的最佳移动",
      "emphasis2": "常规列表",
      "hintemphasis2": "显示所有好移动",
      "emphasis1": "扩展列表",
      "hintemphasis1": "所有理论步",
      "flipBoard": "翻转棋盘",
      "switchEngine": "在线计算"
    },
    "opening2": {
      "leading": "重复移动们",
      "testing": "知识检查",
      "pageLearn": "学习",
      "pageTest": "测试",
      "buttonStart": "开始",
      "buttonAbort": "停止",
      "buttonReset": "重置",
      "labelCorrect": "正确",
      "labelWrong": "错误"
    },
    "compare": {
      "separator1": "、",
      "separator2": "，",
      "separator3": "，",
      "title": "比较选择",
      "aveloRange": "评级范围",
      "timeControl": "时限",
      "rivals": "对手技能对比",
      "pg1": "通信游戏",
      "pg2": "现场游戏",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "高级业余",
      "avelo2": "大师",
      "avelo3": "最好",
      "tc2_2": "常规赛",
      "tc2_3": "快棋",
      "tc2_4": "超快棋",
      "tc3_3": "常规赛、快棋",
      "tc3_4": "超快棋",
      "ed4": "对手是平等的",
      "ed345": "对手水平相当",
      "ed23456": "无限制",
      "headered4": " (平等的)",
      "headered345": " (平相当)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "棋局数量:",
      "resultsPerc": "结果， ％",
      "decisonsPerc": "决策， ％",
      "moveEval": "移动 (评估)",
      "numberOfMoveMade": "数量"
    },
    "settings": {
      "displayedName": "显示的用户名"
    },
    "preferences": {
      "piecesDisplayedAs": "棋子显示形式",
      "shortNotation": "短記譜法",
      "flipWhenBlack": "黑方走棋初始位置走法时翻转棋盘",
      "goodMovesBound": "好轮走的界限",
      "mistakesSensivity": "错误敏感性"
    },
    "moveMarking": {
      "lbHero": "培训",
      "hbydefault": "默认",
      "hchoice": "考虑",
      "hignore": "忽略",
      "lbOpp": "培训",
      "obydefault": "默认",
      "ochoice": "考虑",
      "oignore": "忽略"
    },
    "pieces": {
      "N": "马",
      "B": "象",
      "R": "车",
      "Q": "后",
      "K": "王"
    }
  },
  "uk": {
    "langid": 9,
    "login": {
      "signin": "Увійти",
      "signup": "Реєстрація",
      "remember": "Запам’ятати мене",
      "main": "Увійдіть, щоб продовжити",
      "name": "Ім'я",
      "email": "Електронна пошта",
      "password": "Пароль",
      "forgotPassword": "Забули пароль?",
      "accept": "Увійти",
      "payment": "Оформіть передплату",
      "confirmPassword": "Підтвердити пароль",
      "phone": "Телефон",
      "longPassword": "Пароль повинен бути не коротшим за 8 символів",
      "passwordComplexity": "Пароль повинен містити малі та великі літери, цифри та спеціальні символи",
      "passwordsMatch": "Паролі повинні збігатися",
      "confirmRegister": "Зареєструватись",
      "getName": "Як можна до Вас звертатися?"
    },
    "accountMenu": {
      "settings": "Налаштування",
      "preferences": "Установки",
      "logoutall": "Вийти з усіх пристроїв",
      "logout": "Вихід"
    },
    "common": {
      "aboutCompany": "Про компанію",
      "footer": "ScienceChess 2024",
      "fieldRequired": "Це поле необхідно",
      "ok": "Добре",
      "cancel": "Cкасувати",
      "reset": "Скинути",
      "dateFrom": "Початкова дата",
      "dateTo": "Кінцева дата",
      "all": "усі",
      "allCapital": "Усі"
    },
    "card": {
      "accounts": "Ім'я героя",
      "description": "Опис"
    },
    "cardSummary": {
      "accounts": "Акаунти",
      "editAccount": "Змінити акаунт",
      "addAccount": "Додати акаунт",
      "loadGames": "Завантажити партії",
      "uploadButton": "Завантажити",
      "name": "Ім'я",
      "gamesLoaded": "Завантажено партій",
      "noGames": "Немає даних",
      "analysisQuery": "Аналізувати",
      "debutAnalysis": "Аналіз дебюту",
      "mittelspielAnalysis": "Аналіз міттельшпиля",
      "endspielAnalysis": "Аналіз ендшпілю"
    },
    "playground": {
      "site": "Місце",
      "pg1": "Заочні партії",
      "pg2": "Гра вживу",
      "pg3": "lichess.org",
      "pg4": "chess.com"
    },
    "gameData": {
      "classic": "Класичні",
      "rapid": "Швидкі",
      "blitz": "Блiц",
      "bullet": "Куля"
    },
    "opening1": {
      "openingsOpen": "Відкриті дебюти",
      "openingsSemiOpen": "Напіввідкриті дебюти",
      "openingsClosed": "Закриті дебюти",
      "openingsSemiClosed": "Напівзакриті дебюти",
      "openingsIndian": "Індійські захисту",
      "openingsFlank": "Флангові дебюти та неправильні початки",
      "headerBeforeHeroname": "Дослідження дебютного репертуару: ",
      "headerAfterHeroname": "",
      "weak": "ЗВІТ З ПОМИЛОК",
      "searchStart": "Початок",
      "searchStartHint": "Початкова позиція",
      "search": "Показати",
      "searchHint": "Показати дерево варіантів, починаючи з цієї позиції",
      "searchAfter": "Помилки у партіях із позицією",
      "searchAfterHint": "Список помилок, які відбувалися у тих партіях, де зустрічалася ця позиція",
      "searchFieldPrompt": "Вставте FEN сюди",
      "side": "Бік",
      "sideWhite": "Білі",
      "sideBlack": "Чорні",
      "studied": "Вивчено",
      "studiedYes": "Так",
      "studiedNo": "Ні",
      "setStudied": "Позначити як вивчене",
      "watchlist": "Обрані",
      "watchlistListed": "У списку",
      "moveNumHeader": "Діапазон ходів",
      "moveNumFrom": "Мін",
      "moveNumTo": "Макс",
      "opening": "Дебют",
      "sortBy": "Сортувати за",
      "sortByTotalShortage": "Сумарні втрати у позиції",
      "sortByAverageShortage": "Середня втрата у позиції",
      "sortByNumMistakes": "Кількість помилок",
      "sortByMoveDesc": "Номер ходу спадає",
      "sortByMoveAsc": "Номер ходу за зростанням",
      "compareWithBeforeHeroname": "Порівняти  ",
      "compareWithAfterHeroname": " з: ",
      "mistakesEco": "ECO",
      "mistakesEcoHint": "ECO більшості партій, у яких зустрічалася ця позиція",
      "mistakesdEval": "ΔОц",
      "mistakesdEvalHint": "Середня втрата оцінки позиції у розмірі цінності пішаків",
      "mistakesNum": "Кільк",
      "mistakesNumHint": "Кількість партій (кількість помилок)",
      "mistakesTotalShortage": "Сумарні втрати",
      "mistakesTotalShortageHint": "Загальна сума втрат через вчинені в позиції помилки у розмірі цінності пішаків",
      "mistakesStudied": "Вивчав",
      "mistakesStudiedHint": "",
      "mistakesReFail": "Знову хиба",
      "mistakesReFailHint": "Помилка відбувається після позначки позиції як вивчена",
      "barMode": "Гістограми",
      "barModeResults": "результати партій",
      "barModeDecisions": "прийняті рішення",
      "displayMoves": "Показ ходів",
      "displayMovesBestOnly": "основний перелік",
      "displayMovesExtended": "розширений перелік",
      "cTO": "Рекомендації для тренування",
      "tOIgnore": "не показувати",
      "tOBest": "єдиний найкращий",
      "tOAll": "усі",
      "cPractice": "Зустрічалося",
      "practiceIgnore": "не показувати",
      "practiceHero": "при ході героя",
      "practiceAll": "усі",
      "cEmphasis": "Додаткові",
      "emphasisIgnore": "не показувати",
      "hintemphasisIgnore": "Не показувати додаткові ходи",
      "emphasis3": "єдиний найкращий",
      "hintemphasis3": "Рівно один хід із числа найсильніших",
      "emphasis2": "гарні",
      "hintemphasis2": "Якісні ходи",
      "emphasis1": "розширений перелік",
      "hintemphasis1": "Всі теоретичні",
      "flipBoard": "Перекинути шахівницю",
      "switchEngine": "Stockfish"
    },
    "opening2": {
      "leading": "Повторення ходів",
      "testing": "Перевірка знань",
      "pageLearn": "Навчання",
      "pageTest": "Тест",
      "buttonStart": "Почати",
      "buttonAbortt": "Перервати",
      "buttonReset": "Обнулити",
      "labelCorrect": "Вірно",
      "labelWrong": "Невірно"
    },
    "compare": {
      "separator1": ", ",
      "separator2": ", ",
      "separator3": " ",
      "title": "Параметри порівняння",
      "aveloRange": "Діапазон рейтингу",
      "timeControl": "Контроль часу",
      "rivals": "Порівняння навичок суперників",
      "pg1": "Заочні партії",
      "pg2": "Гра вживу",
      "pg3": "lichess.org",
      "pg4": "chess.com",
      "avelo1": "сильні любителі",
      "avelo2": "майстри",
      "avelo3": "еліта",
      "tc2_2": "класичні",
      "tc2_3": "швидкі",
      "tc2_4": "бліц",
      "tc3_3": "класичнi, швидкi",
      "tc3_4": "блiц",
      "ed4": "суперники рівні",
      "ed345": "суперники зіставні",
      "ed23456": "будь-які",
      "headered4": " (рівні)",
      "headered345": " (зіставні)",
      "headered23456": ""
    },
    "statistics": {
      "numberOfGames": "Кількість партій:",
      "resultsPerc": "Результати, %",
      "decisonsPerc": "Рішення, %",
      "moveEval": "Хід (оцінка)",
      "numberOfMoveMade": "Кільк."
    },
    "settings": {
      "displayedName": "Відображене ім'я"
    },
    "preferences": {
      "piecesDisplayedAs": "Відображення фігур",
      "shortNotation": "Коротка нотація",
      "flipWhenBlack": "Повертати дошку, коли у вихідній позиції хід чорних",
      "goodMovesBound": "Граница 'гарних' ходів",
      "mistakesSensivity": "Чутливість до помилок"
    },
    "moveMarking": {
      "lbHero": "Тренування",
      "hbydefault": "за замовч.",
      "hchoice": "вибрати",
      "hignore": "ігнорувати",
      "lbOpp": "Тренування",
      "obydefault": "за замовч.",
      "ochoice": "розглянути",
      "oignore": "ігнорувати"
    },
    "pieces": {
      "N": "К",
      "B": "С",
      "R": "Т",
      "Q": "Ф",
      "K": "Кр"
    }
  }
};

export const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'ru', // set fallback locale
  messages: L10n, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

