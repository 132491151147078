<template>
  <v-card>
    <v-card-title>{{
      isOld ? $t('cardSummary.editAccount') : $t('cardSummary.addAccount')
    }}</v-card-title>
    <v-card-text>
      <div class="d-flex align-center" style="gap: 1em">
        <div style="flex: 1">
          <v-text-field
            v-model="nickNameModel"
            :rules="[required]"
            :label="hero?.name"
            variant="outlined"
          />
          <v-text-field
            v-model="nickNameModel"
            :rules="[required]"
            :label="hero?.description"
            variant="outlined"
          />
        </div>
      </div>
    </v-card-text>
<v-card-text v-if="errorMessage" style="color: red">{{errorMessage}}</v-card-text>

    
    <div class="d-flex align-center justify-center mb-4 ml-2" style="gap: 1em">
      <v-btn @click="$emit('close')">{{ $t('common.cancel') }}</v-btn>
      <v-btn color="blue" :disabled="!nickNameModel" @click="handleAdd">{{
        $t('common.ok')
      }}</v-btn>
      <v-btn color="red" :disabled="!isOld || hasNickNames" @click="handleDelete" > delete person </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import axios from '@/plugins/axiosWrapper';

export default {
  emits: ['close'],
  props: ['heroId', 'editingNickname','hero'],
  data: () => ({
    nickNameModel: '',
    siteModel: 3,
    errorMessage: ''
  }),
  computed: {
    ...mapState('data', ['playgroundList','heroNicknamesRaw']), 
    isOld() { 
      const { hero } = this;  
      return hero?.hero_id ? true : false;
    },
    hasNickNames(){ 
      const { hero } = this; 
      return this.heroNicknamesRaw.filter(item=>item.hero_id ===hero.hero_id && item.shown != 0).length>0
    }
  },
  methods: {
    ...mapActions('data', ['addHeroNickName','addHero','renameHero','deleteHero']),
    required(v) {
      return !!v || this.$t('common.fieldRequired');
    },
    async handleAdd() { 

      if (!this.isOld) {
        await this.addHero({heroNameModel:this.nickNameModel}) 
        this.$emit('close');
      } else {
         await this.renameHero({heroNameModel:this.nickNameModel, heroId:this.hero?.hero_id}) 
        this.$emit('close');
      }
     },
    async handleDelete() { 

      if (this.isOld) {
        await this.deleteHero({heroId:this.hero?.hero_id})
        this.$emit('close');
        this.$emit('delete',this.hero?.hero_id);
      }
     }
     
  },
  watch: {
    editingNickname: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (!val || !val.nickname_id) {
          this.nickNameModel = '';
          this.siteModel = 3;
        } else {
          this.nickNameModel = val.nick;
          this.siteModel =
            this.playgroundList.find((pg) => pg.title === val.playground)
              ?.playground_id || 3;
        }
      },
    },
  },
};
</script>

<style scoped></style>
