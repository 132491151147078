<template>
  <div class="checkbox-wrapper">
    <input
      type="checkbox"
      :id="checkboxId"
      :value="value"
      :checked="localValue"
      :disabled="disabled"
      @change="handleChange"
      class="custom-checkbox"
    />
    <label :for="checkboxId" class="custom-checkbox-label">
      {{ label }}
    </label>
  </div>
</template>
<script>
export default {
  name: 'CustomCheckbox',
  props: {
    modelValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, String, Number],
      default: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    // trueValue: {
    //   type: [Boolean, String, Number],
    //   default: true,
    // },
    // falseValue: {
    //   type: [Boolean, String, Number],
    //   default: false,
    // },
    // isChecked: {
    //   type: Boolean,
    //   default: false,  
    // },
    // labelSpanClass: {
    //   type: String,
    //   default: '',
    // },
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('change', value);
      },
    },
    checkboxId() {
      return `checkbox-${this._uid}`;
    }
  },
  methods: {
    handleChange(event) {
      const newValue = event.target.checked;
      this.localValue = newValue;
    },
  },
};
</script>
<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
  
.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  -webkit-appearance: none;  
  background-color: transparent;  
  border: 1px solid #52545A; 
  border-radius: 4px;  
  outline: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox:checked {
  background-color: var(--checkbox-color, #1D85DD);  
  border-color: var(--checkbox-color, #1D85DD);  
}

.custom-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 2px 0 0 2px;
  transform: rotate(220deg); 
  transform-origin: center;
  background: transparent;  
  margin-left: -3.5px;  
  margin-top: -7px;   
}


 

.custom-checkbox:disabled {
  background-color: transparent;
  border-color: #52545A;
  cursor: default;
}

.custom-checkbox-label {
  color: var(--text-secondary, #9A9A9A);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;  
  margin-left: 8px;  
}
 
</style>
